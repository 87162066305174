import React, { useState } from "react";

const EducationalBackground = ({ onNext, onBack }) => {
  const [education, setEducation] = useState([
    {
      university: "",
      universityLocation: "",
      degree: "",
      major: "",
      gpa: "",
      graduationYear: "",
      honorsAwards: "",
      documentAttached: false, // New field for document attachment flag
    },
  ]);
  const [error, setError] = useState("");

  // Handle changes to each education entry
  const handleEducationChange = (index, e) => {
    const updatedEducation = [...education];
    updatedEducation[index][e.target.name] = e.target.value;
    setEducation(updatedEducation);
  };

  // Add a new education entry
  const addEducation = () => {
    setEducation([
      ...education,
      {
        university: "",
        universityLocation: "",
        degree: "",
        major: "",
        gpa: "",
        graduationYear: "",
        honorsAwards: "",
      },
    ]);
  };

  const handleFileUpload = (index, event) => {
    const updatedEducation = [...education];
    updatedEducation[index].document = event.target.files[0];
    setEducation(updatedEducation);
  };

  // Proceed to the next form section
  const handleNext = () => {
    const isAllValid = education.every((edu) => {
      return edu.gpa === "" || (edu.gpa !== "" && edu.document);
    });

    if (!isAllValid) {
      setError("Please upload the grade list for all entries with a GPA.");
      return;
    }
    setError("");
    onNext(education);
  };

  const removeEducation = (index) => {
    const newEducation = [...education];
    newEducation.splice(index, 1);
    setEducation(newEducation);
  };

  return (
    <div className="flex flex-col justify-center bg-gray-100 py-16">
      <div className="mx-auto w-full max-w-2xl">
        {/* <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-6">Educational Background</h2> */}
        <div className="bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
          <form className="space-y-6">
            {education.map((edu, index) => (
              <div key={index} className="space-y-6">
                <div>
                  <label htmlFor={`university-${index}`} className="text-sm font-medium text-gray-700">
                    University
                  </label>
                  <input
                    type="text"
                    name="university"
                    id={`university-${index}`}
                    value={edu.university}
                    onChange={(e) => handleEducationChange(index, e)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor={`universityLocation-${index}`} className="text-sm font-medium text-gray-700">
                    University Location
                  </label>
                  <input
                    type="text"
                    name="universityLocation"
                    id={`universityLocation-${index}`}
                    value={edu.universityLocation}
                    onChange={(e) => handleEducationChange(index, e)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor={`degree-${index}`} className="text-sm font-medium text-gray-700">
                    Degree
                  </label>
                  <input
                    type="text"
                    name="degree"
                    id={`degree-${index}`}
                    value={edu.degree}
                    onChange={(e) => handleEducationChange(index, e)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor={`major-${index}`} className="text-sm font-medium text-gray-700">
                    Major
                  </label>
                  <input
                    type="text"
                    name="major"
                    id={`major-${index}`}
                    value={edu.major}
                    onChange={(e) => handleEducationChange(index, e)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor={`gpa-${index}`} className="text-sm font-medium text-gray-700">
                    GPA
                  </label>
                  <input
                    type="text"
                    name="gpa"
                    id={`gpa-${index}`}
                    value={edu.gpa}
                    onChange={(e) => handleEducationChange(index, e)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Conditional Document Upload Field */}
                {edu.gpa && (
                  <div>
                    <label htmlFor={`document-${index}`} className="text-sm font-medium text-gray-700">
                      Upload Grade List
                    </label>
                    <input
                      type="file"
                      name="document"
                      id={`document-${index}`}
                      onChange={(e) => handleFileUpload(index, e)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                )}

                <div>
                  <label htmlFor={`graduationYear-${index}`} className="text-sm font-medium text-gray-700">
                    Graduation Year
                  </label>
                  <input
                    type="text"
                    name="graduationYear"
                    id={`graduationYear-${index}`}
                    value={edu.graduationYear}
                    onChange={(e) => handleEducationChange(index, e)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor={`honorsAwards-${index}`} className="text-sm font-medium text-gray-700">
                    Honors/Awards
                  </label>
                  <input
                    type="text"
                    name="honorsAwards"
                    id={`honorsAwards-${index}`}
                    value={edu.honorsAwards}
                    onChange={(e) => handleEducationChange(index, e)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div className="flex justify-between">
                  {education.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeEducation(index)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Remove
                    </button>
                  )}
                  {index === education.length - 1 && (
                    <button
                      type="button"
                      onClick={addEducation}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Add More
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className="flex justify-between">
              <button
                type="button"
                onClick={onBack}
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EducationalBackground;
