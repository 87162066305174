import { useState } from "react";
import "../../../css/MyMeetingList.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UpcomingTab from "./UpcomingTab";
import PendingTab from "./PendingTab";
import PastTab from "./PastTab";

const MyMeetingList = ({fetchData}) => {
  const [key, setKey] = useState("upcoming");
  
    const handleSelect = (selectedKey) => {
        setKey(selectedKey);
    };

  return (
    <>
      <div className="my_meeting_tabs container">
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
          activeKey={key}
          onSelect={handleSelect}
        >
          <Tab eventKey="upcoming" title="Upcoming">
            <UpcomingTab fetchMeetingData={fetchData} currentMeetingTab={key}/>
          </Tab>
          <Tab  eventKey="pending" title="Pending">
            <PendingTab fetchMeetingData={fetchData} currentMeetingTab={key} />
          </Tab>
          <Tab eventKey="past" title="Past">
            <PastTab fetchMeetingData={fetchData} currentMeetingTab={key}/>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default MyMeetingList;