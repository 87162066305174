import { useState } from "react";
import TimeDropdown from "./TimeDropdown";
import NewMeetingModal from "../Common/NewMeetingModal";
import {formatDate}  from "../utils";
import BuyPlanModal from "./BuyPlanModal";
import CustomDatePicker from "../CustomDatePicker";
import SettingIcon from "../../../assets/setting.png"
import { Image } from "react-bootstrap";


export default function CreateMeeting({fetchData}) {
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const initialTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    const currentDate = new Date().toISOString().slice(0, 10);
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const [selectedTime, setSelectedTime] = useState(initialTime);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (date) => {
        const formattedDate = date
        .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
        setSelectedDate(formattedDate);
    };

    return (
        <>
        <div className="container">
        <form className="time_calender_picker">
                <div className="row">
                    <div className="col-lg-6 col-sm-6 pe-md-0">
                    <div className="schedule_calenderpicker">
                        <h5>Select a Date & Time</h5>
                     <CustomDatePicker selectedDate={selectedDate} handleChange={handleChange} currentDate={currentDate}/>

                     <div className="advanced_setting">
                        {/* <button onClick={handleShow} type="button" className="advance_btn"> <span><Image src={SettingIcon}/></span> Show advanced settings</button> */}
                        <NewMeetingModal 
                            show={show} 
                            handleClose={handleClose} 
                            setSelectedDate={setSelectedDate} 
                            selectedDate={selectedDate}
                            currentDate={currentDate}
                            selectedTime={selectedTime}
                            setSelectedTime={setSelectedTime}
                            initialTime={initialTime}
                            fetchData={fetchData}
                        />
                     </div>
                     </div>
                     </div>
                     <div className="col-lg-6 col-sm-6 pl-0">
                        <div className="meeting_timing_schedule">
                            <div className="metting_btn">
                                <button onClick={handleShow} disabled={!selectedTime} type="button" className="add_meeting">Add meeting</button>
                            </div>
                            <div className="available_timslot_header">
                                <div className="date_slot">
                                    <p>Available timeSlots on</p>
                                    <h6>{formatDate(selectedDate)}</h6>
                                </div>
                            </div>
                            <div className="available_time_list">
                                <p>Time (Duration: 60 minutes)</p>
                                <TimeDropdown selectedTime={selectedTime} setSelectedTime={setSelectedTime} isModalTime={false} selectedDate={selectedDate}/>
                            </div>
                        </div>
                     </div>
                </div>
            </form>
            </div>
            <div>
                <BuyPlanModal show={modalShow} setShow={setModalShow} />
            </div>
            </>
    )
}
