import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/Cases.css";
import { API_URL } from "../config/config.index";

const CaseForm = ({ onCaseGenerated }) => {
  const [caseType, setCaseType] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchCaseInterview = async (caseType, industryType, difficultyLevel) => {
    try {
      const response = await axios.get(`${API_URL}/api/generate`, {
        params: {
          caseType,
          industryType,
          difficultyLevel,
        },
      });
      return response.data.generatedData;
    } catch (error) {
      throw error;
    }
  };

  const generateCaseInterview = async () => {
    if (!caseType || !industryType || !difficultyLevel) {
      toast.error("Please select all dropdown options before generating the case interview.");
      return;
    }

    setLoading(true);

    try {
      const generatedData = await fetchCaseInterview(caseType, industryType, difficultyLevel);

      if (onCaseGenerated) {
        onCaseGenerated({ caseType, industryType, difficultyLevel, generatedData });
      }
    } catch (error) {
      toast.error("Failed to generate the case interview. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const firstInputRef = useRef(null);

  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  return (
    <div className="form">
      <select className="select" ref={firstInputRef} value={caseType} onChange={(e) => setCaseType(e.target.value)}>
        <option value="">Select Case Type</option>
        <option value="MarketEntry">Market entry</option>
        <option value="Profitability">Profitability</option>
        <option value="Mergers and Acquisitions">Mergers and Acquisitions</option>
        {/* <option value="Business Strategy">Business Strategy</option> */}
      </select>
      <select className="select" value={industryType} onChange={(e) => setIndustryType(e.target.value)}>
        <option value="">Select Industry Type</option>
        <option value="Automotive">Automotive</option>
        <option value="Banking and Financial Services">Banking and Financial Services</option>
        <option value="Consumer Goods">Consumer Goods</option>
        <option value="Energy">Energy</option>
        <option value="Healthcare">Healthcare</option>
        <option value="Hospitality">Hospitality</option>
        <option value="Information Technology">Information Technology</option>
        <option value="Manufacturing">Manufacturing</option>
        <option value="Media and Entertainment">Media and Entertainment</option>
        <option value="Retail">Retail</option>
        <option value="Telecommunications">Telecommunications</option>
        <option value="Transportation and Logistics">Transportation and Logistics</option>
      </select>
      <select className="select" value={difficultyLevel} onChange={(e) => setDifficultyLevel(e.target.value)}>
        <option value="">Select Difficulty Level</option>
        <option value="Easy">Easy</option>
        <option value="Medium">Medium</option>
        <option value="Hard">Hard</option>
      </select>
      <button className="button" onClick={generateCaseInterview} disabled={loading}>
        {loading ? "Generating..." : "Generate Case"}
      </button>
    </div>
  );
};

export default CaseForm;
