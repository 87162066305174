import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga";

import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import ProductDisplay from "./components/ProductDisplay";
import SuccessDisplay from "./components/SuccessDisplay";
import Message from "./components/Message";
import StripeCheckoutForm from "./components/StripeCheckoutForm";
import PricingPage from "./components/PricingPage";
import CreateCaseInterview from "./components/CreateCaseInterview";

import PracticeKnowledge from "./components/PracticeKnowledge";
import Blogs from "./components/Blogs";
import blogData from "./assets/BlogData";
import BlogPostDetail from "./components/BlogPostDetail";
import LandingPage from "./components/LandingPage";
import HomePage from "./components/HomePage";
import BuildingBlock from "./components/BuildingBlock";
import Services from "./components/Services";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import { AuthContext, AuthContextWrapper } from "./context/auth.context";
import ChoosePractice from "./components/ChoosePractice";
import PracticeSkills from "./components/PracticeSkills";
import ChatBot from "./components/ChatBot";
import AboutUs from "./components/AbousUs";
import PaymentComponent from "./components/PaymentComponent";
import ProfilePage from "./components/ProfilePage";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import PaymentForm from "./components/PaymentForm";
import Plans from "./components/PlansTwo";
import { ToastContainer } from "react-toastify";
import AdminPage from "./components/AdminPage";
import CasesOverview from "./components/DisplayFreeCases/CasesOverview";
import CaseDetail from "./components/CaseInterviewDetail";
import CaseInterviewDetail from "./components/CaseInterviewDetail";
import AnnouncementBar from "./components/AnnouncementBar";
import { useLocation } from "react-router-dom";
import SimoneSkills from "./components/SimoneSkills/SimoneSkills";
import CarlCase from "./components/CarlCase/CarlCase";
import ChoosePersonalCompany from "./components/ChoosePersonalCompany";
import PersonalExperienceMCK from "./components/PersonalExperienceMCK";
import PersonalExperienceBCG from "./components/PersonalExperienceBCG";
import PersonalExperienceBain from "./components/PersonalExperienceBain";
import FreeCaseStatusManager from "./components/FreeCaseStatus";
import PageTracker from "./components/PageTracker";
import AnalyticsModal from "./components/AnalyticsModal";
import PageTrackingWrapper from "./components/PageTrackingWrapper";
import { AcademyProvider, useAcademy } from "./context/academy.context";
import Navbar from "./components/NavBar";
import UserForm from "./components/UserForm/UserForm";
import SearchableLandingPage from "./components/UserForm/SearchableLandingPage";
import ExclusiveNetworkAccessPage from "./components/UserForm/ExclusiveNetworkAccessPage";
import CaseInterviewDisplay from "./components/DisplayFreeCases/CaseInterviewDisplay";
import WebcamCapture from "./components/CarlCase/WebcamCapture";
import PersonalExperienceRB from "./components/PersonalExperienceRB";
import PersonalExperienceOW from "./components/PersonalExperienceOW";
import PersonalExperienceDeloitte from "./components/PersonalExperienceDeloitte";
import CasePractice from "./components/CasePractice";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoutes from "./components/PrivateRoute/PrivateRoutes";
import ResponsiveComponent from "./components/CarlCase/ResponsiveComponents";
import ResponsiveComponentSimone from "./components/SimoneSkills/ResponsiveComponentSimone";
import PersonalExperienceKearney from "./components/PersonalExperienceKearney";
import PersonalExperienceAccenture from "./components/PersonalExperienceAccenture";
import PersonalExperienceStrategy from "./components/PersonalExperienceStrategy";
import PersonalGrowthPage from "./components/PersonalGrowth/PersonalGrowthPage";
import MetricDetailsPage from "./components/PersonalGrowth/MetricsDetailPages/MetricsDetailPage";
import PracticeDetailsPage from "./components/PersonalGrowth/PracticeDetailsPage";
import CaseInterviewPrepModule from "./components/Academy/AcademyMain";
import LessonView from "./components/Academy/Lessons/LessonView";
import PracticeView from "./components/Academy/Lessons/PracticeView";
import AcademyOverview from "./components/Academy/AcademyMain";
import IssueTreeBuilder from "./components/Academy/Lessons/InterActiveTree";
import ThreeCsAnalysis from "./components/Academy/Lessons/InterActiceCs";
import MeetingBoardContent from "./components/CasePractice/MeetingBoard/MeetingBoardContent";

// Replace "YOUR_PUBLISHABLE_STRIPE_KEY" with your actual Stripe publishable key
const stripePromise = loadStripe("pk_live_51LJufKDDxxcYrcpoempZVO7yDse3ab5bOr5DVoUaHbrvgobmLQFpKUjsuI9U76xwi04JWoQUzIFImuMmeyzTdM1I00Eq4bM44F");
// const stripePromise = loadStripe("pk_test_51NZp0RSAnMBSRmgy8Nrr8XYrIavXeLjQIeUZ2LIBWeucOr7F1EqxchQrmiJfHkp2nnfXI6ZrUCux1vRlVMaakK2G00ZTuRNO2V");

ReactGA.initialize("G-J9JWNYNNNT");

// Google Analytics Opt-Out Function
export const optOutGoogleAnalytics = () => {
  window["ga-disable-G-J9JWNYNNNT"] = true;
  alert("You have opted out of Google Analytics tracking.");
};

export default function App() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const [isAnnouncementVisible, setIsAnnouncementVisible] = useState(true);
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);

  // Google Analytics Opt-Out
  useEffect(() => {
    const gaChoiceMade = localStorage.getItem("gaChoiceMade");
    if (!gaChoiceMade) {
      setShowAnalyticsModal(true);
    }
  }, []);

  const handleOptOut = () => {
    window["ga-disable-G-J9JWNYNNNT"] = true;
    localStorage.setItem("gaChoiceMade", "optedOut");
    setShowAnalyticsModal(false);
  };

  const handleCloseModal = () => {
    localStorage.setItem("gaChoiceMade", "consented");
    setShowAnalyticsModal(false);
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }
    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsAnnouncementVisible(false);
      } else {
        setIsAnnouncementVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AuthContextWrapper>
      <Router>
        <AcademyProvider>
          <PageTrackingWrapper>
            {/* <AnnouncementBar setIsAnnouncementVisible={setIsAnnouncementVisible} isAnnouncementVisible={isAnnouncementVisible} /> */}
            {/* {showAnalyticsModal && <AnalyticsModal isOpen={showAnalyticsModal} onOptOut={handleOptOut} onClose={handleCloseModal} />} */}

            {/* <NavBar isAnnouncementVisible={isAnnouncementVisible} /> */}
            <Navbar />

            <ToastContainer />
            <Routes>
              {/* <Route path="/" element={<LandingPage />} /> */}
              <Route path="/" element={<HomePage />} />

              <Route path="/HomePage" element={<HomePage />} />
              <Route path="/practice-knowledge" element={<PracticeKnowledge />} />
              <Route path="/practice-case-interviews" element={<CreateCaseInterview />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/plans" element={<Plans />} />
              {/* <Route element={<PrivateRoutes />}> */}
              <Route path="/case-practice" element={<CasePractice />} />
              {/* </Route> */}

              {/* Wrap the PaymentComponent with Elements */}

              {/* <Route
              path="/payment"
              element={
                <Elements stripe={stripePromise}>
                  {/* <PaymentComponent /> */}
              {/* <PaymentForm />
                </Elements> */}
              {/* } */}
              {/* />  */}

              <Route path="/admin" element={<AdminPage />} />
              <Route path="/add-personal-information-consultant" element={<UserForm />} />
              <Route path="/free-practice-case-interviews" element={<CasesOverview />} />
              <Route path="/free-practice-case-interviews/:caseId" element={<CaseInterviewDisplay />} />

              <Route path="/blogs" element={<Blogs posts={blogData} />} />
              {/* <Route path="/blog/:blogId" element={<BlogPostDetail />} /> */}
              <Route path="/blog/:blogSlug" element={<BlogPostDetail />} />

              <Route path="/auth/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/progress" element={<PersonalGrowthPage />} />
              <Route path="/progress/metric/:metric" element={<MetricDetailsPage />} />
              <Route path="/progress/practice/:id" element={<PracticeDetailsPage />} />

              <Route path="/academy" element={<AcademyOverview />} />
              <Route path="/lesson/:lessonId" element={<LessonView />} />
              <Route path="/practice/:practiceId" element={<PracticeView />} />

              <Route path="/upgrade-to-premium-to-get-access-to-optimize-case-interview-preparation" element={<SearchableLandingPage />} />
              {/* <Route path="/join-exclusive-network-with-top-consulting-firms" element={<ExclusiveNetworkAccessPage /> } /> */}
              <Route path="/choose" element={<ChoosePractice />} />

              <Route path="/practice-with-other-people" element={<MeetingBoardContent />} />
              <Route path="/choose-personal-experience" element={<ChoosePersonalCompany />} />
              <Route
                path="/practice-personal-experience-mckinsey"
                element={<PersonalExperienceMCK requiredPlan="Premium Plan" caseType="PersonalExperience" />}
              />
              <Route
                path="/practice-personal-experience-bcg"
                element={<PersonalExperienceBCG requiredPlan="Premium Plan" caseType="PersonalExperience" />}
              />
              <Route
                path="/practice-personal-experience-bain"
                element={<PersonalExperienceBain requiredPlan="Premium Plan" caseType="PersonalExperience" />}
              />

              <Route
                path="/practice-personal-experience-roland-berger"
                element={<PersonalExperienceRB requiredPlan="Premium Plan" caseType="PersonalExperience" />}
              />

              <Route
                path="/practice-personal-experience-oliver-wyman"
                element={<PersonalExperienceOW requiredPlan="Premium Plan" caseType="PersonalExperience" />}
              />

              <Route
                path="/practice-personal-experience-deloitte"
                element={<PersonalExperienceDeloitte requiredPlan="Premium Plan" caseType="PersonalExperience" />}
              />

              <Route
                path="/practice-personal-experience-kearney"
                element={<PersonalExperienceKearney requiredPlan="Premium Plan" caseType="PersonalExperience" />}
              />

              <Route
                path="/practice-personal-experience-accenture"
                element={<PersonalExperienceAccenture requiredPlan="Premium Plan" caseType="PersonalExperience" />}
              />

              <Route
                path="/practice-personal-experience-strategy&"
                element={<PersonalExperienceStrategy requiredPlan="Premium Plan" caseType="PersonalExperience" />}
              />

              <Route
                path="/practice-skills-related-to-case-interview"
                element={
                  // <FreeCaseStatusManager requiredPlan="Premium Plan" caseType="Simone">
                  <ResponsiveComponentSimone />
                  // </FreeCaseStatusManager>
                }
              />
              <Route
                path="/practice-case-interview"
                element={
                  // <FreeCaseStatusManager requiredPlan="Basic Plan" caseType="Carl">
                  <ResponsiveComponent />
                  // </FreeCaseStatusManager>
                }
              />

              <Route path="/practice-issue-trees" element={<IssueTreeBuilder />} />
              <Route path="/practice-3cs" element={<ThreeCsAnalysis />} />

              <Route path="/webcam" element={<WebcamCapture />} />

              {/* <Route path="/openai" element={<ChatBot />} /> */}
              <Route path="/about" element={<AboutUs />} />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
            </Routes>
          </PageTrackingWrapper>
        </AcademyProvider>
      </Router>
    </AuthContextWrapper>
  );
}
