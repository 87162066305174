import "../css/CasePractice.css";
import Nav from "react-bootstrap/Nav";
import { useEffect, useState } from "react";
import MeetingBoardContent from "./CasePractice/MeetingBoard/MeetingBoardContent";
import MyMeetingList from "./CasePractice/YoursMeeting/MyMeetingList";
import { Image } from "react-bootstrap";
import ContentImg from "../assets/content_img.png";
import { combineDateAndTimeToUTC } from "./CasePractice/utils";
import { formattedDate } from "../utils/casePractice";
import { getMeetingList } from "../services/MeetingServices";

const CasePractice = () => {
  const [currentTab, setCurrentTab] = useState("tab1");

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };
  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };

  const [currentDate, setCurrentDate] = useState(new Date());
  const [startTime, setStartTime] = useState("00:01");
  const [endTime, setEndTime] = useState("23:59");
  const [currentLanguage, setCurrentLanguage] = useState("English");
  const [currentExperience, setCurrentExperience] = useState("");
  const [meetings, setMeetings] = useState([]);

  const start_time = combineDateAndTimeToUTC(formattedDate(currentDate), startTime);
  const end_time = combineDateAndTimeToUTC(formattedDate(currentDate), endTime);

  const fetchData = async () => {
    try {
      const response = await getMeetingList(
        {
          date: formattedDate(currentDate),
          visibleType: [currentExperience],
          startTime: start_time,
          endTime: end_time,
          language: currentLanguage,
        },
        headers
      );
      setMeetings(response.data.data);
    } catch (error) {
      console.error("Error fetching meeting list:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentDate, startTime, endTime, currentLanguage, currentExperience]);

  return (
    <section className="outer_casepractice">
      <div className="">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner_casepractice">
              <div className="img_content">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="content">
                        <h4>
                          Enhance Your Case <span className="heading_color">Interview Performance:</span>
                        </h4>
                        <p>Advance Your Learning Journey with Collaborative and Solo Practices</p>
                        <p>
                          At Casestudygenerator, we believe in a balanced approach to mastering your skills, where engaging in interactive sessions
                          with peers plays a pivotal role. While practicing alone, such as with tools like Simone Skill Builder, lays the foundation
                          for your growth, the true transformation occurs when you blend this solo preparation with the dynamic experience of
                          practicing with peers.
                        </p>
                        <p>
                          Our platform encourages you to dive into hands-on, scenario-based practice sessions. Whether you're enrolled in our Basic or
                          Premium Plan, you unlock the opportunity to not only refine your skills in solitude but to also engage in in-depth
                          discussions and collaborative learning with fellow learners. This approach isn't just about simulating real interview
                          scenarios; it's about creating a rich tapestry of insights and strategies through the power of collaboration. Upgrade today
                          to balance your individual learning with the unparalleled benefits of practicing alongside peers, ensuring you're not just
                          prepared, but truly interview-ready.
                        </p>
                        <p>
                          {" "}
                          HINT: Use <a href="https://casestudygenerator.com/practice-case-interview">AI - Case Practice</a> to create a custom case
                          for the meeting!
                        </p>{" "}
                      </div>
                    </div>

                    <div className="col-lg-5">
                      <div className="inner_img">
                        <Image src={ContentImg} className="w-100" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="meeting_nav_tabs">
                <div className="container">
                  <Nav variant="pills" defaultActiveKey="link-1">
                    <Nav.Item>
                      <Nav.Link eventKey="link-1" onClick={() => handleTabChange("tab1")}>
                        Meeting Board
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="link-2" onClick={() => handleTabChange("tab2")}>
                        Your Meetings
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="tabs_content">
                  {currentTab == "tab1" && (
                    <MeetingBoardContent
                      setCurrentDate={setCurrentDate}
                      setStartTime={setStartTime}
                      setEndTime={setEndTime}
                      setCurrentLanguage={setCurrentLanguage}
                      setCurrentExperience={setCurrentExperience}
                      currentDate={currentDate}
                      fetchData={fetchData}
                      meetings={meetings}
                      setMeetings={setMeetings}
                    />
                  )}
                  {/* {currentTab == "tab2" && <YourMeetings />} */}
                  {currentTab == "tab2" && <MyMeetingList fetchData={fetchData} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CasePractice;
