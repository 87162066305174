import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { API_URL, API_URL_NEW_BACKEND } from "../config/config.index.js";
import "../css/Chatbot.css";
import { useContext } from "react";
import { marked } from "marked"; // Named import for 'marked'
import DOMPurify from "dompurify";
import Bain_logo from "../assets/Bain_logo.png";
import { AuthContext } from "../context/auth.context.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import VideoButton from "./VideoButton.jsx";
import { Helmet } from "react-helmet";
import useSubscriptionCheck from "./useSubscriptionCheck"; // Ensure this path is correct
import { getUserSubscription } from "../services/SubscriptionServices.js";
import GoPremiumButton from "./GoPremiumButton.jsx";
import GeneralConsultingExperience from "./GeneralConsultingExperience.jsx";

function PersonalExperienceBain(updateFreeCaseStatus) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [ttsData, setTtsData] = useState(null);
  const [userPlan, setUserPlan] = useState("");
  const { isLoggedIn, user } = useContext(AuthContext);
  const messagesEndRef = useRef(null);
  const isAllowed = useSubscriptionCheck(); // Use the hook here
  const eventSourceRef = useRef(null);
  const [threadId, setThreadId] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [warningText, setWarningText] = useState(null);
  const [isFeatureAllowed, setIsFeatureAllowed] = useState(false);

  const fetchUserSubscription = async () => {
    try {
      const response = await getUserSubscription();
      if (response.status === 200) {
        const subscription = response.data.subscription;
        const subscriptionFeatures = subscription.subscriptionFeatures;

        const featureExists = subscriptionFeatures.find((feature) => feature.name === "AI - Personal Experience");

        if (featureExists) {
          if (featureExists.usedQuantity < featureExists.quantity) {
            setIsFeatureAllowed(true);
          } else {
            setWarningText("Limit is Exhauseted");
          }
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      setWarningText(error.response.data.error);
    }
  };

  useEffect(() => {
    fetchUserSubscription();
  }, []);
  //     try {
  //       setLoading(true);

  //       // Fetch Stripe Customer ID
  //       const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
  //       const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

  //       if (!stripeCustomerId) {
  //         console.warn("No Stripe customer ID found for user:", user.email);
  //         toast.error("You need a Basic Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       // Fetch Subscription Details
  //       const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
  //       const planName = productResponse.data.productName;
  //       if (planName !== "Premium Plan") {
  //         toast.error("You need a Premium Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       setUserPlan(planName);
  //     } catch (error) {
  //       console.error("Error fetching user plan:", error);
  //       navigate("/error");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchUserPlan();
  // }, [isLoggedIn, user, navigate]);

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     toast.error("You need to be logged in to access this page");
  //     navigate("/login"); // or wherever your login page is
  //   }
  // }, [isLoggedIn, navigate]);

  useEffect(() => {
    // Automatically scroll to the latest message
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    // Automatically play TTS when data updates
    if (ttsData) {
      const audio = new Audio(`data:audio/mp3;base64,${ttsData}`);
      audio.play().catch((error) => console.error("Error playing audio:", error));
    }

    // Test the backend connection
    axios
      .get(`${API_URL_NEW_BACKEND}/api/test`)
      .then((response) => {
        console.log("Backend connection test:", response.data);
      })
      .catch((error) => {
        console.error("Error connecting to backend:", error);
      });
  }, [messages, ttsData]);

  const handleSend = async () => {
    // if (!isAllowed.isAllowed) {
    //   console.log("Handlesend", isAllowed);
    //   // This should now correctly block the action if isAllowed is false
    //   toast.error("You need a Basic or Premium Plan to access this feature.");
    //   return;
    // }

    const trimmedInput = userInput.trim();
    if (trimmedInput === "") {
      console.error("User input is empty");
      return;
    }

    // Add user's message to the conversation history
    addToConversation("user", trimmedInput);

    // Fetch OpenAI's response and add it to the conversation history
    fetchOpenAIResponse(trimmedInput, threadId)
      .then((response) => {
        addToConversation("api", response);
      })
      .catch((error) => {
        console.error("Error fetching OpenAI response:", error);
      });

    // Clear the user input
    setUserInput("");
  };

  const addToConversation = (type, text) => {
    setConversationHistory((prevHistory) => [...prevHistory, { type, text }]);
  };

  const fetchOpenAIResponse = async (promptText, currentThreadId) => {
    console.log("current thread api first fetch", currentThreadId); // Check the prompt text
    try {
      // Set loading state to true immediately before sending the request
      setLoading(true);

      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      const response = await fetch(`${API_URL_NEW_BACKEND}/api/personal-experience-bain`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userMessage: promptText, threadId: currentThreadId }),
      });

      const data = await response.json();

      if (!currentThreadId) {
        setThreadId(data.threadId);
      }

      const newEventSource = new EventSource(`${API_URL_NEW_BACKEND}/api/stream-responses?threadId=${data.threadId}`);

      let rawContent = "";
      let lastMessageIndex = null;

      newEventSource.addEventListener("messageStart", () => {
        // Reset rawContent and lastMessageIndex when a new message starts
        rawContent = "";
        lastMessageIndex = null;
      });

      newEventSource.addEventListener("textDelta", (event) => {
        const eventData = JSON.parse(event.data);
        rawContent += eventData.value;

        const html = DOMPurify.sanitize(marked(rawContent));

        setConversationHistory((prevHistory) => {
          if (lastMessageIndex !== null) {
            const lastMessage = prevHistory[lastMessageIndex];
            const newMessage = { ...lastMessage, text: html };

            return [...prevHistory.slice(0, lastMessageIndex), newMessage, ...prevHistory.slice(lastMessageIndex + 1)];
          } else {
            lastMessageIndex = prevHistory.length;
            return [...prevHistory, { type: "api", text: html }];
          }
        });

        // Set loading state to false after updating the conversation history
        setLoading(false);
      });

      newEventSource.onopen = () => console.log("SSE connection opened successfully.");
      newEventSource.onerror = (event) => {
        console.error("SSE connection encountered an error:", event);
        setLoading(false);
        newEventSource.close();
      };

      eventSourceRef.current = newEventSource;
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading) {
      handleSend();
    }
  };

  return (
    <div className="pei-chatbot-container">
      <Helmet>
        <title>Master Your Bain & Company Interview - Premium Personal Experience Prep</title>
        <meta
          name="description"
          content="Ace your Bain & Company interview with our premium personal experience preparation tool. Dive deep into personal experience scenarios tailored to Bain's interview standards and enhance your problem-solving and leadership skills."
        />
        <meta
          name="keywords"
          content="Bain interview prep, personal experience interview, Bain & Company preparation, premium interview coaching, consulting interview skills, leadership, problem-solving, case study practice, Bain consulting career"
        />
        <link rel="canonical" href="https://www.casestudygenerator.com/practice-personal-experience-bain" />
        <meta property="og:title" content="Master Your Bain & Company Interview - Premium Personal Experience Prep" />
        <meta
          property="og:description"
          content="Ace your Bain & Company interview with our premium personal experience preparation tool. Dive deep into personal experience scenarios tailored to Bain's interview standards and enhance your problem-solving and leadership skills."
        />
        <meta property="og:url" content="https://www.casestudygenerator.com/practice-personal-experience-bain" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        <GeneralConsultingExperience
          companyName="Bain & Company"
          companyLogo={Bain_logo}
          updateFreeCaseStatus={updateFreeCaseStatus}
          // isFeatureAllowed={isFeatureAllowed}
          fetchOpenAIResponse={fetchOpenAIResponse}
        />
      </div>
    </div>
  );
}

export default PersonalExperienceBain;
