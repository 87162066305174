import React, { useState, useEffect } from "react";
import "../../css/FreeCase/CaseInterviewDisplay.css";
import axios from "axios";
import { API_URL_NEW_BACKEND } from "../../config/config.index.js";
import { useParams } from "react-router-dom";
import DataDisplay from "./DataDisplay/DataDisplay.jsx";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import magicWand from "../../assets/magic-wand.png";
import { Tilt } from "react-tilt";
import BarChart from "./DataDisplay/BarChart.jsx";
import PieChart from "./DataDisplay/PieChart.jsx";
import DataTable from "./DataDisplay/DataTable.jsx";

const CaseInterviewDisplay = () => {
  const [caseData, setCaseData] = useState(null);
  const [revealAnswer, setRevealAnswer] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(true);
  const { caseId } = useParams();
  const navigate = useNavigate();
  const showTooltip = () => setIsTooltipVisible(true);
  const hideTooltip = () => setIsTooltipVisible(false);

  useEffect(() => {
    const handleScroll = () => {
      if (isTooltipVisible) {
        setIsTooltipVisible(false); // Hide tooltip on scroll
      }
    };

    // Add event listener for scroll event
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTooltipVisible]); // Depend on isTooltipVisible to ensure the effect runs when it changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL_NEW_BACKEND}/retrieve/test`);
        console.log(response.data); // Log the response data
      } catch (error) {
        console.error("Error:", error); // Log the error
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${API_URL_NEW_BACKEND}/retrieve/case-interview/${caseId}`);
      setCaseData(response.data);
      console.log(response.data);
    };

    fetchData();
  }, [caseId]);

  if (!caseData) {
    return <div>Loading...</div>;
  }

  const defaultOptions = {
    reverse: false,
    max: 35,
    perspective: 1000,
    scale: 1.1,
    speed: 1000,
    transition: true,
    axis: null,
    reset: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
  };

  const toggleRevealAnswer = () => setRevealAnswer(!revealAnswer);

  // Function to render a single dataset item (for both old and new dataset formats)
  const renderDatasetItem = (datasetItem) => {
    // Determine whether we're dealing with new or old format
    const datasetType = datasetItem.type;
    const dataPoints = datasetItem.content ? datasetItem.content.dataPoints : datasetItem.dataPoints;
    const { type, content } = datasetItem; // Destructuring for clarity

    switch (datasetType) {
      case "barChart":
        return <BarChart dataset={datasetItem.content || datasetItem} />;
      case "pieChart":
        return <PieChart dataset={datasetItem.content || datasetItem} />;
      case "dataTable":
        // Ensure DataTable gets 'dataPoints' from the 'content'
        if (content && Array.isArray(content.dataPoints)) {
          return <DataTable dataPoints={content.dataPoints} />;
        } else {
          console.error("DataTable dataPoints missing or invalid:", content);
          return <div>Invalid data for DataTable</div>;
        }
      default:
        console.error("Unknown dataset type:", type);
        return <div>Unknown dataset type</div>;
    }
  };

  // Render function for datasets, handling both single and multiple datasets
  const renderDatasets = () => {
    // Check if multiDatasets is available and has content
    if (Array.isArray(caseData.multiDatasets) && caseData.multiDatasets.length > 0) {
      return caseData.multiDatasets.map((dataset, index) => <div key={index}>{renderDatasetItem(dataset)}</div>);
    }
    // Check if the old dataset format is being used
    else if (Array.isArray(caseData.dataset) && caseData.dataset.length > 0) {
      return caseData.dataset.map((dataset, index) => <div key={index}>{renderDatasetItem(dataset)}</div>);
    }

    // Fallback if no data is available
    return <div>No data available</div>;
  };

  return (
    <>
      <Helmet>
        <title>Free Case Interview Practice - Strategy Consultant Preparation</title>
        <meta
          name="description"
          content="Prepare for your strategy consultant interview with our free case interview practice. Enhance your skills and learn at your own pace with our comprehensive case studies."
        />
        <meta
          name="keywords"
          content="Case Interview, Case Interview Framework, Practice, Free, Strategy Consultant, Preparation, Case Study, Consulting, Business Strategy, Problem Solving, Interview Preparation"
        />
      </Helmet>

      <div className="ai-transform-banner">
        <button className="ai-transform-button" onClick={() => navigate("/choose")}>
          <span className="ai-icon">🧠</span>
          <span className="button-text">Transform with AI</span>
        </button>
        <div className="ai-transform-tooltip">
          Cases are not meant to be static. We've innovated a dynamic approach to case and skill practice, tailoring it to meet your exact needs.
          Click to discover a personalized practice experience!
        </div>
      </div>

      <div className="case-container">
        <h1 className="case-title">{caseData.title}</h1>
        <div className="case-introduction">{caseData.introduction}</div>
        {caseData.questionsAndAnswers.map((qa, index) => (
          <div key={index} className="case-question">
            <h2>Question {index + 1}:</h2>
            <p className="question-intro">{qa.questionIntro}</p>
            <p className="question">{qa.question}</p>
            <button onClick={toggleRevealAnswer} className="reveal-answer-btn">
              {revealAnswer ? "Hide Answer" : "Reveal Answer"}
            </button>
            {revealAnswer && <p className="answer">{qa.answer}</p>}
            <div className="guidance-for-interviewer">Guidance: {qa.guidanceForInterviewer}</div>
          </div>
        ))}
        {renderDatasets()}
        <div className="case-conclusion">{caseData.conclusion}</div>
      </div>

      <div className="ai-practice-footer">
        <button className="unique-button" onClick={() => navigate("/choose")}>
          Practice with AI-powered learning assistants
        </button>
        <p>Enhance your skills and practice this case or specific skills at your own pace!</p>
      </div>
    </>
  );
};

export default CaseInterviewDisplay;
