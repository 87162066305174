import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptop,
  faDumbbell,
  faUserTie,
  faUsers,
  faBook,
  faGraduationCap,
  faNewspaper,
  faChevronDown,
  faFileAlt,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";

const DropdownItem = ({ icon, text, subText, link, closeMenu }) => (
  <Link
    to={link}
    onClick={() => {
      closeMenu();
    }}
    className="dropdownLink"
  >
    <FontAwesomeIcon icon={icon} className="iconStyle" />
    <div className="textColumn">
      <div className="mainText">{text}</div>
      <div className="dropdownSubtext">{subText}</div>
    </div>
  </Link>
);

const NavbarDropdown = ({ closeMenu, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      if (isOpen) {
        document.body.classList.add("dropdown-open");
      } else {
        document.body.classList.remove("dropdown-open");
      }
    }

    return () => {
      document.body.classList.remove("dropdown-open");
    };
  }, [isOpen, isMobile]);

  useEffect(() => {
    if (!isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isMobile]);

  const handleCloseMenu = () => {
    setIsOpen(false);
    if (closeMenu) closeMenu();
  };

  return (
    <div className={`dropdownStyle ${isMobile ? "mobile" : ""}`} ref={dropdownRef}>
      <div className="navLinkStyle" onClick={toggleDropdown}>
        Products
        <FontAwesomeIcon icon={faChevronDown} className={`dropdownArrow ${isOpen ? "open" : ""}`} />
      </div>
      {(isOpen || !isMobile) && (
        <div className={`dropdownMenuStyle ${isOpen ? "open" : ""}`}>
          <div className="dropdownContent">
            <div className="dropdownSection">
              <h3 className="sectionTitle">AI Practice</h3>
              <DropdownItem
                icon={faLaptop}
                text="Case Practice"
                subText="AI-powered case interview simulations"
                link="/practice-case-interview"
                closeMenu={handleCloseMenu}
              />
              <DropdownItem
                icon={faDumbbell}
                text="Skill Building"
                subText="Targeted practice for consulting skills"
                link="/practice-skills-related-to-case-interview"
                closeMenu={handleCloseMenu}
              />
              <DropdownItem
                icon={faUserTie}
                text="Personal Experience"
                subText="Prepare compelling interview stories"
                link="/choose-personal-experience"
                closeMenu={handleCloseMenu}
              />
            </div>
            <div className="dropdownSection">
              <h3 className="sectionTitle">Practice with People</h3>
              <DropdownItem
                icon={faUserFriends}
                text="Live Peer Practice"
                subText="Schedule real-time case sessions with peers"
                link="/case-practice"
                closeMenu={handleCloseMenu}
              />
              <DropdownItem
                icon={faFileAlt}
                text="Self-Paced Cases"
                subText="Access our library of practice cases"
                link="/free-practice-case-interviews"
                closeMenu={handleCloseMenu}
              />
            </div>
            <div className="dropdownSection featuredSection">
              <div className="featuredContent">
                <FontAwesomeIcon icon={faGraduationCap} className="featuredIcon" />
                <h3>CSG Academy</h3>
                <p>Comprehensive consulting prep resources</p>
                <Link to="/academy" className="featuredButton" onClick={handleCloseMenu}>
                  Explore Now →
                </Link>
              </div>
            </div>
          </div>
          <div className="dropdownFooter">
            <FontAwesomeIcon icon={faNewspaper} className="footerIcon" />
            <span>Latest from our blog:</span>
            <Link to="/blog/comprehensive-guide-mastering-case-interview-frameworks" onClick={handleCloseMenu}>
              Mastering Case Interview Frameworks
            </Link>
            <Link to="/blogs" className="viewAllLink" onClick={handleCloseMenu}>
              View All →
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarDropdown;
