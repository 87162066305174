import { useRef, useState } from "react";
import { Button, Form, Modal, Col } from "react-bootstrap";
import CustomButton from "../CustomButton";
import { createNewMeeting } from "../../../services/MeetingServices";
import { toast } from "react-toastify";
import TimeDropdown from "../MeetingBoard/TimeDropdown";
import { combineDateAndTimeToUTC } from "../utils";
import useSubscriptionCheck from "../../useSubscriptionCheck";

export default function NewMeetingModal({
  show,
  handleClose,
  setSelectedDate,
  selectedDate,
  currentDate,
  selectedTime,
  setSelectedTime,
  initialTime,
  fetchData,
}) {
  // Set an initial value
  const [remarks, setRemarks] = useState("");

  const [novice, setNovice] = useState(true);
  const [advanced, setAdvanced] = useState(true);
  const [pro, setPro] = useState(true);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [language, setLanguage] = useState(true);
  const isAllowed = useSubscriptionCheck(); // Use the hook here

  const handleNovice = () => {
    setNovice(!novice);
  };
  const handleAdvanced = () => {
    setAdvanced(!advanced);
  };
  const handlePro = () => {
    setPro(!pro);
  };
  const handleLanguage = () => {
    setLanguage(!language);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };

  async function createMeeting(e) {
    e.preventDefault();
    const visibilityType = [];

    if (!isAllowed.isAllowed) {
      toast.error("You need a Basic or Premium Plan to access this feature.");
      return;
    }

    if (novice) {
      visibilityType.push("Novice");
    }

    if (advanced) {
      visibilityType.push("Advanced");
    }

    if (pro) {
      visibilityType.push("Pro");
    }

    const startTime = combineDateAndTimeToUTC(selectedDate, selectedTime);

    const meetingObj = {
      timezone: "India/Pune",
      date: selectedDate,
      language: "English",
      visibleType: visibilityType,
      startTime: startTime,
      remarks: remarks,
    };
    if ((!novice && !advanced && !pro) || !language || !selectedDate || !selectedTime) {
      setError("Please fill out all fields.");

      setTimeout(() => {
        setError("");
      }, 3000);
    } else {
      try {
        setIsLoading(true);
        const response = await createNewMeeting(meetingObj, headers);
        fetchData();
        setIsLoading(false);
        console.log(response);
        if (response.status === 201) {
          toast.success(response.data.message);
          handleClose();
          setNovice(true);
          setAdvanced(true);
          setPro(true);
          setLanguage(true);
          setSelectedDate(currentDate);
          setSelectedTime(initialTime);
        }
      } catch (e) {
        toast.error(e.response.data.message);
        setIsLoading(false);
        console.log(e);
      }
    }
  }
  return (
    <Modal className="advanced_setting_modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add a new meeting invitation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="show_advance_setting">
          <div className="form-group split-half">
            <form className="row">
              <div className="form-row col-md-6 mb-3">
                <div className="date_calender">
                  <label className="form-label">Date</label>
                  <input
                    type="date"
                    value={selectedDate}
                    min={currentDate}
                    onChange={handleDateChange}
                    className="form-control"
                    id="exampleFormControlInput1"
                  />
                </div>
              </div>
              <div className="form-row col-md-6 mb-3">
                <div className="time_duration">
                  <label className="form-label">Time (Duration: 60 minutes)</label>
                  <TimeDropdown selectedTime={selectedTime} setSelectedTime={setSelectedTime} isModalTime={true} selectedDate={selectedDate} />
                </div>
              </div>

              <div className="form-row col-md-12 mb-3">
                <div className="time_duration">
                  <label className="form-label">Meeting visible for</label>
                  <div className="outer_button_div">
                    <CustomButton ButtonText="Novice" group="group1" handleButton={handleNovice} id={1} isChecked={novice} />

                    <CustomButton ButtonText="Advanced" group="group2" handleButton={handleAdvanced} id={2} isChecked={advanced} />

                    <CustomButton ButtonText="Pro" group="group3" handleButton={handlePro} id={3} isChecked={pro} />
                  </div>

                  <p>
                    <span>Notice: Novice: 0-10, Advanced: 11-30, Pro: 30 cases solved</span>
                  </p>
                </div>
              </div>

              <div className="form-row col-md-12 mb-3">
                <div className="time_duration">
                  <label className="form-label">Meeting language</label>

                  <div className="outer_button_div">
                    <CustomButton ButtonText="English" group="group4" handleButton={handleLanguage} id={4} isChecked={language} />
                  </div>
                </div>
                <p style={{ color: "red" }}>{error}</p>
              </div>

              <div className="form-row col-md-12">
                <label className="form-label">Remarks (optional)</label>
                <Form.Control
                  as="textarea"
                  placeholder="Is there something you'd like your meeting partner to know?"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="invitation_modal" onClick={createMeeting}>
          {isLoading ? "Loading..." : "Add meeting invitation"}
        </Button>
        <Button variant="secondary" className="cancel_btn" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
