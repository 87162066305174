import React from "react";

function PricingPage() {
  const createCheckoutSession = async () => {
    try {
      const response = await fetch("http://localhost:5005/stripe/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: [
            { id: 1, quantity: 3 },
            { id: 2, quantity: 1 },
          ],
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      window.location = data.url;
    } catch (error) {
      console.log(error.message);
    }
  };

  return <button onClick={createCheckoutSession}>Checkout</button>;
}

export default PricingPage;
