import React, { useEffect, useState } from "react";
import axios from "axios";

const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const BasicInfo = ({ formData, setFormData, onNext }) => {
  const [countries, setCountries] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(formData.location?.region || "");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (selectedRegion && selectedRegion !== "North America") {
      axios
        .get(`https://restcountries.com/v3.1/region/${selectedRegion}`)
        .then((response) => {
          const countryNames = response.data.map((country) => country.name.common);
          setCountries(countryNames);
        })
        .catch((error) => console.error("Error fetching countries:", error));
    } else if (selectedRegion === "North America") {
      setCountries([]);
    }
  }, [selectedRegion]);

  const handleRegionChange = (event) => {
    const region = event.target.value;
    setSelectedRegion(region);
    setFormData({
      ...formData,
      location: { ...formData.location, region, country: "", state: "" },
    });
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setFormData({
      ...formData,
      location: { ...formData.location, country },
    });
  };

  const handleStateChange = (event) => {
    const state = event.target.value;
    setFormData({
      ...formData,
      location: { ...formData.location, state },
    });
  };

  // Add Tailwind CSS
  useEffect(() => {
    const tailwindLink = document.createElement("link");
    tailwindLink.href = "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
    tailwindLink.rel = "stylesheet";
    document.head.appendChild(tailwindLink);
  }, []);

  // Handle change in form fields
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError(""); // Clear the error if the email is valid
      }
    }
  };

  // Handle the 'Next' button click
  const handleNext = (event) => {
    event.preventDefault();
    onNext();
  };

  const isNextButtonDisabled =
    !formData.firstName ||
    !formData.lastName ||
    !formData.email ||
    emailError ||
    !selectedRegion ||
    (selectedRegion !== "North America" && !formData.location?.country) ||
    (selectedRegion === "North America" && !formData.location?.state);

  return (
    <div className="flex flex-col justify-center bg-gray-100 py-16">
      {" "}
      {/* Adjusted padding for alignment */}
      <div className="mx-auto w-full max-w-2xl">
        {" "}
        {/* Responsive width */}
        {/* <h2 className="text-3xl font-extrabold text-gray-900 text-center" style={{ marginTop: "50px", marginBottom: "50px" }}>
          Basic Information
        </h2>{" "} */}
        <div className="bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
          <form className="space-y-6">
            {/* First Name */}
            <div>
              <label htmlFor="firstName" className="text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={formData.firstName || ""}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Last Name */}
            <div>
              <label htmlFor="lastName" className="text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={formData.lastName || ""}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Email */}
            <div>
              <label htmlFor="email" className="text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email || ""}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
              {emailError && <p className="mt-1 text-sm text-red-600">{emailError}</p>}
            </div>

            {/* Region */}
            <div>
              <label htmlFor="region" className="text-sm font-medium text-gray-700">
                Region
              </label>
              <select
                name="region"
                id="region"
                value={selectedRegion}
                onChange={handleRegionChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select Region</option>
                <option value="Europe">Europe</option>
                <option value="Americas">Americas</option>
                <option value="Asia">Asia</option>
                <option value="Africa">Africa</option>
                <option value="North America">North America</option>
              </select>
            </div>

            {/* Conditional Country Selection */}
            {selectedRegion && selectedRegion !== "North America" && (
              <div>
                <label htmlFor="country" className="text-sm font-medium text-gray-700">
                  Country
                </label>
                <select
                  name="country"
                  id="country"
                  value={formData.location?.country || ""}
                  onChange={handleCountryChange}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Country</option>
                  {countries.sort().map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Conditional State Selection for North America */}
            {selectedRegion === "North America" && (
              <div>
                <label htmlFor="state" className="text-sm font-medium text-gray-700">
                  State
                </label>
                <select
                  name="state"
                  id="state"
                  value={formData.location?.state || ""}
                  onChange={handleStateChange}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select State</option>
                  {usStates.sort().map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Profile Picture */}
            <div>
              <label htmlFor="profilePicture" className="text-sm font-medium text-gray-700">
                Profile Picture (optional)
              </label>
              <input
                type="file"
                name="profilePicture"
                id="profilePicture"
                onChange={handleChange}
                className="mt-1 block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100
                    "
                accept="image/*"
              />
            </div>

            {/* Next Button */}
            <div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleNext}
                  disabled={isNextButtonDisabled}
                  className={`justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                    isNextButtonDisabled
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
