import React from "react";
import "../../../css/FreeCase/CaseInterviewDisplay.css";

const DataTable = ({ dataPoints }) => {
  // Function to format data based on the type
  const formatData = (value, format) => {
    switch (format) {
      case "currency":
        // Assuming USD for currency formatting; adjust as necessary
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);
      case "percentage":
        return `${value}%`;
      case "largeNumber":
        return new Intl.NumberFormat().format(value);
      default:
        return value;
    }
  };

  return (
    <table className="data-table">
      <thead>
        <tr>
          <th>Label</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {dataPoints.map((point, index) => (
          <tr key={index}>
            <td>{point.label}</td>
            <td>{formatData(point.value, point.format)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
