import { useContext, useEffect, useState } from "react";
import "../../../css/Meeting.css";
import { convertDateToString, formatTime } from "../../../utils/casePractice";
import NewMeetingModal from "../Common/NewMeetingModal";
import { toast } from "react-toastify";
import { updateMeetingStatus } from "../../../services/MeetingServices";
import { getExperienceRange } from "../utils";
import { AuthContext } from "../../../context/auth.context";
import useSubscriptionCheck from "../../useSubscriptionCheck";

const Meeting = ({ meetings, setMeetings, fetchData }) => {
  const [show, setShow] = useState(false);
  const [visibleMeetings, setVisibleMeetings] = useState(4);
  const [noMoreItems, setNoMoreItems] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const currentDate = new Date().toISOString().slice(0, 10);
  const initialTime = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedTime, setSelectedTime] = useState(initialTime);
  const isAllowed = useSubscriptionCheck(); // Use the hook here

  const { user } = useContext(AuthContext);

  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };

  const onAcceptClick = async (meetingId) => {
    if (!isAllowed.isAllowed) {
      toast.error("You need a Basic or Premium Plan to access this feature.");
      return;
    }

    const data = {
      meetingStatus: "accept",
      meetingId: meetingId,
    };
    try {
      const response = await updateMeetingStatus(data, headers);
      if (response && response.status === 200) {
        toast.success(response.data.message);
        const updatedMeetings = meetings.filter((meeting) => meeting._id !== meetingId);
        setMeetings(updatedMeetings);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    }
  };

  const handleBrowseMore = () => {
    setVisibleMeetings((prevVisibleMeetings) => prevVisibleMeetings + 4);
    if (visibleMeetings + 4 >= meetings.length) {
      setNoMoreItems(true);
    }
  };

  const onCancelMeeting = async (meetingId) => {
    const data = {
      meetingStatus: "cancel",
      meetingId: meetingId,
    };
    try {
      const response = await updateMeetingStatus(data, headers);
      if (response && response.status == 200) {
        toast.success(response.data.message);
        fetchData();
      }
    } catch (error) {
      if (error.response.status == 400) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    }
  };

  const userLevel = (totalTime) => {
    if (totalTime >= 30) {
      return "Pro";
    } else if (totalTime >= 10 && totalTime < 30) {
      return "Advanced";
    } else if (totalTime <= 10) {
      return "Novice";
    }
  };

  return (
    <div className="no-meeting-hint ">
      <div className="meeting_invitation_sec">
        <div className="row">
          {meetings.slice(0, visibleMeetings).map((item, index) => {
            return (
              <div key={index} className="col-lg-6 col-md-6 mb-3">
                <div className="meeting_accept_div">
                  <div className="detail">
                    <p className="heading_color">
                      Scheduled time {formatTime(parseInt(item?.startTime))} - {formatTime(parseInt(item?.endTime))}
                    </p>
                    <p>{convertDateToString(item?.date)}</p>
                    <p className="heading_color bg-change">Host {item?.organizer?.name}</p>
                    <p className="heading_color ">
                      {userLevel(item?.organizer?.totalMeetingCount + 1)} ({getExperienceRange(item?.organizer?.user_experience_level)} cases) (
                      {item?.organizer?.totalMeetingCount + 1} meetings)
                    </p>
                    {item?.organized_by === user._id ? (
                      <button onClick={() => onCancelMeeting(item?._id)} className="btn accept_meeting_btn">
                        Cancel Meeting
                      </button>
                    ) : (
                      <button onClick={() => onAcceptClick(item?._id)} className="btn accept_meeting_btn">
                        Accept Meeting
                      </button>
                    )}
                  </div>
                  <div className="conduct_div_img">
                    <span>
                      <img className="card_img" src={item?.organizer?.profile_url} alt="cardUser" />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {meetings.length != 0 && (
        <div className="col-lg-12 text-center">
          <button
            disabled={noMoreItems}
            style={{ backgroundColor: !noMoreItems && "#0066FF", color: !noMoreItems && "#fff" }}
            onClick={handleBrowseMore}
            type="button"
            className="browse_btn"
          >
            Browse More
          </button>
        </div>
      )}
      {meetings.length == 0 && (
        <>
          <div className="no_meeting_sec">
            <h5>Could not find the right meeting?</h5>
            <p>Simply add your own meeting proposal - according to your preferences!</p>

            <button
              type="submit"
              style={{ backgroundColor: "#0066FF", color: "#fff" }}
              onClick={handleShow}
              className="add_meeting_invitation browse_btn"
            >
              Add your own meeting invitation
            </button>
          </div>
          <NewMeetingModal
            show={show}
            handleClose={handleClose}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            currentDate={currentDate}
            setSelectedTime={setSelectedTime}
            selectedTime={selectedTime}
            initialTime={initialTime}
            fetchData={fetchData}
          />
        </>
      )}
    </div>
  );
};

export default Meeting;
