// src/components/PageTrackingWrapper.jsx
import React from "react";
import { useLocation } from "react-router-dom";
import PageTracker from "./PageTracker";

const PageTrackingWrapper = ({ children }) => {
  const location = useLocation();
  return (
    <>
      <PageTracker location={location} />
      {children}
    </>
  );
};

export default PageTrackingWrapper;
