import React from "react";
import { useEffect, useState } from "react";
import "../App.css";
import { Helmet } from "react-helmet";

import Comparison from "./Comparison";
import SocialProof from "./SocialProof";
// Import at the top of your landing page
import InternalLinksSection from "./InternalLinksSection";

// Use the component where you want to place it in the landing page JSX

import IdeaBox from "./IdeaBox";
import TakeAction from "./TakeAction";

import HeroSection from "./HeroSection";
import Footer from "./Footer";
import ActionSteps from "./ActionSteps";
import CarouselComponent from "./Carousel";
import ConsultingPrepHeader from "./ConsultingPrepHeader";
import PrepEnhancer from "./PrepEnhancer";
import ShowcaseTools from "./ShowCaseTools";
import ResponsiveComponentHeroSection from "./ResponsiveHeroSection";

function LandingPage() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    window.Intercom("update");
  }, [window.location.pathname]);

  // Simulate retrieving user data (replace with actual user data fetching logic)
  useEffect(() => {
    const fetchUserData = async () => {
      // Fetch logic here, e.g., from local storage or API
      // setUserData(exampleUserData);
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    // Define APP_ID once for reusability
    const APP_ID = "p4vsun6g"; // Use your actual app_id here

    // Boot Intercom when component mounts
    window.Intercom("boot", {
      app_id: APP_ID,
      // Include user data if available
      ...(userData && {
        name: userData.name,
        email: userData.email,
        user_id: userData.user_id,
        created_at: userData.created_at, // Unix timestamp
      }),
    });

    // Shutdown Intercom when component unmounts
    return () => {
      window.Intercom("shutdown");
    };
  }, [userData]); // Effect depends on userData

  return (
    <div>
      <main className="landing-page">
        {/* first section */}
        <section className="hero-section">
          <ResponsiveComponentHeroSection />
        </section>
        {/* <section style={{ backgroundColor: "#f5f5f7", color: "#000000", padding: "50px 0" }}>
        <IdeaBox />
      </section> */}
        <section className="carousel-section" style={{ backgroundColor: "#ffffff", color: "#000000" }}>
          <CarouselComponent />
        </section>{" "}
        <section>
          <ShowcaseTools />
        </section>
        <section>
          <SocialProof />
        </section>
        <section style={{ backgroundColor: "#ffffff", color: "#000000" }}>
          <ConsultingPrepHeader />
        </section>
        {/* Social Proof Section */}
        {/* <section>
        <InternalLinksSection />
      </section> */}
        {/* <section>
        <Comparison />
      </section> */}
        {/* <section>
        {" "}
        <PrepEnhancer />
      </section> */}
        {/* 
      <section>
        <ActionSteps />
      </section> */}
        <section style={{ color: "#ffffff", backgroundColor: "#102A43" }}>
          <TakeAction />
        </section>
        <Footer />
      </main>
    </div>
  );
}

export default LandingPage;
