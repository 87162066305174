/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const blogPostStyle = css`
  flex: 1 0 calc(33.33% - 20px); // 3 items per row
  max-width: calc(33.33% - 20px); // Maximum width is also 33.33% of the row, minus the margins
  margin-bottom: 30px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  @media (max-width: 1200px) {
    flex: 1 0 calc(33.33% - 20px); // 3 items per row
    max-width: calc(33.33% - 20px); // Maximum width is also 33.33% of the row, minus the margins
  }

  @media (max-width: 800px) {
    flex: 1 0 100%; // 1 item per row on smaller screens
    max-width: 100%; // Maximum width is also 100% of the row on smaller screens
    margin-right: 0; // Remove margin on smaller screens
  }

  &:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
`;

const blogsRowStyle = css`
  display: flex;
  flex-wrap: wrap; // Allow items to wrap to the next row
  justify-content: space-between; // Add space between items
  margin-top: 20px;

  @media (max-width: 800px) {
    flex-direction: column; // Stack items on smaller screens
    align-items: center;
  }
`;

function BlogPost({ post }) {
  return (
    <Link to={`/blog/${post.slug}`} css={blogPostStyle} className="blog-post-detail-container">
      <img className="blog-post-image" src={post.image} alt={post.title} style={{ width: "100%", objectFit: "contain", borderRadius: "10px" }} />
      <h3>{post.mainTitle}</h3>
      <p className="blog-post-description">{post.description}</p>
      <div className="blog-post-topics">
        {post.topics?.map((topic) => (
          <button key={topic} className="topic-button">
            {topic}
          </button>
        ))}
      </div>
      <p className="read-more-link">Read More</p>
    </Link>
  );
}

export default BlogPost;
