import React from "react";
import "../../css/LatestFeedback.css";

import { Link } from "react-router-dom";

const LatestFeedback = ({ keyTakeaways }) => {
  return (
    <div className="latest-feedback-container">
      <h2 className="feedback-header">What to focus on</h2>
      <div className="latest-feedback">
        {!keyTakeaways || keyTakeaways.length === 0 ? (
          <>
            <h3>No feedback yet</h3>
            <p>Practice a case and save your progress to get insights!</p>
            <p>
              <strong>Upgrade to Premium: Your Secret Weapon</strong>
              <br />
              ✓ Pinpoint your strengths & weaknesses like a pro
              <br />
              ✓ Get expert-level insights on your performance
              <br />✓ Receive a customized roadmap to success
            </p>
            <p>Don't just prepare - excel in your case interviews!</p>
          </>
        ) : (
          <Link to="/progress" className="feedback-link">
            <h3>Your Latest Feedback</h3>
            <ul>
              {keyTakeaways.map((takeaway, index) => (
                <li key={index}>{takeaway}</li>
              ))}
            </ul>
          </Link>
        )}
      </div>
    </div>
  );
};

export default LatestFeedback;
