import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const CustomDatePicker = ({selectedDate, handleChange, currentDate}) => {
  return (
    <DatePickerWrapper>
      <DatePicker
        selected={selectedDate}
        onChange={handleChange}
        dateFormat="MM/dd/yyyy"
        placeholderText="Select a date"
        showPopperArrow={false}
        open={true}
        customInput={<div />}
        minDate={currentDate}
        wrapperClassName="datePicker"
      />
    </DatePickerWrapper>
  );
};

export default CustomDatePicker;

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    display: none;
    width: 100%;
  }
  .react-datepicker-popper {
    position: relative !important;
    transform: translate(0px, 0px) !important;
    width:98% !important;
    margin:auto;
}
.react-datepicker__month-container {
    float: left;
    width: 100%;
}
.react-datepicker {
    width: 100%;
    border:none;
}
.react-datepicker__header {
    text-align: center;
    background-color: #fff;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
    border-bottom:none !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 30px;
    line-height: 1.7rem;
    text-align: center;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:PT Sans;
}
.react-datepicker__week {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    padding:10px 0px;
}
.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #EDEDED;
    margin-top:10px;
}
.react-datepicker__day--selected{
    border-radius: 30px;
    background-color: #F2F8FF;
    color: #0066FF;
}
.react-datepicker__day:hover{
    border-radius:30px;
    background-color:#F2F8FF;
}
.metting_btn button.add_meeting {
    background-color: #0066FF;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: normal;
    margin-top: 0;
    text-decoration: none;
    text-transform: capitalize;
    padding: 10px 15px;
    width: auto;
}
.react-datepicker__current-month{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;  
    text-align: left;
    padding-left: 35px;
}
`;



