import axios from "axios";
import { API_URL } from "../config/config.index";

export function planCheckout(body) {
  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };

  return axios.post(`${API_URL}/plans/checkout`, body, headers);
}

export async function getUserSubscription() {
  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };
  return await axios.get(`${API_URL}/plans/current-plan`, headers);
}

export async function updateSubscription(data) {
  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };
  return await axios.post(
    `${API_URL}/plans/update-current-subscription`,
    data,
    headers
  );
}

export async function updateSubscriptionFeatures(data) {
  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };
  return await axios.post(
    `${API_URL}/subscriptions/update-feature`,
    data,
    headers
  );
}

export async function cancelCurrentSubscription(subscriptionId) {
  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };
  return await axios.get(
    `${API_URL}/plans/cancel-subscription/${subscriptionId}`,
    headers
  );
}

export async function getUsersList() {
  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };

  return await axios.get(`${API_URL}/meeting/users`, {}, headers);
}
