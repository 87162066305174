import React, { useState, useRef, useEffect, useCallback } from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import "../css/GeneralConsultingExperience.css";
import { API_URL, API_URL_NEW_BACKEND } from "../config/config.index.js";

function GeneralConsultingExperience({ companyName, companyLogo, updateFreeCaseStatus, fetchOpenAIResponse }) {
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [chatStarted, setChatStarted] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const eventSourceRef = useRef(null);
  const messagesEndRef = useRef(null);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationHistory]);

  const getApiEndpoint = useCallback((company) => {
    const companyMappings = {
      "McKinsey & Company": "mckinsey",
      "Bain & Company": "bain",
      "Boston Consulting Group": "bcg",
      "Roland Berger": "roland-berger",
      "Oliver Wyman": "oliver-wyman",
      Kearney: "kearney",
      Deloitte: "deloitte",
      Accenture: "accenture",
      PwC: "pwc",
      EY: "ey",

      // Add more mappings as needed
    };

    const endpoint = companyMappings[company] || company.toLowerCase().replace(/\s+/g, "-");
    return `${API_URL_NEW_BACKEND}/api/personal-experience-${endpoint}`;
  }, []);

  const addToConversation = useCallback((type, text) => {
    setConversationHistory((prev) => {
      if (type === "api" && prev.length > 0 && prev[prev.length - 1].type === "api") {
        const newHistory = [...prev];
        newHistory[newHistory.length - 1] = { ...newHistory[newHistory.length - 1], text };
        return newHistory;
      } else {
        return [...prev, { type, text: text || "" }];
      }
    });
  }, []);

  const handleSend = async (input = userInput) => {
    const trimmedInput = input.trim();
    if (trimmedInput === "" || loading) return;

    setLoading(true);
    addToConversation("user", trimmedInput);
    setUserInput("");
    setChatStarted(true);

    try {
      await fetchOpenAIResponseStreaming(trimmedInput, threadId);
    } catch (error) {
      console.error("Error fetching OpenAI response:", error);
      addToConversation("api", "Sorry, I encountered an error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchOpenAIResponseStreaming = async (promptText, currentThreadId) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    try {
      const apiEndpoint = getApiEndpoint(companyName);
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userMessage: promptText, threadId: currentThreadId }),
      });

      const data = await response.json();
      const newThreadId = data.threadId;

      if (!currentThreadId) {
        setThreadId(newThreadId);
      }

      if (newThreadId) {
        const newEventSource = new EventSource(`${API_URL_NEW_BACKEND}/api/stream-responses?threadId=${newThreadId}`);
        let rawContent = "";

        newEventSource.addEventListener("messageStart", () => {
          rawContent = "";
          addToConversation("api", "");
        });

        newEventSource.addEventListener("textDelta", (event) => {
          const eventData = JSON.parse(event.data);
          rawContent += eventData.value;
          const html = DOMPurify.sanitize(marked(rawContent));
          addToConversation("api", html);
        });

        newEventSource.onopen = () => console.log("SSE connection opened successfully.");
        newEventSource.onerror = (event) => {
          console.error("SSE connection encountered an error:", event);
          setLoading(false);
          newEventSource.close();
        };

        eventSourceRef.current = newEventSource;
      } else {
        console.error("No threadId received from the server");
        addToConversation("api", "Sorry, I encountered an error. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      addToConversation("api", "Sorry, I encountered an error. Please try again.");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setChatStarted(true);
    handleSend(question); // Directly call handleSend with the question
  };

  const exampleQuestions = [
    "What are the most important skills for a consultant?",
    "How do I properly structure my answer?",
    "What makes your company unique?",
    "How should I prepare for the personal experience interview?",
  ];

  return (
    <div className={`chatbot-container ${chatStarted ? "chat-active" : ""}`}>
      <Helmet>
        <title>{`Master Your ${companyName} Interview - Personal Experience Prep`}</title>
      </Helmet>

      {!chatStarted ? (
        <>
          <div className="welcome-card">
            <img src={companyLogo} alt={`${companyName} logo`} className="company-logo" />
            <h1 className="welcome-title">{companyName} Personal Experience Interview Prep</h1>
            <p className="welcome-text">We are here to help you prepare for your Personal Experience Interview (PEI) with {companyName}.</p>
            <p className="welcome-text">
              Practice answering common PEI questions, receive feedback, and improve your storytelling skills. The AI adapts to your responses,
              providing a tailored preparation experience.
            </p>
          </div>

          <div className="example-questions">
            {exampleQuestions.map((q, index) => (
              <div key={index} className="question-card" onClick={() => handleQuestionClick(q)}>
                {q}
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="chat-area">
            {selectedQuestion && <div className="selected-question">{selectedQuestion}</div>}
            {conversationHistory.map((message, index) => (
              <div key={index} className={`message-wrapper ${message.type}`} ref={index === conversationHistory.length - 1 ? lastMessageRef : null}>
                {message.type === "user" ? (
                  <div className="user-icon">👤</div>
                ) : (
                  <img src={companyLogo} alt={`${companyName} logo`} className="ai-icon" />
                )}
                <div className={`message ${message.type === "user" ? "user-message" : "ai-message"}`}>
                  <p dangerouslySetInnerHTML={{ __html: message.text }} />
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </>
      )}

      <div className="chat-input-container">
        <button className="add-button" title="Add attachment">
          +
        </button>
        <input
          type="text"
          className="chat-input"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Send a message."
          disabled={loading}
        />
        <button className="send-button" onClick={() => handleSend()} disabled={loading || userInput.trim() === ""} title="Send message">
          ↵
        </button>
      </div>
    </div>
  );
}

export default GeneralConsultingExperience;
