import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mckLogo from "../../assets/Mck_logo.png";
import bcgLogo from "../../assets/Bcg_logo.png";
import bainLogo from "../../assets/Bain_logo.png";
import rolandLogo from "../../assets/Roland_Berger_Logo_2015.png";
import StrategyLogo from "../../assets/Strategy&_logo.png";
import EYLogo from "../../assets/EY_logo.png";
import AccentureLogo from "../../assets/Accenture_logo.png";
import owLogo from "../../assets/OW_logo.png";
import deloitteLogo from "../../assets/Deloitte_Logo.png";
import pwcLogo from "../../assets/Pwc_logo.png";

function AutoPlay() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rtl: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const logos = [
    { src: "https://res.cloudinary.com/djzert4i9/image/upload/v1720106678/BCG_Corporate_Logo.svg_nz1rhq.png", alt: "Boston Consulting Group Logo" },
    { src: "https://res.cloudinary.com/djzert4i9/image/upload/v1714803103/Bain_logo_b9do87.jpg", alt: "Bain & Company Logo" },
    { src: rolandLogo, alt: "Roland Berger Logo" },
    { src: "https://res.cloudinary.com/djzert4i9/image/upload/v1720106602/Strategt__Logo_zlfcne.png", alt: "Strategy& Logo" },
    // { src: EYLogo, alt: "Ernst & Young Logo" },
    // { src: owLogo, alt: "Oliver Wyman Logo" },
    { src: deloitteLogo, alt: "Deloitte Logo" },
    { src: mckLogo, alt: "McKinsey & Company Logo" },
    // { src: pwcLogo, alt: "PwC Logo" },
    { src: AccentureLogo, alt: "Accenture Logo" },
  ];

  return (
    <div className="slider-container">
      <p className="slider-subtitle">You are just a few practice sessions away.</p>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-slide">
            <img src={logo.src} alt={logo.alt} className="logo-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
}

// Add this CSS either in your component using styled-components or in a separate CSS file
const styles = `
  .logo-slide {
    height: 100px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .logo-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export default function AutoPlayWithStyles() {
  return (
    <>
      <style>{styles}</style>
      <AutoPlay />
    </>
  );
}
