import React, { useState } from "react";
import { ChevronLeft, Send, CheckCircle, XCircle } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../css/Academy/PracticeView.css";

const PracticeView = () => {
  const [userAnswers, setUserAnswers] = useState({});
  const [feedback, setFeedback] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { moduleTitle, dayTitle, practiceData } = location.state || {};

  const handleInputChange = (questionId, value) => {
    setUserAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you would send the userAnswers to your backend for AI analysis
    // For this example, we'll simulate an API call
    const analysisResult = await simulateAIAnalysis(userAnswers, practiceData);
    setFeedback(analysisResult);
  };

  const simulateAIAnalysis = async (answers, practiceData) => {
    // This is where you'd normally call your OpenAI assistant API
    // For now, we'll return a mock result
    return {
      score: 75,
      overallFeedback: "Good attempt! You've demonstrated a solid understanding of the core concepts.",
      detailedFeedback: [
        { question: "1", comment: "Excellent analysis of the market factors." },
        { question: "2", comment: "Consider exploring cost-cutting measures in more detail." },
        { question: "3", comment: "Your revenue projection seems optimistic. Provide more justification." },
      ],
      areasForImprovement: ["Cost analysis", "Market sizing"],
      recommendedLessons: ["Advanced Market Sizing Techniques", "Profitability Deep Dive"],
    };
  };

  return (
    <div className="pv-container">
      <div className="pv-header">
        <button className="pv-back-button" onClick={() => navigate("/academy")}>
          <ChevronLeft size={20} />
          <span>{moduleTitle}</span>
        </button>
        <h1 className="pv-title">{practiceData.name}</h1>
      </div>

      <div className="pv-content">
        <h2 className="pv-day-title">{dayTitle}</h2>
        <div className="pv-practice-description">
          <p>{practiceData.description}</p>
        </div>
        <form onSubmit={handleSubmit} className="pv-answer-form">
          {practiceData.questions.map((question, index) => (
            <div key={index} className="pv-question">
              <h3>Question {index + 1}:</h3>
              <p>{question.text}</p>
              <textarea
                className="pv-answer-input"
                value={userAnswers[question.id] || ""}
                onChange={(e) => handleInputChange(question.id, e.target.value)}
                placeholder="Type your answer here..."
                rows={4}
              />
            </div>
          ))}
          <button type="submit" className="pv-submit-button">
            Submit Answers <Send size={16} />
          </button>
        </form>
        {feedback && (
          <div className="pv-feedback">
            <h3>Feedback</h3>
            <p>
              <strong>Score:</strong> {feedback.score}/100
            </p>
            <p>
              <strong>Overall:</strong> {feedback.overallFeedback}
            </p>
            <h4>Detailed Feedback:</h4>
            <ul>
              {feedback.detailedFeedback.map((item, index) => (
                <li key={index}>
                  Question {item.question}: {item.comment}
                </li>
              ))}
            </ul>
            <h4>Areas for Improvement:</h4>
            <ul>
              {feedback.areasForImprovement.map((area, index) => (
                <li key={index}>{area}</li>
              ))}
            </ul>
            <h4>Recommended Lessons:</h4>
            <ul>
              {feedback.recommendedLessons.map((lesson, index) => (
                <li key={index}>{lesson}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default PracticeView;
