import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../css/CustomSkillCreatorMobile.css";

const CustomSkillCreatorMobile = ({ onSubmit, onClose }) => {
  const [selectedSkill, setSelectedSkill] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const skillOptions = [
    { name: "Market Sizing", icon: "📊" },
    { name: "Framework Building", icon: "🏗️" },
    { name: "Data Analysis", icon: "📈" },
    { name: "Hypothesis Testing", icon: "🧪" },
    { name: "Quantitative Reasoning", icon: "🔢" },
    { name: "Problem Structuring", icon: "🧩" },
  ];

  const difficultyLevels = ["Easy", "Medium", "Hard"];

  const handleSubmit = () => {
    if (currentStep === 2 && selectedSkill && difficulty) {
      onSubmit({
        skill: selectedSkill,
        difficulty: difficulty,
      });
    }
  };

  const handleNext = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="step-content">
            <h3>Step 1: Select Skill to Practice</h3>
            <div className="skill-grid">
              {skillOptions.map((skill) => (
                <button
                  key={skill.name}
                  type="button"
                  className={`skill-button ${selectedSkill === skill.name ? "selected" : ""}`}
                  onClick={() => setSelectedSkill(selectedSkill === skill.name ? "" : skill.name)}
                >
                  <span className="skill-icon">{skill.icon}</span>
                  <span className="skill-name">{skill.name}</span>
                  {selectedSkill === skill.name && <FontAwesomeIcon icon={faCheck} className="check-icon" />}
                </button>
              ))}
            </div>
          </div>
        );
      case 2:
        return (
          <div className="step-content">
            <h3>Step 2: Choose Difficulty</h3>
            <div className="difficulty-grid">
              {difficultyLevels.map((level) => (
                <button
                  key={level}
                  type="button"
                  className={`difficulty-button ${difficulty === level ? "selected" : ""}`}
                  onClick={() => setDifficulty(level)}
                >
                  {level}
                  {difficulty === level && <FontAwesomeIcon icon={faCheck} className="check-icon" />}
                </button>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="custom-skill-creator-mobile">
      <button className="close-button" onClick={onClose} aria-label="Close">
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <h2>Create Custom Skill Session</h2>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${(currentStep / 2) * 100}%` }}></div>
      </div>
      <div>
        {renderStep()}
        <div className="navigation-buttons">
          {currentStep > 1 && (
            <button type="button" onClick={handleBack} className="back-button">
              Back
            </button>
          )}
          {currentStep < 2 ? (
            <button type="button" onClick={handleNext} className="next-button" disabled={!selectedSkill}>
              Next
            </button>
          ) : (
            <button type="button" onClick={handleSubmit} className="create-session-button" disabled={!selectedSkill || !difficulty}>
              Create Session
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomSkillCreatorMobile;
