import React from "react";
import mainimage from "../../assets/herosection-main-img.avif";
import Modal from "react-bootstrap/Modal";

const VideoModal = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" className="video-modal" centered>
      <Modal.Body>
        <div className="video-box">
          <iframe
            className="responsive-iframe"
            src="https://res.cloudinary.com/djzert4i9/video/upload/v1719928411/Video_Carl_Demo_1_q171kh.mp4"
            allowFullScreen
            title="YouTube Video"
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const HeroSection = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section className="hero">
      <div className="announcement">
        <span className="shining-star"></span>
        <span> We released the voice chat functionality🎙️. </span>
        <a href="https://casestudygenerator.com/practice-case-interview" className="announcement-link">
          See here{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#0073e6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M7 17l9.2-9.2M17 17V7H7"></path>
          </svg>
        </a>
      </div>
      <h1 className="hero-title">
        Master Case Interviews<br></br> with AI-Powered Precision
      </h1>
      <p className="hero-subtitle">
        Elevate your consulting career prospects with personalized, AI-generated case studies. <br></br>
        Practice smarter, interview better, and land your dream consulting job.{" "}
      </p>
      <div className="hero-buttons">
        <a onClick={() => setModalShow(true)} className="hero-button primary-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
            {" "}
            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />{" "}
          </svg>
          See how it works
        </a>
        <a href="https://casestudygenerator.com/practice-case-interview" className="hero-button secondary-button">
          Start for free
        </a>
      </div>
      <div className="frame">
        <div className="hero-image">
          <img src="https://res.cloudinary.com/djzert4i9/image/upload/v1720029856/Screenshot_Carl_2_pksecc.png" alt="main-image"></img>
        </div>
      </div>

      <VideoModal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
};

export default HeroSection;
