/** @jsxImportSource @emotion/react */
import { useParams } from "react-router-dom";
import blogData from "../assets/BlogData"; // Ensure the JSON data is imported here
import React from "react";
import "../css/BlogPostDetail.css"; // Import the CSS file
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

function BlogPostDetail() {
  const { blogSlug } = useParams();
  const post = blogData.find((post) => post.slug === blogSlug);

  if (!post) {
    return <p>No blog post found.</p>;
  }

  return (
    <div className="blog-post-detail-container">
      <h1 className="blog-post-title">{post.mainTitle}</h1>

      {post.headerTitle1 && (
        <section className="blog-post-section">
          <div className="blog-post-content">
            <ReactMarkdown>{post.introduction}</ReactMarkdown>
          </div>
          <h2 className="blog-post-header">{post.headerTitle1}</h2>
          <div className="markdown-content">
            <ReactMarkdown children={post.headerContent1} />
          </div>
          {post.headerImage1 && (
            <figure>
              <img className="blog-post-image" src={post.headerImage1} alt={post.headerImageAlt3} />
              <figcaption>{post.headerImage1Description}</figcaption>
            </figure>
          )}
        </section>
      )}

      {post.headerTitle2 && (
        <section className="blog-post-section">
          <h2 className="blog-post-header">{post.headerTitle2}</h2>
          <div className="markdown-content">
            <ReactMarkdown children={post.headerContent2} />
          </div>
          {post.headerImage2 && (
            <figure>
              <img className="blog-post-image" src={post.headerImage2} alt={post.headerImageAlt2} />
              <figcaption>{post.headerImage2Description}</figcaption>
            </figure>
          )}
        </section>
      )}

      {post.subHeaderTitle1 && (
        <section className="blog-post-section">
          <h3 className="blog-post-subheader">{post.subHeaderTitle1}</h3>
          <p className="blog-post-content">{post.subHeaderContent1}</p>
        </section>
      )}

      {post.subHeaderTitle2 && (
        <section className="blog-post-section">
          <h3 className="blog-post-subheader">{post.subHeaderTitle2}</h3>
          <p className="blog-post-content">{post.subHeaderContent2}</p>
        </section>
      )}

      {post.headerTitle3 && (
        <section className="blog-post-section">
          <h2 className="blog-post-header">{post.headerTitle3}</h2>
          <div className="markdown-content">
            <ReactMarkdown children={post.headerContent3} />
          </div>{" "}
          {post.headerImage3 && (
            <figure>
              <img className="blog-post-image" src={post.headerImage3} alt={post.headerImageAlt3} />
              <figcaption>{post.headerImage3Description}</figcaption>
            </figure>
          )}
        </section>
      )}

      {post.headerTitle4 && (
        <section className="blog-post-section">
          <h2 className="blog-post-header">{post.headerTitle4}</h2>
          <div className="markdown-content">
            <ReactMarkdown children={post.headerContent4} />
          </div>{" "}
          {post.headerImage4 && (
            <figure>
              <img className="blog-post-image" src={post.headerImage4} alt={post.headerImageAlt4} />
              <figcaption>{post.headerImage4Description}</figcaption>
            </figure>
          )}
          {post.pdfFile && (
            <a href={post.pdfFile} download>
              <i className="fa fa-download"></i> Download Free Practice Questions!
            </a>
          )}
        </section>
      )}

      {post.headerTitle5 && (
        <section className="blog-post-section">
          <h2 className="blog-post-header">{post.headerTitle5}</h2>
          <div className="markdown-content">
            <ReactMarkdown children={post.headerContent5} />
          </div>{" "}
        </section>
      )}

      {post.conclusionTitle && (
        <section className="blog-post-section">
          <h2 className="blog-post-header">{post.conclusionTitle}</h2>
          <div className="markdown-content">
            <ReactMarkdown children={post.conclusionContent} />
          </div>{" "}
        </section>
      )}

      <footer className="blog-post-footer">
        <p className="blog-post-content">{post.footerContent}</p>
        {post.link && (
          <p className="blog-post-content">
            Practice more:{" "}
            <a className="blog-post-link" href={post.link}>
              {post.linkDescription}
            </a>
          </p>
        )}
      </footer>
    </div>
  );
}

export default BlogPostDetail;
