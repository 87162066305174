import { Accordion } from "react-bootstrap";

const Faqs = () => {
  return (
    <div className="container">
      <div className="basic-faqs">
        <h2>Got Questions? Explore Our FAQs</h2>

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>What defines a meeting?</Accordion.Header>
            <Accordion.Body>
              <p>
                {" "}
                In a CaseStudyGenerator meeting, participants engage in a simulated consulting case interview through a video or audio call.
                Initially, one participant assumes the role of the interviewer, while the other acts as the interviewee. Each case discussion is
                designed to last 45 minutes, followed by a role reversal for a comprehensive session of approximately 45 minutes. Post-interview, it's
                beneficial for participants to exchange feedback, facilitating learning and enhancement of their interviewing capabilities.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>What are the implications of accepting a meeting?</Accordion.Header>
            <Accordion.Body>
              <p>
                Accepting a meeting invitation commits you to a 90-minute session where you'll embody both the interviewer and interviewee personas,
                sequentially. Post-acceptance, the meeting details are accessible under "Your Meetings." Should unforeseen circumstances arise, making
                attendance impossible, kindly notify your partner and proceed to cancel the meeting.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>How do I arrange meetings with peers?</Accordion.Header>
            <Accordion.Body>
              <p>
                CaseStudyGenerator offers two streamlined methods for scheduling meetings: proposing a meeting on the Meeting Board or sending a
                personal invitation.
              </p>
              <ol>
                <li>
                  The Meeting Board displays numerous case interview proposals. You can join any open invitation or post your own meeting proposal,
                  then wait for another participant to confirm.
                </li>
                <li>
                  Through the Case Partner Listing, survey potential practice partners. Utilizing search filters, streamline your search to match your
                  preparation needs, and directly propose a meeting to a candidate of your choice.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>What does participating in a meeting cost?</Accordion.Header>
            <Accordion.Body>
              <p>
                You need a Basic or Premium plan to access unlimited meetings and a wealth of preparation resources. Basic membership allows for up to
                3 complimentary meetings.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>How should I prepare for my meeting?</Accordion.Header>
            <Accordion.Body>
              <p> Meeting participation requires assuming both the interviewee and interviewer roles.</p>

              <p>
                Before embarking on your first meeting, familiarize yourself with the structure of case interviews. Review basic case interview
                techniques and tackle some practice cases independently. Once comfortable with the basic strategy, you're ready to engage in practice
                sessions.
              </p>

              <p>
                As an interviewer, you're tasked with leading the case discussion. Adequate preparation of the case is essential, ensuring you're
                well-versed in the problem and recommended approach. Upon meeting confirmation, details will be listed under “Your Meetings” on the
                Meeting Board. Prepare using the pre-selected case, choose a new one, or introduce a case of your own.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>Where is the meeting conducted?</Accordion.Header>
            <Accordion.Body>
              <p>Approved meetings are listed under “Your Meetings” on the Meeting Board, where you'll find a link to the Meeting Room.</p>
              <p>
                The Meeting Room is your virtual connection point. You may use the built-in video feature or opt for an external platform (e.g., Zoom,
                Google Meet). With integrated case material, you and your partner can collaboratively navigate through the case, sharing exhibits and
                diagrams as needed.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>How can I track my scheduled meetings?</Accordion.Header>
            <Accordion.Body>
              <p>Your scheduled and pending meetings, along with invitations, are accessible on the Meeting Board under “Your Meetings”.</p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>What should I do after the meeting?</Accordion.Header>
            <Accordion.Body>
              <p>
                Following your meeting, gathering feedback is crucial for identifying areas of improvement. Engage with "Simone the Skill Builder" and
                "Carl the Case Cracker" on CaseStudyGenerator for targeted practice to enhance your skills. While practicing with peers is invaluable
                for real-life experience, supplementing this with separate skill-building exercises ensures a well-rounded preparation. Remember, a
                balanced approach that includes both peer interaction and individual skill refinement is key to success.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};
export default Faqs;
