import React, { useState, useMemo, useEffect } from "react";
import { ChevronDown, Search, ChevronLeft, ChevronRight, CheckCircle, Clock } from "lucide-react";
import "../../css/Academy/AcademyOverview.css";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config/config.index.js";
import axios from "axios";
import { useAcademy } from "../../context/academy.context.js";
import { useContext } from "react";
import { AuthContext } from "../../context/auth.context.js";
import { Helmet } from "react-helmet";

const AcademyOverview = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
  const [modules, setModules] = useState([]);
  const [isModuleDropdownOpen, setIsModuleDropdownOpen] = useState(false);
  const [testMessage, setTestMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { dayData, setDayData } = useAcademy();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/academy/test`);
        setTestMessage(response.data);
      } catch (error) {
        console.error("Error fetching data from test route:", error);
        setTestMessage("Failed to fetch data");
      }
    };

    fetchData();
  }, []);

  console.log(testMessage);

  useEffect(() => {
    const fetchOverview = async () => {
      console.log("Fetching overview data...");
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        console.log("Token:", token ? "Present" : "Missing");

        let config = {};
        if (user && user._id) {
          config = {
            params: { userId: user._id },
            headers: { Authorization: `Bearer ${token}` },
          };
        }

        const response = await axios.get(`${API_URL}/academy/overview`, config);
        console.log("API Response:", response.data);

        if (response.data.length === 0) {
          setError("No modules found. Please contact support.");
        } else {
          setModules(response.data);
          setDayData(response.data[0].days.flatMap((day) => day.items));
        }
        setLoading(false);
        console.log("Modules set:", response.data.length);
      } catch (error) {
        console.error("Error fetching overview:", error);
        console.log("Error response:", error.response);
        setError("Failed to fetch academy data. Please try again later.");
        setLoading(false);
      }
    };

    fetchOverview();
  }, [user, setDayData]);

  const currentModule = modules[currentModuleIndex] || { days: [] };
  console.log("Current module:", currentModule);

  const { overallProgress, dayProgress } = useMemo(() => {
    const totalItems = currentModule.days.reduce((acc, day) => acc + day.items.length, 0);
    const completedItems = currentModule.days.reduce(
      (acc, day) => acc + day.items.filter((item) => item.status === "completed" || item.status === "submitted").length,
      0
    );

    const overallProgress = Math.round((completedItems / totalItems) * 100);

    const dayProgress = currentModule.days.map((day) => {
      const dayCompletedItems = day.items.filter((item) => item.status === "completed" || item.status === "submitted").length;
      return Math.round((dayCompletedItems / day.items.length) * 100);
    });

    return { overallProgress, dayProgress };
  }, [currentModule]);

  const getStatusIcon = (status) => {
    if (status === "completed" || status === "submitted") {
      return <CheckCircle size={16} className="text-blue-400" />;
    }
    return <Clock size={16} className="text-amber-500" />;
  };

  const handleItemClick = (item) => {
    if (item.type === "PRACTICE") {
      navigate(`/practice/${item._id}`);
    } else {
      const dayData = currentModule.days.find((day) => day.dayNumber === item.dayNumber)?.items || [];
      navigate(`/lesson/${item._id}`, {
        state: {
          moduleTitle: currentModule.title,
          dayTitle: `Day ${item.dayNumber}`,
          dayData: dayData,
          currentLesson: item,
        },
      });
    }
  };

  const getTypeColor = (type) => {
    const colors = {
      EXTRA: "bg-violet-100 text-violet-800",
      LESSON: "bg-sky-100 text-sky-800",
      PRACTICE: "bg-emerald-100 text-emerald-800",
    };
    return colors[type] || "bg-gray-100 text-gray-800";
  };

  const handleModuleChange = (index) => {
    setCurrentModuleIndex(index);
    setIsModuleDropdownOpen(false);
    setDayData(modules[index].days.flatMap((day) => day.items));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="academy-container">
      <Helmet>
        <title>Case Study Generator | Consulting Case Interview Prep Academy</title>
        <meta
          name="description"
          content="Master consulting case interviews with Case Study Generator's comprehensive Academy. Interactive modules, real-world practice, and expert-led lessons to ace your McKinsey, Bain, or BCG interview."
        />
        <meta
          name="keywords"
          content="case interview prep, consulting interview, McKinsey, Bain, BCG, case study practice, management consulting, problem-solving skills"
        />
        <link rel="canonical" href="https://www.casestudygenerator.com/academy" />
        <meta property="og:title" content="Case Study Generator | Ace Your Consulting Case Interview" />
        <meta
          property="og:description"
          content="Comprehensive case interview preparation with interactive modules, real-world practice, and expert guidance. Get ready for McKinsey, Bain, BCG, and more with Case Study Generator."
        />
        <meta property="og:url" content="https://www.casestudygenerator.com/academy" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="academy-header">
        <div className="academy-module-selector">
          <h1 className="academy-title" onClick={() => setIsModuleDropdownOpen(!isModuleDropdownOpen)}>
            {currentModule.title}
            <ChevronDown size={24} className="ml-2 text-gray-600" />
          </h1>
          {isModuleDropdownOpen && (
            <div className="academy-module-dropdown">
              {modules.map((module, index) => (
                <div
                  key={index}
                  className={`academy-module-option ${index === currentModuleIndex ? "active" : ""}`}
                  onClick={() => handleModuleChange(index)}
                >
                  {module.title}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="academy-controls">
          {/* <div className="academy-search-container">
            <input
              type="text"
              placeholder="Search content"
              className="academy-search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search size={18} className="academy-search-icon" />
          </div> */}
          <button
            className="academy-button"
            onClick={() => handleModuleChange(Math.max(0, currentModuleIndex - 1))}
            disabled={currentModuleIndex === 0}
          >
            <ChevronLeft size={18} style={{ marginRight: "4px" }} /> Previous module
          </button>
          <button
            className="academy-button academy-button-primary"
            onClick={() => handleModuleChange(Math.min(modules.length - 1, currentModuleIndex + 1))}
            disabled={currentModuleIndex === modules.length - 1}
          >
            Next module <ChevronRight size={18} style={{ marginLeft: "4px" }} />
          </button>
        </div>
      </div>

      <div className="academy-progress">
        <div className="academy-progress-bar" style={{ width: `${overallProgress}%` }}></div>
        <span className="academy-progress-text">{overallProgress}% Complete</span>
      </div>

      <div className="academy-content">
        <div className="academy-grid">
          {currentModule.days.map((day, dayIndex) => (
            <div key={dayIndex} className="academy-day-card">
              <div className="academy-day-header">
                <h2 className="academy-day-title">Day {day.dayNumber}</h2>
                <div className="academy-day-progress">
                  <svg width="40" height="40" viewBox="0 0 40 40">
                    <circle cx="20" cy="20" r="18" fill="none" stroke="#e5e7eb" strokeWidth="4" />
                    <circle
                      cx="20"
                      cy="20"
                      r="18"
                      fill="none"
                      stroke="#3b82f6"
                      strokeWidth="4"
                      strokeDasharray={`${dayProgress[dayIndex] * 1.13}, 100`}
                      transform="rotate(-90 20 20)"
                    />
                    <text x="20" y="24" textAnchor="middle" fontSize="12" fill="#3b82f6" fontWeight="bold">
                      {dayProgress[dayIndex]}%
                    </text>
                  </svg>
                </div>
              </div>
              {day.items.map((item, itemIndex) => (
                <div key={itemIndex} className="academy-item" onClick={() => handleItemClick(item)}>
                  <div className="academy-item-icon">{getStatusIcon(item.status)}</div>
                  <div>
                    <div className={`academy-item-type ${getTypeColor(item.type)}`}>{item.type}</div>
                    <div className="academy-item-name">{item.name}</div>
                    {item.status === "submitted" && (
                      <div className="academy-item-status">
                        Submitted <span className="academy-item-feedback">Review feedback</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AcademyOverview;
