import axios from "axios";
import { API_URL } from "../config/config.index";

export function getMeetingList(body, headers) {
  return axios.post(`${API_URL}/meeting/list`, body, headers);
}

export async function createNewMeeting(data, headers) {
  return await axios.post(`${API_URL}/meeting/create`, data, headers);
}

export async function fetchUserMeetings(data, headers) {
  return await axios.post(`${API_URL}/meeting/status`, data, headers);
}

export async function updateMeetingStatus(data, headers) {
  return await axios.post(`${API_URL}/meeting/update-status`, data, headers);
}

export async function getUsersList(headers) {
  return await axios.get(`${API_URL}/meeting/users`,{},headers);
}
