import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth.context";
import { API_URL } from "../config/config.index.js";
import axios from "axios";
function useSubscriptionCheck() {
  const navigate = useNavigate();
  const { isLoggedIn, user } = useContext(AuthContext);
  const [isAllowed, setIsAllowed] = useState(false);
  const [userPlan, setUserPlan] = useState(""); // State variable to store the user's plan

  useEffect(() => {
    const fetchUserPlan = async () => {
      if (!isLoggedIn) {
        // If not logged in, don't do anything. This hook doesn't handle redirection to login
        return;
      }

      try {
        const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
        const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

        // Check if stripeCustomerId exists before making the next API call
        if (!stripeCustomerId) {
          console.warn("No Stripe customer ID found for user:", user.email);

          return;
        }

        const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
        console.log("Received product response:", productResponse.data);

        const planName = productResponse.data.productName;
        setUserPlan(planName);
        console.log("User's subscription plan:", planName);

        // Set isAllowed to true if the user has a Basic or Premium plan
        if (planName === "Basic Plan" || planName === "Premium Plan") {
          setIsAllowed(true);
        }

        console.log("User's subscription plan:", planName);
      } catch (error) {
        console.error("Error fetching user plan:", error);
      }
    };

    fetchUserPlan();
  }, [isLoggedIn, user, navigate]);

  console.log("isAllowed:", isAllowed); // Log the isAllowed value to the console
  console.log("userPlan:", userPlan); // Log the user's plan to the console

  return { isAllowed, userPlan }; // Return the user's plan along with the isAllowed value
}

export default useSubscriptionCheck;
