import React from "react";
import "../../css/SearchableLandingPage.css"; // This is where the styles would be imported from
import { Helmet } from "react-helmet"; // This is where the SEO tags would be imported from
import { Link } from "react-router-dom"; // This is where the Link component would be imported from
import SearchableLandingPageHeaderImg from "../../assets/Searchable_landing_page_header.png"; // This is where the header image would be imported from
import SearchableLandingPageReasonGIF from "../../assets/SearchableHeaderGIF.gif"; // This is where the first reason image would be imported from
import Video_Personal_Experience from "../../assets/Video_Personal_Experience.gif"; // This is where the video would be imported from
import SearchableLandingPageReason1 from "../../assets/Searchable_landing_page_reason1.png"; // This is where the first reason image would be imported from
import Practice_with_Peers from "../../assets/Practice_with_peers.png"; // This is where the second reason image would be imported from

const SearchableLandingPage = () => {
  return (
    <div className="searchable-landing-page">
      <Helmet>
        <title>Case Study Generator - Master Consulting Interview Prep</title>
        <meta
          name="description"
          content="Master your consulting interview prep with Case Study Generator. Dive into a comprehensive platform offering in-depth case studies, personalized feedback, and exclusive access to top consultancy networks."
        />
        <meta
          name="keywords"
          content="consulting interviews, case study preparation, consulting career, interview skills, consulting network, case study practice, career development"
        />
        <link rel="canonical" href="https://www.casestudygenerator.com/upgrade-to-premium-to-get-access-to-top-consulting-firms" />
        <meta property="og:title" content="Case Study Generator - Your Pathway to Consulting Success" />
        <meta
          property="og:description"
          content="Unlock your potential in consulting with Case Study Generator. Comprehensive interview prep, case studies, and insider access to consultancy networks."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.casestudygenerator.com/searchable-landing-page" />
        <meta property="og:image" content="https://www.casestudygenerator.com/images/searchable-landing-page-thumbnail.jpg" />
        <meta property="og:site_name" content="Case Study Generator" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Searchable Landing Page",
              "description": "The ultimate platform for consulting interview preparation, offering extensive case studies, personalized feedback, and access to top consultancy networks.",
              "url": "https://www.casestudygenerator.com/searchable-landing-page",
              "publisher": {
                "@type": "Organization",
                "name": "Case Study Generator"
              }
            }
          `}
        </script>
      </Helmet>
      {/* Section 1: Header Landing Page */}
      <section className="header-landing-page">
        <div className="left-content">
          <h1>Prepare like a Pro.</h1>
          <p>
            Unlock the door to your consulting career with our targeted interview preparation platform. Tailored exclusively for the consulting
            sector, we provide in-depth case practice and skill enhancement exercises, coupled with direct access to top consultancy networks. Embrace
            a preparation journey that goes beyond the basics, equipping you with the insights and confidence to excel in the highly competitive world
            of consulting.{" "}
          </p>
          <Link to="/plans">
            <button className="button">Try it out now</button>
          </Link>
        </div>
        <div className="right-content">
          {/* 
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/PwburwEd3Hk?autoplay=1&loop=1&playlist=PwburwEd3Hk"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  */}

          <img src={SearchableLandingPageReasonGIF} alt="Description of the gif" />
        </div>
      </section>

      {/* Section 2: Reasons to Switch */}
      <section className="reasons-to-switch">
        <h2>3 Reasons to Switch to Case Study Generator</h2>
        <div className="reason">
          <img src={SearchableLandingPageReason1} alt="" />
          <div className="text-content">
            <h3>Unlimited Case Practice for Mastery</h3>
            <p>
              Dive into an ocean of case studies with our platform, offering unlimited case practice and skill-building exercises. Each scenario is
              designed to replicate the complexities of real consulting challenges, providing you with a robust foundation in analytical thinking,
              strategic problem-solving, and effective communication. This continuous engagement ensures that you're not just prepared, but are
              mastering the skills necessary for a standout performance in consulting interviews.
            </p>
            <Link to="/plans">
              <button className="get-started-button">
                GET STARTED
                <span className="arrow">→</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="reason mirrored">
          <img src={Video_Personal_Experience} alt="" />
          <div className="text-content">
            <h3>Immediate, Constructive Feedback for Rapid Improvement</h3>
            <p>
              Accelerate your learning curve with our immediate feedback system. After each practice session, receive instant, detailed feedback that
              pinpoints your strengths and areas for improvement. This level of personalized critique is crucial for fine-tuning your approach,
              allowing you to develop a deeper understanding of consulting concepts and to enhance your performance dynamically and efficiently.
            </p>
            <Link to="/plans">
              <button className="get-started-button">
                GET STARTED
                <span className="arrow">→</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="reason">
          <img src={Practice_with_Peers} alt="" />
          <div className="text-content">
            <h3>Transform Your Consulting Career with Peer Learning</h3>
            <p>
              Embark on a transformative journey in the consulting industry with our platform, designed to elevate your career through collaborative
              learning. Here, you don't just prepare; you connect and grow with peers who share your ambitions. Our platform fosters a community where
              aspiring consultants practice and refine their skills together, mirroring real-world scenarios. Dive into a dynamic learning environment
              where you can challenge yourself, exchange perspectives with fellow aspirants, and develop the critical thinking and problem-solving
              skills essential for success in the consulting world. This isn't just preparation – it's a shared journey towards mastering the art of
              consulting.
            </p>
            <Link to="/plans">
              <button className="get-started-button">
                GET STARTED
                <span className="arrow">→</span>
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Section 3: Features You Will Love */}
      <section className="features-you-will-love">{/* Recreate the attached image interaction here */}</section>

      {/* Section 4: Reference */}
      <section className="reference">
        <div className="quote">
          {/* Quote from someone who loves the platform */}
          <p>
            "For anyone preparing for interviews, I highly recommend the Case Study Generator for its versatility and comprehensive coverage of
            topics. It's a game-changer in how we approach learning and preparation at Roland Berger."
          </p>
          <p>- Thijs van der Kamp, project manager at Roland Berger</p>
        </div>
      </section>

      {/* Section 5: Take next step */}
      <section className="take-next-step">
        <h3>Take the Next Step in Your Consulting Journey</h3>
        <div className="call-to-action">
          <p>
            You've seen the transformative power of our platform and how it's reshaping the way aspiring consultants prepare. Now, it's your turn to
            step into this realm of endless possibilities. Don't just dream about a career in consulting; make it a reality. Join our community of
            forward-thinkers and start shaping your future today. The path to success in consulting begins here, and it begins now. Are you ready to
            take the leap?
          </p>
          <Link to="/plans">
            <button className="join-now-button">
              Join Now & Unleash Your Potential
              <span className="arrow">→</span>
            </button>
          </Link>
        </div>
      </section>

      {/* Section 6: Frequently Asked Questions */}
      <section className="frequently-asked-questions">{/* Structure based on the second image will go here */}</section>
    </div>
  );
};

export default SearchableLandingPage;
