import React, { useState } from "react";

import { useEffect } from "react";

const ProfessionalSummary = ({ onNext, onBack }) => {
  const [formData, setFormData] = useState({
    headline: "",
    bio: "",
  });

  // add tailwind cdn
  useEffect(() => {
    const tailwindLink = document.createElement("link");
    tailwindLink.href = "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
    tailwindLink.rel = "stylesheet";
    document.head.appendChild(tailwindLink);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex flex-col justify-center bg-gray-100 py-16">
      {" "}
      {/* Adjusted padding for alignment */}
      <div className="mx-auto w-full max-w-2xl">
        {" "}
        {/* Responsive width */}
        {/* <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-4">Professional Summary</h2> */}
        <div className="bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
          <form className="space-y-6">
            {/* Professional Headline */}
            <div>
              <label htmlFor="headline" className="text-sm font-medium text-gray-700">
                Professional Headline
              </label>
              <input
                type="text"
                name="headline"
                id="headline"
                value={formData.headline}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Bio */}
            <div>
              <label htmlFor="bio" className="text-sm font-medium text-gray-700">
                Bio
              </label>
              <textarea
                name="bio"
                id="bio"
                value={formData.bio}
                onChange={handleChange}
                required
                rows="4"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Buttons */}
            <div className="flex justify-between">
              <button
                type="button"
                onClick={onBack}
                className="px-4 py-2 bg-gray-300 text-gray-700 font-semibold rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Back
              </button>
              <button
                type="button"
                onClick={onNext}
                className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalSummary;
