import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/AnnouncementBar.css";

function AnnouncementBar({ setIsAnnouncementVisible, isAnnouncementVisible }) {
  useEffect(() => {
    const handleScroll = () => {
      console.log(window.scrollY); // This should log scroll position on scroll
      if (window.scrollY > 0) {
        setIsAnnouncementVisible(false);
      } else {
        setIsAnnouncementVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [setIsAnnouncementVisible]); // Ensure useEffect is correctly dependent on props

  return (
    <div className={`announcement-bar ${isAnnouncementVisible ? "" : "hidden"}`}>
      <p>🔥 Mobile Photo Analysis: Transform paper sketches into AI feedback on your phone for precise learning enhancements. 🔥</p>
      <Link to="/practice-skills-related-to-case-interview" className="announcement-cta">
        Get Started now!
      </Link>
    </div>
  );
}

export default AnnouncementBar;
