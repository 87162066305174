import React, { useState, useEffect, useContext } from "react";
import "../../css/PersonalGrowth/PersonalGrowthPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faArrowUp, faTimesCircle, faThumbsUp, faStar, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { API_URL } from "../../config/config.index";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const metricIcons = {
  Creativity: faChartLine,
  Structure: faArrowUp,
  Communication: faTimesCircle,
  "Problem Solving": faThumbsUp,
  "Quantitative Skills": faStar,
  "Business Acumen": faArrowDown,
};

const PersonalGrowthPage = () => {
  const [metrics, setMetrics] = useState({});
  const { user } = useContext(AuthContext);
  const [practices, setPractices] = useState([]);
  const [recentAnalysis, setRecentAnalysis] = useState(null);
  const [expandedMetric, setExpandedMetric] = useState(null);
  const [expandedSummary, setExpandedSummary] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user && user._id) {
          const response = await axios.get(`${API_URL}/analysis/all/${user._id}`);
          const allAnalyses = response.data;

          if (allAnalyses.length > 0) {
            allAnalyses.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

            setRecentAnalysis(allAnalyses[allAnalyses.length - 1]);

            const metricsData = processMetrics(allAnalyses[allAnalyses.length - 1]);
            setMetrics(metricsData);

            const practicesData = allAnalyses.map(processAnalysis);
            setPractices(practicesData);
          } else {
            setPlaceholderData();
          }
        } else {
          setPlaceholderData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setPlaceholderData();
      }
    };

    fetchData();
  }, [user]);

  const setPlaceholderData = () => {
    setMetrics({
      creativity: { score: 0, examples: "No data yet", tips: ["Start practicing to see tips"] },
      structure: { score: 0, examples: "No data yet", tips: ["Start practicing to see tips"] },
      communication: { score: 0, examples: "No data yet", tips: ["Start practicing to see tips"] },
      "problem solving": { score: 0, examples: "No data yet", tips: ["Start practicing to see tips"] },
      "quantitative skills": { score: 0, examples: "No data yet", tips: ["Start practicing to see tips"] },
      "business acumen": { score: 0, examples: "No data yet", tips: ["Start practicing to see tips"] },
    });
    setPractices([
      {
        id: "placeholder",
        date: "No practice yet",
        score: 0,
        strengths: "Start practicing to see your strengths",
        weaknesses: "Start practicing to see areas for improvement",
        actionItems: "Start practicing to get action items",
        caseSummary: "Start practicing to get case summaries",
      },
    ]);
  };

  const formatMetricName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const processMetrics = (analysis) => {
    const metricsData = {};
    if (analysis.detailedEvaluation.MetricAnalysis) {
      analysis.detailedEvaluation.MetricAnalysis.forEach((metric) => {
        metricsData[metric.Metric.toLowerCase()] = {
          score: parseFloat(metric.Performance),
          examples: metric.Strengths[0].Example,
          tips: [metric.SpecificPracticeSuggestion.Instructions],
          purpose: metric.SpecificPracticeSuggestion.Purpose,
          instructions: metric.SpecificPracticeSuggestion.Instructions,
          strengths: metric.Strengths,
          areasForImprovement: metric.AreasForImprovement,
          specificPracticeSuggestion: metric.SpecificPracticeSuggestion,
        };
      });
    } else {
      Object.entries(analysis.detailedEvaluation).forEach(([key, value]) => {
        metricsData[key.toLowerCase()] = {
          score: parseFloat(value.score),
          examples: value.justifications[0].example,
          tips: [value.areas_for_improvement],
          purpose: `Improve your ${key} skills`,
          instructions: value.areas_for_improvement,
          strengths: [{ Description: value.areas_of_strength }],
          areasForImprovement: [{ Description: value.areas_for_improvement }],
          justifications: value.justifications,
        };
      });
    }
    return metricsData;
  };

  const processAnalysis = (analysis) => {
    let overallScore, strengths, weaknesses, detailedEvaluation;
    if (analysis.detailedEvaluation.MetricAnalysis) {
      overallScore =
        analysis.detailedEvaluation.MetricAnalysis.reduce((sum, metric) => sum + parseFloat(metric.Performance), 0) /
        analysis.detailedEvaluation.MetricAnalysis.length;
      strengths = analysis.detailedEvaluation.MetricAnalysis[0].Strengths[0].Description;
      weaknesses = analysis.detailedEvaluation.MetricAnalysis[0].AreasForImprovement[0].Description;
      detailedEvaluation = analysis.detailedEvaluation;
    } else {
      const metrics = Object.values(analysis.detailedEvaluation);
      overallScore = metrics.reduce((sum, metric) => sum + parseFloat(metric.score), 0) / metrics.length;
      strengths = metrics[0].areas_of_strength;
      weaknesses = metrics[0].areas_for_improvement;
      detailedEvaluation = analysis.detailedEvaluation;
    }
    return {
      date: new Date(analysis.createdAt).toLocaleDateString(),
      score: overallScore,
      strengths: strengths,
      weaknesses: weaknesses,
      actionItems: analysis.priorityFocusAreas[0],
      id: analysis._id,
      detailedEvaluation: detailedEvaluation,
      caseSummary: analysis.caseSummary,
      executiveSummary: analysis.executiveSummary,
    };
  };

  const toggleExpandMetric = (key) => {
    setExpandedMetric(expandedMetric === key ? null : key);
  };

  const toggleSummary = (id) => {
    setExpandedSummary(expandedSummary === id ? null : id);
  };

  const scores = practices.map((practice) => practice.score);
  const dates = practices.map((practice) => practice.date);

  const data = {
    labels: dates,
    datasets: [
      {
        label: "Practice Scores",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "rgba(54, 162, 235, 1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "rgba(54, 162, 235, 1)",
        pointHoverBorderColor: "rgba(54, 162, 235, 0.8)",
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        data: scores,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: 14,
            family: "Roboto",
          },
          color: "#666",
        },
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleFont: {
          family: "Roboto",
          size: 16,
        },
        bodyFont: {
          family: "Roboto",
          size: 14,
        },
        callbacks: {
          label: function (context) {
            return `Score: ${context.raw.toFixed(2)}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Dates",
          color: "#666",
          font: {
            size: 16,
            family: "Roboto",
          },
        },
        ticks: {
          color: "#666",
          font: {
            size: 14,
            family: "Roboto",
          },
        },
        grid: {
          color: "rgba(0,0,0,0.1)",
        },
      },
      y: {
        min: 0,
        max: 10,
        title: {
          display: true,
          text: "Scores",
          color: "#666",
          font: {
            size: 16,
            family: "Roboto",
          },
        },
        ticks: {
          stepSize: 1,
          color: "#666",
          font: {
            size: 14,
            family: "Roboto",
          },
        },
        grid: {
          color: "rgba(0,0,0,0.1)",
        },
      },
    },
  };

  return (
    <div className="personal-growth-page">
      <header>
        <h1>Hi, {user && user.name ? user.name : "there"}</h1>
        <p>
          {user
            ? "You're on track to crush your case interview. Let's review your progress"
            : "Upgrade to Basic or Premium to start tracking your progress!"}
        </p>
        {!user && (
          <Link to="/plans" className="login-button">
            Upgrade
          </Link>
        )}
      </header>
      <section className="old-key-metrics">
        {Object.entries(metrics).map(([key, value]) => (
          <div key={key} className={`old-metric-card ${expandedMetric === key ? "expanded" : ""}`} onClick={() => toggleExpandMetric(key)}>
            <div className="old-metric-header">
              <div className="old-metric-info">
                <div className="old-metric-title">{formatMetricName(key)}</div>
                <div className="old-metric-score">Latest Score: {value.score.toFixed(1)}</div>
              </div>
            </div>
            <div className="old-metric-summary">
              <p>
                <strong>Example:</strong> {value.examples}
              </p>
              <p>
                <strong>Practice the following:</strong> {value.tips[0]}
              </p>
              {user ? (
                <Link
                  to={`/progress/metric/${key}`}
                  state={{
                    metricDetails: value,
                    allPractices: practices,
                    allMetrics: metrics,
                  }}
                  className="old-click-for-more button button-primary mt-auto"
                >
                  Click for more details
                </Link>
              ) : (
                <Link to="/login" className="old-click-for-more button button-primary mt-auto">
                  Log in to see details
                </Link>
              )}
            </div>
          </div>
        ))}
      </section>
      <section className="practice-timeline">
        <h2>Your practice timeline</h2>
        <div className="timeline-chart">
          {user ? <Line data={data} options={options} /> : <div className="placeholder-chart">Log in to see your practice timeline</div>}
        </div>
      </section>
      <section className="past-practices">
        <h2>Past practices</h2>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Score</th>
              <th>Strengths</th>
              <th>Areas for Improvement</th>
              <th>Action Items</th>
              <th>Case Summary</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {practices.map((practice) => (
              <React.Fragment key={practice.id}>
                <tr>
                  <td data-label="Date">
                    <div className="practice-date">{practice.date}</div>
                    <div className="practice-length">{practice.length}</div>
                  </td>
                  <td data-label="Score">
                    <div className="score-circle">
                      <svg viewBox="0 0 36 36" className="circular-chart">
                        <path className="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                        <path
                          className="circle"
                          strokeDasharray={`${practice.score * 10}, 100`}
                          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" className="percentage">
                          {practice.score.toFixed(1)}
                        </text>
                      </svg>
                    </div>
                  </td>
                  <td data-label="Strengths" className="strengths" title={practice.strengths}>
                    <h4>Strengths</h4>
                    <p>{practice.strengths}</p>
                  </td>
                  <td data-label="Areas for Improvement" className="weaknesses" title={practice.weaknesses}>
                    <h4>Areas for Improvement</h4>
                    <p>{practice.weaknesses}</p>
                  </td>
                  <td data-label="Action Items" className="action-items" title={practice.actionItems}>
                    <h4>Action Items</h4>
                    <p>{practice.actionItems}</p>
                  </td>
                  <td data-label="Case Summary">
                    {user ? (
                      <button className="case-summary-toggle" onClick={() => toggleSummary(practice.id)}>
                        {expandedSummary === practice.id ? "Hide Summary" : "View Summary"}
                      </button>
                    ) : (
                      <Link to="/login" className="case-summary-toggle">
                        Log in to view
                      </Link>
                    )}
                  </td>
                  <td>
                    {user ? (
                      <button
                        className="view-details-button"
                        onClick={() =>
                          navigate(`/progress/practice/${practice.id}`, {
                            state: {
                              practiceDetails: practice,
                              allPractices: practices,
                            },
                          })
                        }
                      >
                        View Details
                      </button>
                    ) : (
                      <Link to="/login" className="view-details-button">
                        Log in to view
                      </Link>
                    )}
                  </td>
                </tr>
                {user && (
                  <tr className={`summary-row ${expandedSummary === practice.id ? "expanded" : ""}`}>
                    <td colSpan="7">
                      <div className="case-summary-content">
                        <h4>Case Summary</h4>
                        <p>{practice.caseSummary}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default PersonalGrowthPage;
