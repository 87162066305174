import React from "react";

import { useEffect } from "react";

const FormNavigation = ({ currentPage, goToPage }) => {
  // add tailwind cdn
  useEffect(() => {
    const tailwindLink = document.createElement("link");
    tailwindLink.href = "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
    tailwindLink.rel = "stylesheet";
    document.head.appendChild(tailwindLink);
  }, []);

  return (
    <div className="form-navigation flex justify-center space-x-4 my-8">
      <button
        onClick={() => goToPage(0)}
        className={`w-32 px-4 py-2 text-sm font-medium rounded-md transition-colors ${
          currentPage === 0 ? "bg-blue-500 text-white" : "bg-white text-blue-500 border border-blue-500"
        }`}
        disabled={currentPage === 0}
      >
        Basic Info
      </button>
      <button
        onClick={() => goToPage(1)}
        className={`w-32 px-4 py-2 text-sm font-medium rounded-md transition-colors ${
          currentPage === 1 ? "bg-blue-500 text-white" : "bg-white text-blue-500 border border-blue-500"
        }`}
        disabled={currentPage === 1}
      >
        Professional Summary
      </button>
      <button
        onClick={() => goToPage(2)}
        className={`w-32 px-4 py-2 text-sm font-medium rounded-md transition-colors ${
          currentPage === 2 ? "bg-blue-500 text-white" : "bg-white text-blue-500 border border-blue-500"
        }`}
        disabled={currentPage === 2}
      >
        Educational Background
      </button>
      <button
        onClick={() => goToPage(3)}
        className={`w-32 px-4 py-2 text-sm font-medium rounded-md transition-colors ${
          currentPage === 3 ? "bg-blue-500 text-white" : "bg-white text-blue-500 border border-blue-500"
        }`}
        disabled={currentPage === 3}
      >
        Work Experience
      </button>
      <button
        onClick={() => goToPage(4)}
        className={`w-32 px-4 py-2 text-sm font-medium rounded-md transition-colors ${
          currentPage === 4 ? "bg-blue-500 text-white" : "bg-white text-blue-500 border border-blue-500"
        }`}
        disabled={currentPage === 4}
      >
        Review
      </button>
    </div>
  );
};

export default FormNavigation;
