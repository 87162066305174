import React, { useCallback, useEffect, useState, useRef } from "react";
import "./SliderStyle.css";

const Slider = ({ onChange, onSliderChange }) => {
    const startTime = new Date();
    startTime.setHours(0, 1, 0, 0);
    const endTime = new Date();
    endTime.setHours(23, 59, 0, 0);

  const [minVal, setMinVal] = useState(startTime);
  const [maxVal, setMaxVal] = useState(endTime);
  const minValRef = useRef(startTime);
  const maxValRef = useRef(endTime);
  const range = useRef(null);

  // Function to format time as HH:mm
  const formatTime = (time) => {
    return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Convert to percentage
  const getPercent = useCallback(
    (value) => {
      const diff = endTime.getTime() - startTime.getTime();
      const valDiff = value.getTime() - startTime.getTime();
      return Math.round((valDiff / diff) * 100);
    },
    [startTime, endTime]
  );

  // Set width of the range
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  useEffect(() => {

    onSliderChange({
        start: formatTime(minVal),
        end: formatTime(maxVal)
    })
    // console.log("mincheck:",formatTime(minVal), " maxcheck:", formatTime(maxVal));
  }, [minVal, maxVal, onChange, formatTime]);


  // function dateToMilliseconds(dateString) {
  //   const givenTime = new Date(dateString);
  //   return givenTime.getTime();
  // }

  return (
    <div className="range_container">
      <input
        type="range"
        min={startTime.getTime()}
        max={endTime.getTime()}
        value={minVal.getTime()}
        onChange={(event) => {
          const value = new Date(Number(event.target.value));
          setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal.getTime() > endTime.getTime() - 100 && "5" }}
      />
      <input
        type="range"
        min={startTime.getTime()}
        max={endTime.getTime()}
        value={maxVal.getTime()}
        onChange={(event) => {
          const value = new Date(Number(event.target.value));
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
      </div>
      <div className="values">
        <div className="slider__left-value">{formatTime(minVal)}</div>
        <div className="slider__right-value">{formatTime(maxVal)}</div>
      </div>
    </div>
  );
};

export default Slider;
