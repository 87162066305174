import React, { useState, useEffect } from 'react';

const TimeDropdown = ({selectedTime, setSelectedTime, isModalTime, selectedDate}) => {
  const [times, setTimes] = useState([]);

  const [selectedIndex ,setSelectedIndex] = useState(0);

  useEffect(() => {
    const generateTimes = () => {
      const intervalMinutes = 30;
      const currentTime = new Date();
      const endTime = new Date();
      endTime.setHours(23, 59, 59);
      const nextRoundHour = new Date(currentTime);
      nextRoundHour.setHours(nextRoundHour.getHours() + 1);
      nextRoundHour.setMinutes(0);
  
      const timeOptions = [];
      const currentTimeString = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      if (currentTimeString !== '00:00') {
        timeOptions.push({ value: currentTimeString, label: `${currentTimeString} (Now)` });
      }
      while (nextRoundHour < endTime) {
        const timeString = nextRoundHour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        timeOptions.push({ value: timeString, label: timeString });
        nextRoundHour.setMinutes(nextRoundHour.getMinutes() + intervalMinutes);
      }
  
      setTimes(timeOptions);
    };

    const formattedDate = new Date()
        .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");

    if (selectedDate == formattedDate) {
      generateTimes();
    }else {
      generateTimeSlots("00:01", "23:30", 30, setTimes);
    }
  }, [selectedDate]);

  function generateTimeSlots(startTime, endTime, interval = 30, setTimes) {
    const timeSlots = [];
    const startHour = parseInt(startTime.split(':')[0], 10);
    const startMinute = parseInt(startTime.split(':')[1], 10);
    const endHour = parseInt(endTime.split(':')[0], 10);
    const endMinute = parseInt(endTime.split(':')[1], 10);
  
    let currentHour = startHour;
    let currentMinute = startMinute;
  
    while (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute)) {
      const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
      timeSlots.push({ value: formattedTime, label: formattedTime });
  
      currentMinute += interval;
      if (currentMinute >= 60) {
        currentMinute = 0;
        currentHour++;
      }
    }
    setTimes(timeSlots);
  }

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleTimeChangeClick = (value, index) => {
    const [time] = value.split(" ")
    setSelectedTime(time)
    setSelectedIndex(index)
  }

  return (
    <>
      {isModalTime ? (
        <select
          className="form-select"
          value={selectedTime}
          onChange={handleTimeChange}
        >
          {times.map((time, index) => (
            <option key={index} value={time.value}>
              {time.label}
            </option>
          ))}
        </select>
      ) : (
        <>
          {times.map((time, index) => (
            <div style={{cursor: 'pointer'}} key={index} onClick={() => handleTimeChangeClick(time.label, index)} className={`time1 ${selectedIndex === index ? 'active' : ''}`}>
              <span className="heading_tittle">
                {time.label} 
              </span>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default TimeDropdown;
