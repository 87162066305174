import React, { useEffect, useRef } from "react";
import { Mic, X } from "lucide-react";
import "../../css/SpeechIndicator.css";

const SpeechIndicator = ({ isRecording, onStop }) => {
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const rafIdRef = useRef(null);

  useEffect(() => {
    if (isRecording) {
      setupAudioContext();
      animate();
    } else {
      cleanupAudio();
    }

    return cleanupAudio;
  }, [isRecording]);

  const setupAudioContext = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 256;
      const bufferLength = analyserRef.current.frequencyBinCount;
      dataArrayRef.current = new Uint8Array(bufferLength);

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const source = audioContextRef.current.createMediaStreamSource(stream);
          source.connect(analyserRef.current);
        })
        .catch((err) => console.error("Error accessing microphone:", err));
    }
  };

  const cleanupAudio = () => {
    cancelAnimationFrame(rafIdRef.current);
    if (audioContextRef.current && audioContextRef.current.state !== "closed") {
      audioContextRef.current.close().catch((err) => console.error("Error closing AudioContext:", err));
    }
  };

  const animate = () => {
    rafIdRef.current = requestAnimationFrame(animate);
    if (!analyserRef.current) return;

    analyserRef.current.getByteFrequencyData(dataArrayRef.current);
    const bars = document.querySelectorAll(".audio-bar");
    const bufferLength = analyserRef.current.frequencyBinCount;

    for (let i = 0; i < bars.length; i++) {
      const index = Math.floor((i * bufferLength) / bars.length);
      const value = dataArrayRef.current[index];
      const percent = (value / 255) * 100;
      bars[i].style.height = Math.max(2, percent) + "%";
    }
  };

  if (!isRecording) return null;

  return (
    <div className="speech-indicator-overlay">
      <div className="speech-indicator-container">
        <div className="microphone-icon-wrapper">
          <Mic className="microphone-icon" />
          <div className="microphone-ping"></div>
        </div>
        <div className="audio-visualizer">
          {[...Array(20)].map((_, i) => (
            <div key={i} className="audio-bar"></div>
          ))}
        </div>
        <p className="listening-text">Listening...</p>
        <button className="stop-button" onClick={onStop}>
          <X className="stop-icon" />
          Stop Recording
        </button>
      </div>
    </div>
  );
};

export default SpeechIndicator;
