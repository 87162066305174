import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import { MdDownload } from "react-icons/md"; // Import the download icon
import "chartjs-plugin-datalabels";

const BarChart = ({ dataset }) => {
  const chartRef = useRef(null);

  if (!dataset || !dataset.dataPoints) {
    console.error("BarChart: invalid dataset provided");
    return <div>Invalid dataset</div>;
  }

  const chartData = {
    labels: dataset.dataPoints.map((item) => item.label),
    datasets: [
      {
        label: dataset.chartLabel || "Values",
        data: dataset.dataPoints.map((item) => item.value),
        backgroundColor: ["#264653", "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: dataset.yAxisLabel || "Value",
        },
      },
      x: {
        title: {
          display: true,
          text: dataset.chartLabel || "Category",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += context.raw;
            return label;
          },
        },
      },
    },
  };

  const downloadChart = () => {
    const canvas = chartRef.current.canvas;
    const image = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = image;
    link.download = "chart.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Bar data={chartData} options={options} ref={chartRef} />
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "10px" }}>
        <button onClick={downloadChart} style={{ border: "none", background: "transparent", cursor: "pointer" }}>
          Download <MdDownload size="24px" title="Download chart" /> {/* Use the icon with some styling */}
        </button>
      </div>
    </>
  );
};

export default BarChart;
