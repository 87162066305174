/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import BlogPost from "./BlogPost";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";

const blogsContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;

  @media (max-width: 1366px) {
    padding: 10px;
  }
`;

const searchContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center; // Center the items along the horizontal axis
  gap: 10px;
  margin-top: 20px;

  @media (max-width: 1366px) {
    gap: 5px;
  }
`;

const searchInputStyle = css`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 300px;

  @media (max-width: 1366px) {
    width: 100%;
  }
`;

const searchButtonStyle = css`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #0070c0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #005499;
  }

  @media (max-width: 1366px) {
    width: 100%;
    padding: 8px 0;
  }
`;

const blogsRowStyle = css`
  display: flex;
  flex-wrap: wrap; // Allow items to wrap to the next row
  justify-content: space-between; // Add space between items
  margin-top: 20px;

  @media (max-width: 800px) {
    flex-direction: column; // Stack items on smaller screens
    align-items: center;
  }
`;

const freeResourcesStyle = css`
  position: fixed;
  top: 80px;
  right: 30px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  visibility: visible; // Initially visible

  ${
    "" /* @media (max-width: 1366px) {
    top: 10px;
    right: 10px;
  } */
  }
`;

const freeResourcesLinkStyle = css`
  color: #0070c0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function Blogs({ posts }) {
  const [searchValue, setSearchValue] = useState("");
  const [filteredPosts, setFilteredPosts] = useState(posts);
  const [isAtTop, setIsAtTop] = useState(true); // State to track scroll position

  useEffect(() => {
    const filteredPosts = posts.filter((post) => {
      const isMatchingTitle = post.mainTitle.toLowerCase().includes(searchValue.toLowerCase());
      return isMatchingTitle;
    });
    setFilteredPosts(filteredPosts);
  }, [searchValue, posts]);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.pageYOffset < 100); // Sets isAtTop based on vertical scroll position
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up the event listener
    };
  }, []);

  useEffect(() => {
    const filteredPosts = posts.filter((post) => {
      const isMatchingTitle = post.mainTitle.toLowerCase().includes(searchValue.toLowerCase());
      return isMatchingTitle;
    });
    setFilteredPosts(filteredPosts);
  }, [searchValue, posts]);

  return (
    <>
      <Helmet>
        <title>Top Case Interview Prep: Learn Strategies from McKinsey, BCG, Deloitte Experts</title>
        <meta
          name="description"
          content="Ace your case interviews with our exclusive insights and expert strategies from leaders at McKinsey, BCG, and Deloitte. Dive into our in-depth preparation materials and outshine your competition!"
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "http://schema.org",
        "@type": "Blog",
        "url": "https://casestudygenerator.com/blogs",
        "publisher": {
          "@type": "Organization",
          "name": "Case Study Generator",
          "logo": {
            "@type": "ImageObject",
            "url": "https://res.cloudinary.com/djzert4i9/image/upload/v1715166670/Overview_Personal_Experience_c72fal.png"
          }
        },
        "description": "The ultimate resource for preparing for case interviews with top consulting firms. Access expertly crafted articles, tips, and strategies to succeed in your consulting career.",
        "image": {
          "@type": "ImageObject",
          "url": "https://res.cloudinary.com/djzert4i9/image/upload/v1714672812/Profitability_Framework_2_slwpcj.jpg"
        }
      }
    `}
        </script>
      </Helmet>

      <div css={blogsContainerStyle}>
        <div css={freeResourcesStyle} style={{ visibility: isAtTop ? "visible" : "hidden" }}>
          <i className="fa fa-download"></i> {/* Changed icon to download */}
          <a
            href="https://drive.google.com/file/d/1yoxjgrSnNsTYdGrKNUp0qqwgYN1qL53R/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            css={freeResourcesLinkStyle}
            onClick={() => {
              if (window.ga) {
                window.ga("send", "event", "Download", "click", "Free Resources");
              }
            }}
          >
            Unlock Your Potential with Our Free Practice Questions!
          </a>
        </div>

        <div css={searchContainerStyle}>
          <input
            css={searchInputStyle}
            type="text"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder="Search..."
          />
          <button css={searchButtonStyle} onClick={() => {}}>
            Search
          </button>
        </div>

        <div css={blogsRowStyle}>
          {filteredPosts.map((post) => (
            <BlogPost key={post.id} post={post} />
          ))}
        </div>
      </div>
    </>
  );
}

export default Blogs;
