import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, Check, Sparkles } from "lucide-react";
import "../../css/CaseCreationForm.css";

const CustomSelect = ({ options, value, onChange, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (optionValue) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  return (
    <div className="custom-select" ref={selectRef}>
      <label className="select-label">{label}</label>
      <div className="select-wrapper">
        <div className="select-value" onClick={() => setIsOpen(!isOpen)}>
          {options.find((opt) => opt.value === value)?.label || "Select an option"}
          <ChevronDown className={`icon ${isOpen ? "open" : ""}`} />
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.ul className="options-list" initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -10 }}>
              {options.map((option) => (
                <li key={option.value} className={`option ${value === option.value ? "selected" : ""}`} onClick={() => handleChange(option.value)}>
                  {option.label}
                  {value === option.value && <Check className="check-icon" />}
                </li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const CaseCreationForm = ({ onSubmit }) => {
  const [step, setStep] = useState(1);
  const [caseType, setCaseType] = useState("");
  const [industry, setIndustry] = useState("");

  const caseTypes = [
    { value: "market_entry", label: "Market Entry" },
    { value: "profitability_analysis", label: "Profitability Analysis" },
    { value: "growth_strategy", label: "Growth Strategy" },
    { value: "cost_reduction", label: "Cost Reduction" },
    { value: "pricing_strategy", label: "Pricing Strategy" },
    { value: "merger_acquisition", label: "Merger & Acquisition" },
    { value: "product_launch", label: "Product Launch" },
    { value: "supply_chain_optimization", label: "Supply Chain Optimization" },
    { value: "digital_transformation", label: "Digital Transformation" },
    { value: "customer_segmentation", label: "Customer Segmentation" },
    { value: "risk_management", label: "Risk Management" },
    { value: "innovation_strategy", label: "Innovation Strategy" },
    { value: "market_expansion", label: "Market Expansion" },
    { value: "competitive_analysis", label: "Competitive Analysis" },
    { value: "sustainability_strategy", label: "Sustainability Strategy" },
    { value: "operational_efficiency", label: "Operational Efficiency" },
    { value: "customer_experience_improvement", label: "Customer Experience Improvement" },
  ];

  const industries = [
    { value: "aerospace_defense", label: "Aerospace & Defense" },
    { value: "agriculture", label: "Agriculture" },
    { value: "automotive", label: "Automotive" },
    { value: "biotechnology", label: "Biotechnology" },
    { value: "chemicals", label: "Chemicals" },
    { value: "construction", label: "Construction" },
    { value: "consumer_goods", label: "Consumer Goods" },
    { value: "ecommerce", label: "E-commerce" },
    { value: "education", label: "Education" },
    { value: "energy_utilities", label: "Energy & Utilities" },
    { value: "entertainment_media", label: "Entertainment & Media" },
    { value: "financial_services", label: "Financial Services" },
    { value: "food_beverage", label: "Food & Beverage" },
    { value: "healthcare", label: "Healthcare" },
    { value: "hospitality_tourism", label: "Hospitality & Tourism" },
    { value: "information_technology", label: "Information Technology" },
    { value: "insurance", label: "Insurance" },
    { value: "manufacturing", label: "Manufacturing" },
    { value: "mining_metals", label: "Mining & Metals" },
    { value: "non_profit", label: "Non-profit" },
    { value: "oil_gas", label: "Oil & Gas" },
    { value: "pharmaceuticals", label: "Pharmaceuticals" },
    { value: "real_estate", label: "Real Estate" },
    { value: "retail", label: "Retail" },
    { value: "telecommunications", label: "Telecommunications" },
    { value: "transportation_logistics", label: "Transportation & Logistics" },
  ];

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const resetForm = () => {
    setStep(1);
    setCaseType("");
    setIndustry("");
  };

  const handleSubmit = () => {
    onSubmit({ caseType, industry, skills: [] }); // Include an empty skills array
    resetForm();
  };

  const renderStepSummary = () => {
    return (
      <div className="step-summary">
        {step > 1 && (
          <>
            <div className="summary-item">
              <span className="summary-label">Case Type:</span>
              <span className="summary-value">{caseTypes.find((ct) => ct.value === caseType)?.label}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">Industry:</span>
              <span className="summary-value">{industries.find((ind) => ind.value === industry)?.label || "Not selected"}</span>
            </div>
          </>
        )}
      </div>
    );
  };

  const isNextDisabled = () => {
    if (step === 1) return !caseType;
    return false;
  };

  return (
    <div className="case-creation-container">
      <h2 className="case-creation-header">Create Your Practice Case</h2>
      <form className="case-creation-form" onSubmit={handleSubmit}>
        <div className="select-container">
          <label htmlFor="caseType">Select Case Type</label>
          <div className="select-wrapper">
            <select id="caseType" value={caseType} onChange={(e) => setCaseType(e.target.value)} required>
              <option value="">Select an option</option>
              {caseTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
            <ChevronDown className="select-icon" />
          </div>
        </div>

        <div className="select-container">
          <label htmlFor="industry">Select Industry</label>
          <div className="select-wrapper">
            <select id="industry" value={industry} onChange={(e) => setIndustry(e.target.value)} required>
              <option value="">Select an option</option>
              {industries.map((ind) => (
                <option key={ind.value} value={ind.value}>
                  {ind.label}
                </option>
              ))}
            </select>
            <ChevronDown className="select-icon" />
          </div>
        </div>

        <button type="submit" className="submit-button">
          Create Practice Case
        </button>
      </form>
    </div>
  );
};

export default CaseCreationForm;
