import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config/config.index";
import { AuthContext } from "../context/auth.context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/Signup.css";

const SignUp = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false); // New state to track if the user has signed up
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
  });

  const { setToken, authenticateUser, isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     toast.info("Already logged in", {
  //       onClose: () => navigate("/"),
  //     });
  //   }
  // }, [isLoggedIn, navigate]);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password, name } = formData;

    try {
      const response = await axios.post(`${API_URL}/auth/signup`, { email, password, name });

      if (response.data.authToken) {
        localStorage.setItem("authToken", response.data.authToken);
        setToken(response.data.authToken);
        authenticateUser();
        setIsSignedUp(true); // Set user as signed up
        setShowModal(true);
      } else {
        console.error("Expected authToken in response but didn't receive it.");
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Signup error. Please try again.");
      }
    }
  };

  const Modal = () => {
    return (
      <div className="Modal-backdrop">
        <div className="Modal-content">
          <h2>Welcome!</h2>
          <p>Congratulations on signing up! Go check out practice sessions with Carl the Case Cracker and Simone the Skill Builder.</p>
          <button onClick={() => navigate("/choose")}>Let's do it!</button>
        </div>
      </div>
    );
  };

  return (
    <div className="Auth-form-container">
      {!isSignedUp && (
        <form className="Auth-form" onSubmit={handleSubmit}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Signup</h3>
            <p style={{ color: "grey" }}>Welcome! Enter the details below to sign up.</p>
            <div className="form-group">
              <label>Name:</label>
              <input type="text" name="name" value={formData.name} onChange={handleInputChange} className="form-control" />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input type="email" name="email" value={formData.email} onChange={handleInputChange} className="form-control" />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input type="password" name="password" value={formData.password} onChange={handleInputChange} className="form-control" />
            </div>
            <button type="submit" className="btn-primary mt-3">
              Submit
            </button>
          </div>
        </form>
      )}
      {showModal && <Modal />}
    </div>
  );
};

export default SignUp;
