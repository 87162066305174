import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { Helmet } from "react-helmet";
import { API_URL_NEW_BACKEND } from "../../config/config.index.js";
import { marked } from "marked";
import DOMPurify from "dompurify";
import SimonePicture from "../../assets/Simone_Skills.png";
import { AuthContext } from "../../context/auth.context.js";
import { updateSubscriptionFeatures } from "../../services/SubscriptionServices";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PlusIcon, ArrowUpIcon } from "lucide-react";
import "../../css/SimoneSkills.css";

function SimoneSkills() {
  const [userInput, setUserInput] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [selectedDifficulty, setSelectedDifficulty] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const eventSourceRef = useRef(null);
  const messagesEndRef = useRef(null);
  const lastMessageRef = useRef(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationHistory]);

  useEffect(() => {
    const updateFeature = async () => {
      if (threadId !== null) {
        await updateSubscriptionFeatures({ featureName: "AI - Skill Building" });
      }
    };

    updateFeature();
  }, [threadId]);

  const exampleSkills = ["Market Sizing", "Profitability Analysis", "Case Math", "Framework Development"];

  const difficulties = ["Beginner", "Intermediate", "Advanced"];

  const addToConversation = useCallback((type, text) => {
    setConversationHistory((prev) => {
      if (type === "ai" && prev.length > 0 && prev[prev.length - 1].type === "ai") {
        const newHistory = [...prev];
        newHistory[newHistory.length - 1] = { ...newHistory[newHistory.length - 1], text };
        return newHistory;
      } else {
        return [...prev, { type, text: text || "" }];
      }
    });
  }, []);

  const handleSkillSelection = async (skill) => {
    if (selectedDifficulty) {
      const promptText = `${selectedDifficulty.toLowerCase()} level question about: ${skill}`;
      setSelectedQuestion(promptText);
      setChatStarted(true);
      await fetchOpenAIResponse(promptText);
    } else {
      toast.warn("Please select a difficulty level first!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const fetchOpenAIResponse = async (promptText) => {
    setLoading(true);
    try {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      const response = await fetch(`${API_URL_NEW_BACKEND}/api/skill-building`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userMessage: promptText, threadId }),
      });

      const data = await response.json();

      if (!threadId) {
        setThreadId(data.threadId);
      }

      const newEventSource = new EventSource(`${API_URL_NEW_BACKEND}/api/stream-responses?threadId=${data.threadId}`);

      let rawContent = "";

      newEventSource.addEventListener("messageStart", () => {
        rawContent = "";
        addToConversation("ai", "");
      });

      newEventSource.addEventListener("textDelta", (event) => {
        const eventData = JSON.parse(event.data);
        rawContent += eventData.value;
        const html = DOMPurify.sanitize(marked(rawContent));
        addToConversation("ai", html);
      });

      newEventSource.onopen = () => console.log("SSE connection opened successfully.");
      newEventSource.onerror = (event) => {
        console.error("SSE connection encountered an error:", event);
        setLoading(false);
        newEventSource.close();
      };

      eventSourceRef.current = newEventSource;
    } catch (error) {
      console.error("An error occurred:", error);
      addToConversation("ai", "Sorry, I encountered an error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    if (userInput.trim() === "" || loading) return;

    addToConversation("user", userInput);
    setUserInput("");
    await fetchOpenAIResponse(userInput);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleRestartPractice = () => {
    setChatStarted(false);
    setConversationHistory([]);
    setUserInput("");
    setSelectedDifficulty("");
    setSelectedQuestion(null);
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }
  };

  return (
    <div className={`simone-skills-container ${chatStarted ? "chat-active" : ""}`}>
      <Helmet>
        <title>Simone Skills - Master Consulting Case Skills with AI Coaching | Case Study Generator</title>
        <meta
          name="description"
          content="Develop your consulting skills with Simone, our AI-driven strategy coach. Specializing in case math, market analysis, and problem-solving frameworks, Simone provides targeted skill-building exercises and personalized feedback to advance your consulting career."
        />
        <link rel="canonical" href="https://www.casestudygenerator.com/practice-skills-related-to-case-interview" />
      </Helmet>
      <ToastContainer />

      {!chatStarted ? (
        <>
          <div className="welcome-card">
            <h1 className="welcome-title">AI-Powered Skill Building</h1>
            <p className="welcome-text">
              Dive into targeted practice sessions that build your confidence and competence. Whether you're tackling framework application or
              quantitative reasoning, you'll develop the sharp analytical skills that make interviewers take notice. Start now and transform how you
              approach cases.
            </p>
          </div>
          <div className="difficulty-selector">
            {difficulties.map((diff) => (
              <button
                key={diff}
                className={`difficulty-button ${selectedDifficulty === diff ? "selected" : ""}`}
                onClick={() => setSelectedDifficulty(diff)}
              >
                {diff}
              </button>
            ))}
          </div>
          <div className="example-questions">
            {exampleSkills.map((skill, index) => (
              <div key={index} className="question-card" onClick={() => handleSkillSelection(skill)}>
                {skill}
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="chat-area">
            {selectedQuestion && <div className="selected-question">{selectedQuestion}</div>}
            {conversationHistory.map((message, index) => (
              <div key={index} className={`message-wrapper ${message.type}`} ref={index === conversationHistory.length - 1 ? lastMessageRef : null}>
                {message.type === "user" ? <div className="user-icon">👤</div> : <img src={SimonePicture} alt="Carl" className="ai-icon" />}
                <div className={`message ${message.type === "user" ? "user-message" : "ai-message"}`}>
                  <p dangerouslySetInnerHTML={{ __html: message.text }} />
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </>
      )}
      <div className="chat-input-container">
        <button className="add-button" title="Add attachment">
          <PlusIcon size={24} />
        </button>
        <input
          type="text"
          className="chat-input"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder={chatStarted ? "Send a message." : "Or type here what you want to practice."}
          disabled={loading}
        />
        <button className="send-button" onClick={handleSend} disabled={loading || userInput.trim() === ""} title="Send message">
          <ArrowUpIcon size={24} />
        </button>
      </div>
      {chatStarted && (
        <button className="restart-button" onClick={handleRestartPractice}>
          New Practice
        </button>
      )}
    </div>
  );
}

export default SimoneSkills;
