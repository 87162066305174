import { useEffect, useState } from "react";
import { fetchUserMeetings, updateMeetingStatus } from "../../../services/MeetingServices";
import { toast } from "react-toastify";
import { convertDateToString, formatTime } from "../../../utils/casePractice";
import { getExperienceRange } from "../utils";
import NoMeetings from "../Common/NoMeetings";

const PastTab = ({ currentMeetingTab, fetchMeetingData }) => {
  const [pastMeetings, setPastMeetings] = useState([]);

  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };

  const fetchData = async () => {
    try {
      const body = { currentStatus: currentMeetingTab };
      const response = await fetchUserMeetings(body, headers);
      setPastMeetings(response.data.userMeetings);
    } catch (error) {
      console.error("Error fetching meeting list:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentMeetingTab]);

  const onDeleteMeeting = async (meetingId) => {
    const data = {
      meetingStatus: "delete",
      meetingId: meetingId,
    };
    try {
      const response = await updateMeetingStatus(data, headers);
      if (response && response.status == 200) {
        toast.success(response.data.message);
        fetchData();
      }
    } catch (error) {
      if (error.response.status == 400) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      {pastMeetings.map((item, index) => {
        return (
          <div key={index} className="tab_content_outer mt-4">
            <div className="tab_content d-flex justify-content-between mb-4">
              <div className="d-flex gap-3">
                <div className="tab_icon d-flex justify-content-center align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stopwatch" viewBox="0 0 16 16">
                    <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z" />
                    <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3" />
                  </svg>
                </div>
                <div className="">
                  <h5 className="mb-0">
                    Scheduled time {formatTime(parseInt(item?.startTime))} - {formatTime(parseInt(item?.endTime))}
                  </h5>
                  <h4 className="mb-0">{convertDateToString(item?.date)}</h4>
                  <h6>Host: {item?.organizer?.name}</h6>
                  <div>
                    <span>
                      {item?.organizer?.user_experience_level} ({getExperienceRange(item?.organizer?.user_experience_level)} cases) (
                      {item?.organizer?.totalMeetingCount + 1} meetings)
                    </span>
                  </div>
                </div>
              </div>
              <div className="join_btn  d-flex gap-2 align-items-start">
                <button onClick={() => onDeleteMeeting(item?._id)} className="btn">
                  Delete
                </button>
              </div>
            </div>
          </div>
        );
      })}
      {pastMeetings?.length == 0 && <NoMeetings fetchData={fetchMeetingData} text="Past" />}
    </>
  );
};

export default PastTab;
