import React from "react";
import { Pie } from "react-chartjs-2";
import "../../../css/FreeCase/CaseInterviewDisplay.css";
import "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";

const PieChart = ({ dataset }) => {
  const dataPoints = dataset.dataPoints.map((point) => point.value);
  const labels = dataset.dataPoints.map((point) => point.label);

  const chartData = {
    labels,
    datasets: [
      {
        label: dataset.chartLabel,
        data: dataPoints,
        backgroundColor: generateColors(dataPoints.length),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: true, // Always display datalabels
        color: "#fff",
        anchor: "center",
        align: "center",
        font: {
          size: 11,
        },
        formatter: (value, context) => {
          // Since you want to log inside formatter, ensure data exists
          if (context.chart.data.labels[context.dataIndex] !== undefined) {
            console.log(`Formatted Label:`, context.chart.data.labels[context.dataIndex], ": ", value);
          }
          return context.chart.data.labels[context.dataIndex] + ": " + value;
        },
        textStrokeColor: "#000",
        textStrokeWidth: 0.5,
        // textShadowBlur: 2,
      },
    },
  };

  console.log("ChartData:", chartData); // Log the chart data
  console.log("Options:", options); // Log the options

  return <Pie data={chartData} options={options} className="pie-chart" />;
};

// Function to generate colors dynamically with a more professional palette
const generateColors = (length) => {
  const baseColors = [
    "#7F8FA4", // Desaturated Blue
    "#BDA0A4", // Soft Rose
    "#8FB8D0", // Gentle Sky Blue
    "#CABD95", // Muted Gold
    "#A89CB0", // Light Purple
    "#8FBDB5", // Subdued Green
    "#C3B2E5", // Light Lavender
    "#FFCEB4", // Warm Peach
    "#BFD9D6", // Pale Mint
    "#E0C7A9", // Warm Beige
    // Add more colors if needed
  ];

  const colors = [];
  for (let i = 0; i < length; i++) {
    colors.push(baseColors[i % baseColors.length]);
  }
  return colors;
};

export default PieChart;
