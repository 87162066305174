import React, { useState, useEffect } from "react";
import "../css/HomePage.css";

import Header from "./Home/Header";
import HeroSection from "./Home/HeroSection";
import LogoSection from "./Home/LogoSection";
import FeaturesSection from "./Home/FeatureSection";
import PossiblitiesSection from "./Home/PossiblitiesSection";
import HeadingSection from "./Home/HeadingSection";
import ReviewSection from "./Home/ReviewSection";
import PricingSection from "./Home/PricingSection";
import FAQsSection from "./Home/FAQsSection";
import SalesSection from "./Home/SalesSection";
import Footer from "./Home/Footer";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="home">
      {/* <Header /> */}
      <HeroSection />
      <LogoSection />
      <FeaturesSection />
      <PossiblitiesSection />
      {/* <HeadingSection /> */}
      {/* <ReviewSection /> */}
      <PricingSection />
      <FAQsSection />
      <SalesSection />
      <Footer />
    </div>
  );
}

export default App;
