import React, { useState } from 'react';

const CaseExperienceDropdown = ({onExperienceChange, user}) => {
  const [selectedExperience, setSelectedExperience] = useState('Novice');

  const handleExperienceChange = (event) => {
    setSelectedExperience(event.target.value);
    onExperienceChange(event.target.value)
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await getMeetingList({
  //       visibleType: selectedExperience
  //     });
  //     setMeetings(response.data.data);
  //   } catch (error) {
  //     console.error("Error fetching meeting list:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // },[selectedExperience])

  return (
    <div className="col-md-3 mb-3">
      <div>
        <select className="form-select" value={selectedExperience} onChange={handleExperienceChange}>
          <option value="" disabled>Select Case Experience</option>
          <option value="Novice">
            <div>Novice</div>
          </option>
          {user[0]?.user_experience_level !== "Novice" && <option value="Advanced">
            <div>Advanced</div>
          </option>}
          {user[0]?.user_experience_level == "Pro" && <option value="Pro">
            <div>Pro</div>
          </option>}
        </select>
      </div>
    </div>
  );
};

export default CaseExperienceDropdown;
