// src/components/PageTracker.jsx
import { useEffect } from "react";
import { ga4Event } from "../utils/ga4";

const PageTracker = ({ location }) => {
  useEffect(() => {
    ga4Event("page_view", { page_path: location.pathname + location.search });
  }, [location]);

  return null;
};

export default PageTracker;
