import React, { useEffect, useState } from "react";
import { API_URL } from "../config/config.index";
import axios from "axios";
import { toast } from "react-toastify";

// import { API_URL } from "../config/config.index";

import "../css/PlansTwo.css";
import {
  getUserSubscription,
  planCheckout,
  updateSubscription,
} from "../services/SubscriptionServices";
import { useNavigate } from "react-router-dom";
import ChangeSubscriptionModal from "./ChangeSubscriptionModal";
const StripeSubscriptions = () => {
  const [plans, setPlans] = useState([]);
  const [subscribedPlan, setSubscribedPlan] = useState(null);
  const navigate = useNavigate();
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [oldPlan, setOldPlan] = useState(null);
  const [newPlan, setNewPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPlanId, setLoadingPlanId] = useState(null);

  const handleSubscriptionModal = (plan) => {
    setOldPlan(subscribedPlan);
    setNewPlan(plan);
    setShowChangeModal(true);
  };

  const handleCloseModal = () => {
    setShowChangeModal(false);
  };

  const handleChangeSubscription = async () => {
    try {
      setIsLoading(true);
      const response = await updateSubscription({ priceId: newPlan.priceId });
      if (response.status === 200) {
        toast.success("Plan Changed Successfully");
      } else {
        console.error("Failed to retrieve checkout URL:", response);
      }
      setIsLoading(false);

      setShowChangeModal(false);
      setSubscribedPlan(null);
      fetchPlans();
      fetchUserSubscription();
      navigate("/profile");
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchPlans();
    fetchUserSubscription();
  }, []);

  const fetchPlans = async () => {
    try {
      const subscriptionPlans = await axios.get(`${API_URL}/plans/get`);
      setPlans(subscriptionPlans.data);
    } catch (error) {}
  };

  const fetchUserSubscription = async () => {
    try {
      const response = await getUserSubscription();
      setSubscribedPlan(response.data.subscription);
    } catch (error) {}
  };

  const handleBuyPlan = async (planId) => {
    try {
      setIsLoading(true);
      setLoadingPlanId(planId);
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        showLoginError();
        return;
      }

      const response = await planCheckout({ planId });
      if (response.status === 200) {
        const { checkoutUrl } = response.data;
        window.location.href = checkoutUrl;
      } else if (response.status >= 400 && response.status < 404) {
        showLoginError();
      } else {
        toast.error("We are facing a problem with the server.");
        console.error("Unexpected response status:", response.status);
      }
      setIsLoading(false);
      setLoadingPlanId(null);
    } catch (error) {
      setIsLoading(false);
      setLoadingPlanId(null);

      if (error.response.status >= 400 && error.response.status < 404) {
        showLoginError();
      }
      console.error("Error:", error);
    }
  };

  const showLoginError = () => {
    setIsLoading(false);
    setLoadingPlanId(null);
    toast.error("You need to be logged in first");
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/login";
    }, 1500);
  };

  return (
    <>
      <div className="plans-container">
        <div className="header-plans">
          <span className="header-title centered">Choose Plan</span>
          <span className="header-description centered">
            Choose Your Ideal Subscription Option
          </span>
        </div>
        <div className="plans-carousel">
          {plans &&
            plans?.map((plan, index) => {
              return (
                <div key={index} className="price-card">
                  <div className="card-header">
                    <span className="package-title">{plan.name}</span>
                  </div>
                  <div className="price-card-description">
                    <span className="package-description">
                      {plan.description}
                    </span>
                  </div>
                  <div className="card-pricing">
                    <span className="price">€ {plan?.price}</span>
                    <span className="pricing-period">/ {plan.interval}</span>
                  </div>
                  <div className="buy-plan">
                    {subscribedPlan === null && (
                      <button
                        className="start-trial-btn full-width"
                        onClick={() => handleBuyPlan(plan._id)}
                        disabled={isLoading}
                      >
                        {loadingPlanId === plan._id
                          ? "Loading..."
                          : "Start Trial"}
                      </button>
                    )}
                    {subscribedPlan !== null &&
                      subscribedPlan.plan === plan._id && (
                        <button className="subscribed-btn full-width" disabled>
                          Subscribed Plan
                        </button>
                      )}
                    {subscribedPlan !== null &&
                      subscribedPlan.plan !== plan._id && (
                        <button
                          className="upgrade-btn full-width"
                          onClick={() => handleSubscriptionModal(plan)}
                          disabled={isLoading}
                        >
                          {loadingPlanId === plan._id
                            ? "Loading..."
                            : "Upgrade Plan"}
                        </button>
                      )}
                  </div>
                  <div className="card-features">
                    <span className="feature-title">This plan includes:</span>
                    {plan?.features?.length > 0 &&
                      plan?.features?.map((feature, index) => {
                        return (
                          <div key={index} className="feature">
                            <i className="check-icon gray"></i>
                            <span className="feature-name">{feature.name}</span>
                            {feature.quantity > 1 && (
                              <span className="feature-quantity">
                                {feature.quantity}*
                              </span>
                            )}
                          </div>
                        );
                      })}
                  </div>
                  <ChangeSubscriptionModal
                    isOpen={showChangeModal}
                    onClose={handleCloseModal}
                    oldPlan={oldPlan?.planName}
                    newPlan={newPlan?.name}
                    handleChangePlan={handleChangeSubscription}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default StripeSubscriptions;
