import React from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { Line, Radar } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faTrophy, faBullseye, faLightbulb, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../../../css/PersonalGrowth/MetricDetailsPage.css";

const MetricDetailsPage = () => {
  const { metric } = useParams();
  const location = useLocation();
  const { metricDetails, allPractices, allMetrics } = location.state || {};

  if (!metricDetails || !allPractices || !allMetrics) {
    return <div className="error-message">Error: Missing data. Please go back and try again.</div>;
  }

  const formatMetricName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatUrlParam = (param) => {
    return param.replace(/-/g, " ").replace(/_/g, " ");
  };

  const formattedMetric = formatUrlParam(metric);

  const getMetricScores = () => {
    return allPractices
      .map((practice) => {
        const metricAnalysis = practice.detailedEvaluation?.MetricAnalysis?.find((m) => m.Metric.toLowerCase() === formattedMetric.toLowerCase());
        return metricAnalysis ? parseFloat(metricAnalysis.Performance) : null;
      })
      .filter((score) => score !== null);
  };

  const metricScores = getMetricScores();
  const latestScore = metricScores[metricScores.length - 1] || 0;
  const averageScore = metricScores.reduce((sum, score) => sum + score, 0) / metricScores.length || 0;
  const scoreChange =
    metricScores.length > 1 ? (((latestScore - metricScores[metricScores.length - 2]) / metricScores[metricScores.length - 2]) * 100).toFixed(2) : 0;

  const keyMetricsData = [
    {
      icon: faChartLine,
      title: "Latest Score",
      value: latestScore.toFixed(2),
      subtext: `${scoreChange}% from last practice`,
      changeType: scoreChange >= 0 ? "positive" : "negative",
    },
    {
      icon: faTrophy,
      title: "Average Score",
      value: averageScore.toFixed(2),
      subtext: "For this specific metric",
    },
    {
      icon: faBullseye,
      title: "Improvement Area",
      value: metricDetails.areasForImprovement[0]?.Description || "N/A",
      subtext: "Focus on this to improve",
    },
    {
      icon: faLightbulb,
      title: "Key Strength",
      value: metricDetails.strengths[0]?.Description || "N/A",
      subtext: "Your strongest aspect",
    },
  ];

  const chartData = {
    labels: allPractices.map((practice) => new Date(practice.date).toLocaleDateString()),
    datasets: [
      {
        label: formatMetricName(formattedMetric), // Use formattedMetric here
        data: metricScores,
        borderColor: "#4CAF50",
        backgroundColor: "rgba(76, 175, 80, 0.1)",
        fill: true,
      },
    ],
  };

  const radarData = {
    labels: Object.keys(allMetrics).map((key) => formatMetricName(key)),
    datasets: [
      {
        label: "Your Skills",
        data: Object.values(allMetrics).map((m) => m.score),
        backgroundColor: "rgba(33, 150, 243, 0.2)",
        borderColor: "#2196F3",
        pointBackgroundColor: "#2196F3",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: 10,
        grid: {
          color: "rgba(0, 0, 0, 0.05)",
        },
      },
      x: {
        grid: {
          color: "rgba(0, 0, 0, 0.05)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const radarOptions = {
    scale: {
      ticks: { beginAtZero: true, max: 10 },
      pointLabels: {
        font: {
          size: 12,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="metric-details-page">
      <Link to="/progress" className="back-button">
        <FontAwesomeIcon icon={faArrowLeft} /> Back to Progress
      </Link>
      <header className="metric-header">
        <h1>{formatMetricName(metric)}</h1>
      </header>

      <div className="key-metrics">
        {keyMetricsData.map((data, index) => (
          <div key={index} className="metric-card">
            <div className="metric-card-header">
              <FontAwesomeIcon icon={data.icon} className="metric-icon" />
              <h2 className="metric-title">{data.title}</h2>
            </div>
            <div className="metric-card-body">
              <p className={`metric-value ${index > 1 ? "metric-value-text" : ""}`}>{data.value}</p>
              <p className="metric-subtext">{data.subtext}</p>
              {data.changeType && (
                <span className={`metric-change ${data.changeType}`}>
                  {data.changeType === "positive" ? "↑" : "↓"} {Math.abs(parseFloat(scoreChange))}%
                </span>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="charts-container">
        <div className="chart-card">
          <h3>Performance Over Time</h3>
          <Line data={chartData} options={chartOptions} />
        </div>
        <div className="chart-card">
          <h3>Skills Overview</h3>
          <Radar data={radarData} options={radarOptions} />
        </div>
      </div>

      <div className="recent-activities">
        <h3>Recent Activities and Examples</h3>
        {allPractices
          .slice(-2)
          .reverse()
          .map((practice, index) => (
            <div key={index} className="activity-item">
              <h4>Practice Date: {new Date(practice.date).toLocaleDateString()}</h4>
              <p>
                <strong>Feedback:</strong>{" "}
                {practice.detailedEvaluation?.MetricAnalysis?.find((m) => m.Metric.toLowerCase() === metric.toLowerCase())?.Strengths?.[0]
                  ?.Description || "N/A"}
              </p>
              <p>
                <strong>Next Steps:</strong>{" "}
                {practice.detailedEvaluation?.MetricAnalysis?.find((m) => m.Metric.toLowerCase() === metric.toLowerCase())?.SpecificPracticeSuggestion
                  ?.Instructions || "N/A"}
              </p>
              <button className="practice-button">Practice</button>
            </div>
          ))}
      </div>

      <div className="improvement-tips">
        <h3>Improvement Tips</h3>
        <ul>
          {metricDetails.tips.map((tip, index) => (
            <li key={index}>{tip}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MetricDetailsPage;
