import React from "react";
import timesaving from "../../assets/Time-Saving.svg";
import websearch from "../../assets/Web-Search.svg";

import models from "../../assets/Multiple-Models.avif";
import PromptLibrary from "../../assets/Prompt-Library.avif";
import ChatSynchronization from "../../assets/Chat-Synchronization.svg";

const FeaturesSection = () => {
  return (
    <section className="features-section">
      <h2 className="features-title">
        Your Path to Consulting <br></br> Excellence Starts Here
      </h2>
      <p className="features-subtitle">
        Transform from candidate to consultant with the most comprehensive<br></br>interview preparation platform tailored for your success.
      </p>
      <div className="features-grid">
        <div className="feature-item">
          <img
            src="https://res.cloudinary.com/djzert4i9/image/upload/v1720104100/Screenshot_Personal_Tutor_jkx7iz.png"
            alt="AI Interviewer"
            className="feature-image"
          />
          <h3>Your 24/7 AI Interview Coach</h3>
          <p>
            Practice with an AI tutor that's always ready, day or night. It adapts to<br></br>
            your skill level, offering increasingly challenging cases as you improve.<br></br>
            Receive detailed, immediate feedback on your structure, analysis, and<br></br>
            communication. Learn from mistakes in a risk-free environment, and<br></br>
            track your progress across key consulting competencies over time.<br></br>
            Prepare efficiently and confidently for your real interviews.
          </p>
        </div>
        <div className="feature-item-2">
          <img
            src="https://res.cloudinary.com/djzert4i9/image/upload/v1720103557/Screenshot_Progress_m31qgh.png"
            alt="Progress Tracking Dashboard"
            className="feature-image"
          />
          <h3>Track Your Consulting Skills Growth</h3>
          <p>Visualize your improvement across key competencies, identify trends, and focus your efforts where it matters most.</p>
        </div>
      </div>
      <div className="features-grid-2">
        <div className="feature-items">
          <img
            src="https://res.cloudinary.com/djzert4i9/image/upload/v1720101422/Screenshot_Skills_gjheda.png"
            alt="Skill Building"
            className="feature-image"
          />
          <h3>Build Your Consulting Toolkit</h3>
          <p>
            Develop essential skills like market sizing and<br></br>framework building through focused practice.
          </p>
        </div>
        <div className="feature-items">
          <img
            src="https://res.cloudinary.com/djzert4i9/image/upload/v1715166670/Overview_Personal_Experience_c72fal.png"
            alt="Personal Experience"
            className="feature-image"
          />
          <h3>Nail the Personal Interview</h3>
          <p>
            Prepare for firm-specific cultural assessments<br></br>and behavioral interviews to stand out<br></br>from other candidates.
          </p>
        </div>
        <div className="featured-items">
          <img
            src="https://res.cloudinary.com/djzert4i9/image/upload/v1720101566/dylan-ferreira-HJmxky8Fvmo-unsplash_pjeud7.jpg"
            alt="Peer Network"
            className="feature-image"
          />
          <h3>Learn from Future Colleagues</h3>
          <p>
            Connect with fellow aspiring consultants for<br></br>mock interviews and collaborative learning.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
