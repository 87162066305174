import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { ChevronLeft, ChevronRight, CheckCircle, Circle, PlayCircle, ChevronDown, ChevronUp, Rocket, BarChart2 } from "lucide-react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import "../../../css/Academy/LessonView.css";
import { API_URL } from "../../../config/config.index.js";
import axios from "axios";
import { useAcademy } from "../../../context/academy.context.js";
import IssueTreeBuilder from "./InterActiveTree.jsx";
import ThreeCsFramework from "./InterActiceCs.jsx";
import SummaryMarkdownRenderer from "./SummaryMarkdownRenderer.jsx";
import { updateSubscriptionFeatures, getUserSubscription } from "../../../services/SubscriptionServices.js";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import { AuthContext } from "../../../context/auth.context.js";

const LessonView = () => {
  const [activeSection, setActiveSection] = useState("Learning goals");
  const [expandedSections, setExpandedSections] = useState({});
  const [lesson, setLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFeatureAllowed, setIsFeatureAllowed] = useState(false);
  const [warningText, setWarningText] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);

  const [prevLesson, setPrevLesson] = useState(null);
  const [nextLesson, setNextLesson] = useState(null);

  const navigate = useNavigate();
  const { lessonId } = useParams();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { moduleTitle, dayTitle, currentLesson } = location.state || {};
  const { dayData, setDayData } = useAcademy();

  const sectionRefs = useRef({});
  const contentRef = useRef(null);

  const componentMap = {
    IssueTreeBuilder: IssueTreeBuilder,
    ThreeCsFramework: ThreeCsFramework,
  };

  const fetchLessonData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/academy/lesson/${lessonId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setLesson(response.data.lesson);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching lesson data:", error);
      setError("Failed to load lesson data. Please try again.");
      setLoading(false);
    }
  }, [lessonId]);

  useEffect(() => {
    fetchLessonData();
  }, [fetchLessonData]);

  useEffect(() => {
    if (lesson && lesson.summary) {
      console.log("Summary content:", lesson.summary);
    }
  }, [lesson]);

  const fetchUserSubscription = async () => {
    try {
      const response = await getUserSubscription();
      if (response.status === 200) {
        const subscription = response.data.subscription;
        const subscriptionFeatures = subscription.subscriptionFeatures;

        const featureExists = subscriptionFeatures.find((feature) => feature.name === "AI - Case Practice");

        if (featureExists) {
          if (featureExists.usedQuantity < featureExists.quantity) {
            setIsFeatureAllowed(true);
            setWarningText(null);
          } else {
            setWarningText("Limit is Exhausted");
          }
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      setWarningText(error.response.data.error);
    }
  };

  useEffect(() => {
    fetchUserSubscription();
  }, []);

  const formatTableCode = (codeContent) => {
    const lines = codeContent.trim().split("\n");
    if (lines.length < 2) return codeContent;

    const rows = lines.map((line) =>
      line
        .split("|")
        .map((cell) => cell.trim())
        .filter((cell) => cell !== "")
    );

    const hasHeader = rows[0].some((cell) => cell.startsWith("**") && cell.endsWith("**"));
    const bodyRows = hasHeader ? rows.slice(1) : rows;
    const maxColumns = Math.max(...rows.map((row) => row.length));

    return (
      <div className="lv-table-wrapper">
        <table className="lv-code-table">
          {hasHeader && (
            <thead>
              <tr>
                {rows[0].map((cell, index) => (
                  <th key={index}>
                    <ReactMarkdown>{cell.replace(/^\*\*|\*\*$/g, "")}</ReactMarkdown>
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {bodyRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(maxColumns)].map((_, cellIndex) => (
                  <td key={cellIndex}>{row[cellIndex] ? <ReactMarkdown>{row[cellIndex]}</ReactMarkdown> : null}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderContent = (contentItem) => {
    switch (contentItem.type) {
      case "text":
        return <ReactMarkdown>{contentItem.data}</ReactMarkdown>;
      case "code":
        if (contentItem.data.includes("|")) {
          return formatTableCode(contentItem.data);
        }
        return <ReactMarkdown>{`\`\`\`\n${contentItem.data}\n\`\`\``}</ReactMarkdown>;
      case "table":
        return <div dangerouslySetInnerHTML={{ __html: contentItem.data }} />;
      case "image":
        return (
          <figure className="lv-figure">
            <img src={contentItem.data} alt={contentItem.caption} className="lv-image" />
            {contentItem.caption && <figcaption className="lv-caption">{contentItem.caption}</figcaption>}
          </figure>
        );
      case "video":
        return (
          <div className="lv-video-wrapper">
            <iframe
              width="100%"
              height="315"
              src={contentItem.data}
              title={contentItem.caption}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {contentItem.caption && <p className="lv-caption">{contentItem.caption}</p>}
          </div>
        );
      case "customComponent":
        const CustomComponent = componentMap[contentItem.component];
        return (
          <>
            {contentItem.data && <ReactMarkdown>{contentItem.data}</ReactMarkdown>}
            {CustomComponent && <CustomComponent {...contentItem.props} />}
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5, root: contentRef.current }
    );

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, [lesson]);

  useEffect(() => {
    if (dayData && currentLesson) {
      const currentDayLessons = dayData.filter((item) => item.dayNumber === currentLesson.dayNumber);
      const currentIndex = currentDayLessons.findIndex((item) => item._id === currentLesson._id);
      setPrevLesson(currentIndex > 0 ? currentDayLessons[currentIndex - 1] : null);
      setNextLesson(currentIndex < currentDayLessons.length - 1 ? currentDayLessons[currentIndex + 1] : null);
    }
  }, [dayData, currentLesson]);

  const toggleLessonCompletion = async () => {
    try {
      setIsUpdating(true);
      if (!user) {
        toast.info("You have to be logged in to save your progress.");
        return;
      }

      const currentStatus = dayData.find((item) => item._id === lessonId)?.status;
      const newStatus = currentStatus === "completed" ? "not-started" : "completed";

      await axios.post(
        `${API_URL}/academy/progress`,
        { lessonId, status: newStatus, userId: user._id },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );

      setDayData((prevDayData) => prevDayData.map((item) => (item._id === lessonId ? { ...item, status: newStatus } : item)));
    } catch (error) {
      console.error("Error updating lesson progress:", error);
      setError("Failed to update lesson progress. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const toggleSection = (sectionId) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const scrollToSection = (sectionId) => {
    sectionRefs.current[sectionId]?.scrollIntoView({ behavior: "smooth" });
  };

  const navigateToLesson = (lesson) => {
    if (lesson) {
      navigate(`/lesson/${lesson._id}`, {
        state: {
          moduleTitle,
          dayTitle,
          currentLesson: lesson,
        },
      });
    }
  };

  const getStatusIcon = (status) => {
    if (status === "completed") {
      return <CheckCircle size={16} className="lv-status-completed" />;
    } else if (status === "in-progress") {
      return <PlayCircle size={16} className="lv-status-in-progress" />;
    } else {
      return <Circle size={16} className="lv-status-not-started" />;
    }
  };

  const getButtonText = () => {
    const currentStatus = dayData.find((item) => item._id === lessonId)?.status;
    if (currentStatus === "completed") {
      return (
        <>
          <CheckCircle size={20} />
          Mark as Incomplete
        </>
      );
    } else {
      return (
        <>
          <Circle size={20} />
          Mark as Complete
        </>
      );
    }
  };

  if (loading) {
    return <div className="lv-loading">Loading lesson data...</div>;
  }

  if (error) {
    return <div className="lv-error">{error}</div>;
  }

  if (!lesson) {
    return <div className="lv-no-data">No lesson data available.</div>;
  }

  return (
    <>
      <Helmet>
        <title>{`${lesson.name} | Case Study Generator Prep Lesson`}</title>
        <meta
          name="description"
          content={`Master ${lesson.name} for your consulting case interview with Case Study Generator. Interactive content, expert tips, and practice exercises to boost your problem-solving skills.`}
        />
        <meta
          name="keywords"
          content={`${lesson.name.toLowerCase()}, case interview skills, consulting frameworks, problem-solving techniques, ${moduleTitle.toLowerCase()}, case study generator`}
        />
        <link rel="canonical" href={`https://www.casestudygenerator.com/lesson/${lessonId}`} />
        <meta property="og:title" content={`${lesson.name} | Case Study Generator Consulting Lesson`} />
        <meta
          property="og:description"
          content={`Enhance your case interview skills with Case Study Generator's lesson on ${lesson.name}. Part of our comprehensive consulting interview prep academy.`}
        />
        <meta property="og:url" content={`https://www.casestudygenerator.com/lesson/${lessonId}`} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="lv-container">
        <div className="lv-sidebar">
          <button className="lv-back-button" onClick={() => navigate("/academy")}>
            <ChevronLeft size={20} />
            <span>{moduleTitle}</span>
          </button>
          <h2 className="lv-day-title">{dayTitle}</h2>
          <ul className="lv-lesson-list">
            {dayData
              .filter((item) => item.dayNumber === currentLesson.dayNumber)
              .map((item, index) => (
                <li key={index} className={`lv-lesson-item ${item._id === currentLesson._id ? "active" : ""}`} onClick={() => navigateToLesson(item)}>
                  {getStatusIcon(item.status)}
                  <span>{item.name}</span>
                </li>
              ))}
          </ul>

          <div className="lv-ai-tools">
            <h2 className="lv-ai-tools-title">Turn Theory into Practice!</h2>
            <Link to="/practice-case-interview" className="lv-ai-tool-button">
              <Rocket size={20} />
              <span>AI - Case Practice</span>
            </Link>
            <Link to="/practice-skills-related-to-case-interview" className="lv-ai-tool-button">
              <BarChart2 size={20} />
              <span>AI - Skill Building</span>
            </Link>
          </div>
        </div>

        <div className="lv-content" ref={contentRef}>
          <h1 className="lv-title">{lesson.name}</h1>

          {lesson.videoUrl && (
            <div className="lv-video-container">
              <iframe
                width="100%"
                height="315"
                src={lesson.videoUrl}
                title="Lesson Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}

          <div className="lv-goals" ref={(el) => (sectionRefs.current["Learning goals"] = el)} id="Learning goals">
            <h2>After this lesson, you will be able to:</h2>
            <ul>
              {lesson.learningGoals.map((goal, index) => (
                <li key={index}>{goal}</li>
              ))}
            </ul>
          </div>

          {lesson.sections.map((section, index) => (
            <section key={index} ref={(el) => (sectionRefs.current[section.title] = el)} id={section.title}>
              <h2 className="lv-section-title">{section.title}</h2>
              {section.content.map((contentItem, contentIndex) => (
                <div key={contentIndex} className="lv-content-item">
                  {renderContent(contentItem)}
                </div>
              ))}
              {section.extraInfo && (
                <div className="lv-extra-info">
                  <button onClick={() => toggleSection(section.title)} className="lv-toggle-btn">
                    {expandedSections[section.title] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    {expandedSections[section.title] ? "Hide" : "Show"} Additional Information
                  </button>
                  {expandedSections[section.title] && (
                    <div className="lv-extra-content">
                      <ReactMarkdown>{section.extraInfo}</ReactMarkdown>
                    </div>
                  )}
                </div>
              )}
            </section>
          ))}

          {lesson.summary && (
            <div className="lv-summary lv-content-item" ref={(el) => (sectionRefs.current["Summary"] = el)} id="Summary">
              <h2 className="lv-section-title">Summary</h2>
              <SummaryMarkdownRenderer content={lesson.summary} />
            </div>
          )}

          <button
            className={`lv-finish-button ${dayData.find((item) => item._id === lessonId)?.status === "completed" ? "completed" : ""} ${
              isUpdating ? "updating" : ""
            }`}
            onClick={toggleLessonCompletion}
            disabled={isUpdating}
          >
            {isUpdating ? <span className="loading-spinner"></span> : getButtonText()}
          </button>

          <div className="lv-navigation">
            <button className="lv-nav-button prev" onClick={() => navigateToLesson(prevLesson)} disabled={!prevLesson}>
              <ChevronLeft size={20} />
              <span>Previous Lesson</span>
            </button>
            <button className="lv-nav-button next" onClick={() => navigateToLesson(nextLesson)} disabled={!nextLesson}>
              <span>Next Lesson</span>
              <ChevronRight size={20} />
            </button>
          </div>
        </div>

        <div className="lv-quick-nav">
          <h3>Quick Navigation</h3>
          <ul>
            <li className={activeSection === "Learning goals" ? "active" : ""} onClick={() => scrollToSection("Learning goals")}>
              Learning goals
            </li>
            {lesson.sections.map((section, index) => (
              <li key={index} className={activeSection === section.title ? "active" : ""} onClick={() => scrollToSection(section.title)}>
                {section.title}
              </li>
            ))}
            {lesson.summary && (
              <li className={activeSection === "Summary" ? "active" : ""} onClick={() => scrollToSection("Summary")}>
                Summary
              </li>
            )}
          </ul>
          <div className="lv-nav-actions">
            <button onClick={() => setExpandedSections((prev) => Object.fromEntries(Object.keys(prev).map((key) => [key, true])))}>Expand all</button>
            <button onClick={() => contentRef.current?.scrollTo({ top: 0, behavior: "smooth" })}>Back to top</button>
            <button onClick={() => contentRef.current?.scrollTo({ top: contentRef.current.scrollHeight, behavior: "smooth" })}>Go to bottom</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonView;
