/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Ensures content starts from the top */
  min-height: 100vh; /* Adjusted to min-height to ensure content fits */
  width: 100vw;
  background-color: #102a43;
  color: #fff;
  padding: 20px; /* Uniform padding for larger screens */

  @media (max-width: 768px) {
    padding: 10px; /* Adjusted padding for smaller screens */
  }
`;

const headingStyle = css`
  background: linear-gradient(to right, #1a8cd8, #4dabf7);
  -webkit-background-clip: text;
  font-size: 2.5rem;
  color: transparent;
  text-align: center;
  margin-top: 30px; /* Optimized margin-top for a better look on all screens */
`;

const highlightStyle = css`
  background: linear-gradient(to right, #1a8cd8, #4dabf7);
  -webkit-background-clip: text;
`;

const getIntoContactStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-align: center;
`;

// Assume buttonStyle and buttonFocusStyle are defined elsewhere if they are needed

function TakeAction() {
  return (
    <div css={containerStyle}>
      <h2 css={headingStyle}>Photograph Your Handwritten Notes, Receive Instant Feedback!</h2>

      <div css={getIntoContactStyle}>{/* Insert 'get into contact' content here */}</div>
      <div style={{ position: "relative", width: "100%", paddingTop: "56.25%" /* 16:9 Aspect Ratio */ }}>
        <iframe
          src="https://res.cloudinary.com/djzert4i9/video/upload/v1712504318/Video_Landing_Page_fd1srp.mp4"
          title="Simone Skills - AI-Driven Strategy Coaching | Case Study Generator"
          frameBorder="0"
          loading="lazy"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: "10%", // Adjust this value to move the iframe horizontally
            width: "80%", // Adjust this value to change the width of the iframe
            height: "70%",
            margin: "auto",
            border: "0", // Remove any default border
          }}
          allow="clipboard-write"
        ></iframe>
      </div>
    </div>
  );
}

export default TakeAction;
