import { useRef, useState } from "react";
import CustomTooltip from "../../Tooltip/CustomTooltip";
import NewMeetingModal from "./NewMeetingModal";
import CalenderSvg from "../../svgIcons/CalenderSvg";
import PurposeMeeting from "../../svgIcons/PurposeMeeting";
import Acceptance from "../../svgIcons/Acceptance";
import ConductMeeting from "../../svgIcons/ConductMeeting";
import RightIcon from "../../svgIcons/RightIcon";


export default function NoMeetings({text, fetchData}) {
    const [languageTooltip, setLanguageTooltip] = useState(false);
    const [proposeTooltip, setProposeTooltip] = useState(false);
    const [acceptenceTooltip, setAcceptenceTooltip] = useState(false);
    const [meetingTooltip, setMeetingTooltip] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const languageRef = useRef(null);
    const proposeRef = useRef(null);
    const acceptenceRef = useRef(null)
    const meetingRef = useRef(null)

    const currentDate = new Date().toISOString().slice(0, 10);
    const initialTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const [selectedTime, setSelectedTime] = useState(initialTime);

    return (
      <div>
        <div className="pending_meeting_details">
          <h4>No {text} meetings found</h4>
          <p>
            Have a look at the <a href="#">Meeting Board </a> or simply add your
            own meeting proposal - according to your preferences!
          </p>
          <div className="interviewBasic_Steps">
            <div
              ref={languageRef}
              onClick={() => setLanguageTooltip((prev) => !prev)}
              className="process-item"
            >
              <div className="process-item-circle">
                <span>
                  {" "}
                  <CalenderSvg />
                </span>
              </div>
              <div className="process-item-text">Pick a Date and Language</div>
            </div>
            <CustomTooltip target={languageRef.current} show={languageTooltip}>
              Select a suitable date and your preferred language for an
              effective exchange.
            </CustomTooltip>
            <div className="process-arrow">
              <span>
                <RightIcon />
              </span>
            </div>
            <div
              ref={proposeRef}
              onClick={() => setProposeTooltip((prev) => !prev)}
              className="process-item"
            >
              <div className="process-item-circle">
                <span>
                  <PurposeMeeting />
                </span>
              </div>
              <div className="process-item-text">Propose meeting</div>
            </div>
            <CustomTooltip target={proposeRef.current} show={proposeTooltip}>
              Send out your proposed meeting details for consideration.
            </CustomTooltip>
            <div className="process-arrow">
              <span>
                {" "}
                <RightIcon />
              </span>
            </div>
            <div
              ref={acceptenceRef}
              onClick={() => setAcceptenceTooltip((prev) => !prev)}
              className="process-item"
            >
              <div className="process-item-circle">
                <span>
                  <Acceptance />{" "}
                </span>
              </div>
              <div className="process-item-text">Wait for acceptance</div>
            </div>
            <CustomTooltip
              target={acceptenceRef.current}
              show={acceptenceTooltip}
            >
              Await the approval from your peers to confirm the session's
              schedule.
            </CustomTooltip>
            <div className="process-arrow">
              <span>
                {" "}
                <RightIcon />
              </span>
            </div>
            <div
              ref={meetingRef}
              onClick={() => setMeetingTooltip((prev) => !prev)}
              className="process-item"
            >
              <div className="process-item-circle">
                <span>
                  <ConductMeeting />{" "}
                </span>
              </div>
              <div className="process-item-text">Conduct meeting</div>
            </div>
            <CustomTooltip target={meetingRef.current} show={meetingTooltip}>
              Lead the session with confidence once confirmation is received.
            </CustomTooltip>
          </div>

          <div className="invitation_meeting">
            <button
              type="submit"
              onClick={handleShow}
              className="add_meeting_invitation"
            >
              Add your own meeting invitation
            </button>
          </div>
          <NewMeetingModal 
            show={show} 
            handleClose={handleClose} 
            setSelectedDate={setSelectedDate} 
            selectedDate={selectedDate}
            currentDate={currentDate}
            setSelectedTime={setSelectedTime}
            selectedTime={selectedTime}
            initialTime={initialTime}
            fetchData={fetchData}
          />
        </div>
      </div>
    );
}
