import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/auth.context";
import "../../css/CombinedSectionMobile.css";
import { API_URL, API_URL_NEW_BACKEND } from "../../config/config.index";
import { marked } from "marked";
import useSubscriptionCheck from "../useSubscriptionCheck";
import Webcam from "react-webcam";
import cameraIcon from "../../assets/camera-icon.png";
import CameraCapture from "../CameraCapture";
import CaseCreationForm from "./CaseCreationForm";
import { getUserSubscription, updateSubscriptionFeatures } from "../../services/SubscriptionServices";
import "../../css/CarlCaseMobile.css";
import carlImage from "../../assets/Carl_Case.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faStop, faCamera, faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomCaseCreatorMobile from "./CaseCreationMobile";
import CaseOptionsModal from "./CaseOptionsModal";

const CarlCaseMobile = () => {
  const [inputValue, setInputValue] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDefaultText, setShowDefaultText] = useState(true);
  const [userHasValidPlan, setUserHasValidPlan] = useState(false);
  const [showWebcam, setShowWebcam] = useState(false);
  const [displayContent, setDisplayContent] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { userPlan } = useSubscriptionCheck();
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [isImageAllowed, setImageAllowed] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [showCustomCaseCreator, setShowCustomCaseCreator] = useState(false);
  const [showCaseOptions, setShowCaseOptions] = useState(false);
  const [showChatInterface, setShowChatInterface] = useState(false);

  const { user, isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const isAllowed = useSubscriptionCheck();
  const [warningText, setWarningText] = useState("User Not Subscribed");
  const [isFeatureAllowed, setIsFeatureAllowed] = useState(false);
  const [showForm, setShowForm] = useState(!isFeatureAllowed);
  const [isImageUploadAllowed, setIsImageUploadAllowed] = useState(false);
  const eventSourceRef = useRef(null);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const inputRef = useRef(null);

  const fetchUserSubscription = async () => {
    try {
      const response = await getUserSubscription();
      if (response.status === 200) {
        const subscription = response.data.subscription;
        const subscriptionFeatures = subscription.subscriptionFeatures;

        const featureExists = subscriptionFeatures.find((feature) => feature.name === "AI - Case Practice");

        if (featureExists) {
          if (featureExists.usedQuantity < featureExists.quantity) {
            setIsFeatureAllowed(true);
            setWarningText(null);
          } else {
            setWarningText("Limit is Exhausted");
          }
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      setWarningText(error.response.data.error);
    }
  };

  useEffect(() => {
    fetchUserSubscription();
    console.log("Is this working");
    console.log("User's plan:", userPlan);
    if (userPlan === "Premium Plan") {
      setIsPremiumUser(true);
    } else {
      setIsPremiumUser(false);
    }
  }, [userPlan]);

  useEffect(() => {
    scrollToBottom();
  }, [conversationHistory]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const handleImageCapture = (imageSrc) => {
    setImagePreview(imageSrc);
    console.log("Captured image source:", imageSrc);
    setIsCameraOpen(false);
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const handleFormSubmit = async (formData) => {
    console.log(formData);
    setShowForm(false);
    const promptText = `Create a case with the following details: Case Type - ${formData.caseType}, Industry - ${
      formData.industry
    }, Skills - ${formData.skills.join(", ")}.`;
    await fetchOpenAIResponse(promptText);
  };

  const addToConversation = useCallback((type, text) => {
    setConversationHistory((prev) => {
      if (type === "ai" && prev.length > 0 && prev[prev.length - 1].type === "ai") {
        const updatedHistory = [...prev];
        updatedHistory[prev.length - 1] = { ...updatedHistory[prev.length - 1], text };
        return updatedHistory;
      } else {
        return [...prev, { type, text: text || "" }];
      }
    });

    setTimeout(scrollToBottom, 0);
  }, []);

  const contextText = conversationHistory
    .slice(-3)
    .map((message) => message.text)
    .join(" ");

  const fetchOpenAIResponse = async (promptText, imagePreview, contextText) => {
    console.log("current thread api first fetch", threadId);
    let loaderTimeout;
    try {
      loaderTimeout = setTimeout(() => {
        setLoading(true);
      }, 3000);

      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      const response = await fetch(`${API_URL_NEW_BACKEND}/api/assistant-chat-mobile`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userMessage: promptText, threadId: threadId, imageUrl: imagePreview, contextText }),
      });

      clearTimeout(loaderTimeout);

      const data = await response.json();

      if (!threadId) {
        setThreadId(data.threadId);
      }

      const newEventSource = new EventSource(`${API_URL_NEW_BACKEND}/api/stream-responses?threadId=${data.threadId}`);

      let rawContent = "";

      newEventSource.addEventListener("messageStart", () => {
        rawContent = "";
        addToConversation("ai", "");
      });

      newEventSource.addEventListener("textDelta", (event) => {
        const eventData = JSON.parse(event.data);
        rawContent += eventData.value;

        const html = DOMPurify.sanitize(marked(rawContent));
        addToConversation("ai", html);
      });

      newEventSource.onopen = () => console.log("SSE connection opened successfully.");
      newEventSource.onerror = (event) => {
        console.error("SSE connection encountered an error:", event);
        setLoading(false);
        setImagePreview(null);
        newEventSource.close();
      };

      eventSourceRef.current = newEventSource;
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      setLoadingMessage("");
      clearTimeout(loaderTimeout);
    }
  };

  const handleAPISubmit = async (event) => {
    event.preventDefault();
    console.log("Raw Submission:", inputValue);

    const apiInput = inputValue.trim();
    if (apiInput || imagePreview) {
      setImagePreview(null);
      setLoadingMessage(imagePreview ? "Analyzing image..." : "Processing your request...");
      addToConversation("user", apiInput || "Image sent");
      await fetchOpenAIResponse(inputValue.trim(), threadId, imagePreview, contextText);
      setInputValue("");
      setImagePreview(null);
    } else {
      console.log("Input was empty or whitespace only.");
    }
  };

  const handleImageUploadCheck = async (features) => {
    const imageUploadFeature = features.find((feature) => feature.name === "Blend AI with classic pen-and-paper");

    if (imageUploadFeature) {
      setIsImageUploadAllowed(true);
    } else {
      setIsImageUploadAllowed(false);
    }
  };

  useEffect(() => {
    const updateFeature = async () => {
      if (threadId !== null) {
        await updateSubscriptionFeatures({ featureName: "AI - Case Practice" });
      }
    };

    updateFeature();
  }, [threadId]);

  const startRecording = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.warn("getUserMedia not supported on your browser!");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        recorder.start();
        setIsRecording(true);

        recorder.ondataavailable = async (e) => {
          const audioData = new Blob([e.data], { type: "audio/wav" });
          const formData = new FormData();
          formData.append("file", audioData, "audio.wav");

          try {
            const response = await fetch(`${API_URL_NEW_BACKEND}/api/transcribe`, {
              method: "POST",
              body: formData,
            });
            const data = await response.json();
            setInputValue(data.transcription.text);
          } catch (error) {
            console.error("Error while sending audio data:", error);
          }
        };

        recorder.onerror = (err) => {
          console.error("Recorder error:", err);
          setIsRecording(false);
        };

        recorder.onstop = () => {
          stream.getTracks().forEach((track) => track.stop());
          setIsRecording(false);
        };
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };

  const handleCameraClick = () => {
    console.log("Camera button clicked");
  };

  const handleCreateCaseClick = () => {
    setShowCaseOptions(true);
  };

  const handleHelpMeCreateCase = () => {
    setShowChatInterface(true);
    fetchOpenAIResponse("Help me create a case. Please guide me through the process step by step.");
  };

  const handleCreateOwnCase = () => {
    setShowCustomCaseCreator(true);
  };

  const handleCustomCaseSubmit = async (formData) => {
    setShowCustomCaseCreator(false);
    setShowChatInterface(true);
    const promptText = `Create a case with the following details: Case Type - ${formData.caseType}, Industry - ${formData.industry}${
      formData.skill ? `, Primary Skill - ${formData.skill}` : ""
    }.`;
    await fetchOpenAIResponse(promptText);
  };

  if (!showChatInterface && !showCustomCaseCreator) {
    return (
      <div className="carl-case-mobile initial-options">
        <h1>Create a Case</h1>
        <p>Choose how you'd like to start:</p>
        <button className="option-button" onClick={handleHelpMeCreateCase}>
          Help me create a case
        </button>
        <button className="option-button" onClick={handleCreateOwnCase}>
          Create my own case
        </button>
      </div>
    );
  }

  return (
    <div className="carl-case-mobile">
      {!showChatInterface && !showCustomCaseCreator ? (
        <div className="carl-case-mobile initial-options">
          <h1>Create a Case</h1>
          <p>Choose how you'd like to start:</p>
          <button className="option-button" onClick={handleHelpMeCreateCase}>
            Help me create a case
          </button>
          <button className="option-button" onClick={handleCreateOwnCase}>
            Create my own case
          </button>
        </div>
      ) : showCustomCaseCreator ? (
        <CustomCaseCreatorMobile
          onSubmit={handleCustomCaseSubmit}
          onClose={() => {
            setShowCustomCaseCreator(false);
            setShowChatInterface(true);
          }}
        />
      ) : (
        <>
          <div className="chat-container" ref={chatContainerRef}>
            {conversationHistory.map((message, index) => (
              <div key={index} className={`message ${message.type}`}>
                {message.type === "ai" && <img src={carlImage} alt="AI" className="ai-avatar" />}
                <div className="message-content" dangerouslySetInnerHTML={{ __html: message.text }} />
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <footer className="chat-footer-mobile">
            <form onSubmit={handleAPISubmit} className="input-form">
              <div className="input-wrapper">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Type your message..."
                  disabled={loading}
                  ref={inputRef}
                  className="message-input"
                />
                <button
                  type="button"
                  className={`mic-button ${isRecording ? "recording" : ""}`}
                  aria-label="Voice input"
                  onClick={isRecording ? stopRecording : startRecording}
                >
                  {isRecording ? <FontAwesomeIcon icon={faStop} size="lg" /> : <FontAwesomeIcon icon={faMicrophone} size="lg" />}
                </button>
              </div>
              <button type="submit" disabled={loading || !inputValue.trim()} className="send-button" aria-label="Send message">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22 2L11 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </form>
            {loading && <div className="loading-indicator">AI is thinking...</div>}
          </footer>
        </>
      )}
      {showCaseOptions && (
        <CaseOptionsModal
          onClose={() => setShowCaseOptions(false)}
          onHelpMeCreate={handleHelpMeCreateCase}
          onCreateOwn={() => {
            setShowCaseOptions(false);
            setShowCustomCaseCreator(true);
            setShowChatInterface(false);
          }}
        />
      )}
    </div>
  );
};

export default CarlCaseMobile;
