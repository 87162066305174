import React from 'react'


const RightIcon = () => {
    return (
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0 0.792399L0.792468 0L6.79246 6L0.792468 12L0 11.2075L5.20753 6L0 0.792399Z" fill="#A1A1A1"/>
</svg>

    );
}


export default RightIcon;