import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import globe from "../../assets/globe.png";

const SalesSection = () => {
  return (
    <section className="salesSection">
      <Container className="momentum-card">
        <Row className="align-items-center">
          <Col md={6}>
            <h1>
              Start Practicing with<br></br> CaseStudyGenerator
            </h1>
            <p>
              We've partnered with leading student consultancies to make excellence accessible. These collaborations offer exclusive discount codes to
              their members, providing premium access to the world's best case prep platform at no extra cost to them. Elevate your consulting journey
              with top-tier resources, tailored demos, and the opportunity to shape our platform through your feature requests. Join a community
              that's redefining case interview preparation.
            </p>
            <a href="mailto:question@casestudygenerator.com" className="contact-sale-link">
              <Button className="contact-sale">Request Discount Coupons</Button>
            </a>
          </Col>
          <Col md={6} className="text-center">
            <img src={globe} alt="Dotted Globe" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SalesSection;
