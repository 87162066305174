import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRobot, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import "../../css/CaseOptionsModal.css";

const CaseOptionsModal = ({ onClose, onHelpMeCreate, onCreateOwn }) => {
  return (
    <div className="com-case-options-modal">
      <div className="com-modal-content">
        <button className="com-close-button" onClick={onClose} aria-label="Close">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="com-modal-title">Create a Case</h2>
        <p className="com-modal-description">Choose how you'd like to create your case:</p>
        <div className="com-options-container">
          <button className="com-option-button" onClick={onHelpMeCreate}>
            <FontAwesomeIcon icon={faRobot} className="com-option-icon" />
            <span>Help me create a case</span>
          </button>
          <button className="com-option-button" onClick={onCreateOwn}>
            <FontAwesomeIcon icon={faPencilAlt} className="com-option-icon" />
            <span>Create my own case</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CaseOptionsModal;
