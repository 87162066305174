import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";
import randomFacts from "../components/pages/data/facts.json";
import Loader, { ProgressBar, ThreeDots } from "react-loader-spinner";
import { AuthContext } from "../context/auth.context";
import { API_URL } from "../config/config.index.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for toast styles
import { Helmet } from "react-helmet";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  backgroundColor: "#f4f4f4",
  padding: "30px",
};

const formStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  width: "100%",
  padding: "20px",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

const inputStyle = {
  padding: "10px",
  fontSize: "16px",
  border: "1px solid #ced4da",
  borderRadius: "4px",
  backgroundColor: "#f8f9fa",
  width: "30%",
};

const buttonStyle = {
  padding: "10px 20px",
  fontSize: "16px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "#0070c0",
  color: "#ffffff",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
};

const majesticButtonStyle = {
  padding: "10px 20px",
  fontSize: "16px",
  border: "none",
  borderRadius: "4px",
  background: "linear-gradient(45deg, #0070c0, #003e6b)",
  color: "#ffffff",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
  fontFamily: "Arial, sans-serif",
  marginTop: "10px",
};

const backButtonStyle = {
  padding: "10px 20px",
  fontSize: "16px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "#ffffff",
  color: "#0070c0",
  cursor: "pointer",
  marginTop: "20px",
  transition: "background-color 0.3s ease",
};

const outputStyle = {
  marginTop: "20px",
  width: "100%",
  padding: "10px",
  border: "1px solid #ced4da",
  borderRadius: "4px",
  backgroundColor: "#f8f9fa",
  resize: "vertical",
};

const factBoxStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
  backgroundColor: "#f8f8f8",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  width: "80%",
  maxWidth: "500px",
};

const factTextStyle = {
  marginTop: "20px",
  fontSize: "18px",
  color: "#333333",
  fontWeight: "500",
  textAlign: "center",
  fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
  letterSpacing: "0.5px",
};

function PracticeSkills() {
  const navigate = useNavigate();
  const [generatedData, setGeneratedData] = useState("");

  const [arithmeticTopic, setArithmeticTopic] = useState(""); // New state for arithmetic topic
  const [marketTopic, setMarketTopic] = useState(""); // New state for market topic

  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const [userPlan, setUserPlan] = useState(""); // Changed to a single string

  const [generatedAnswer, setGeneratedAnswer] = useState("");
  const [answerRevealed, setAnswerRevealed] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [feedbackFormVisible, setFeedbackFormVisible] = useState(false);

  const { isLoggedIn, user, checkPremiumMembership } = useContext(AuthContext);

  // useEffect(() => {
  //   async function fetchData() {
  //     if (!isLoggedIn || !user || !user.email) {
  //       toast.error("You must be logged in to access this page");
  //       navigate("/login"); // Assuming you have a login page at /login
  //       return;
  //     }

  //     try {
  //       const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
  //       const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

  //       // Check if stripeCustomerId exists before making the next API call
  //       // Commented out as we're not using the premium check for now
  //       /*
  //       if (!stripeCustomerId) {
  //         console.warn("No Stripe customer ID found for user:", user.email);
  //         toast.error("You need a Premium Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }
  //       */

  //       const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
  //       console.log("Received product response:", productResponse.data);

  //       const planName = productResponse.data.productName;
  //       setUserPlan(planName);
  //       console.log("User's subscription plan:", planName);

  //       // Check if the planName matches "Premium Plan"
  //       // Commented out as we're not using the premium check for now
  //       /*
  //       if (planName !== "Premium Plan") {
  //         toast.error("You need a Premium Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }
  //       */
  //     } catch (error) {
  //       console.error("Error fetching user plan:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchData();
  // }, [isLoggedIn, user, navigate]);

  useEffect(() => {
    if (!isLoggedIn) {
      toast.error("You need to be logged in to access this page");
      navigate("/login"); // or wherever your login page is
    }
  }, [isLoggedIn, navigate]);

  const getRandomFact = () => {
    return randomFacts[Math.floor(Math.random() * randomFacts.length)];
  };

  const backToMainMenu = () => {
    navigate("/");
  };

  const generateQuestion = async () => {
    if ((!arithmeticTopic && !marketTopic) || !difficultyLevel) {
      toast.error("Please select a value for each option");
      return;
    }

    try {
      setLoading(true);

      const response = await axios.get(`${API_URL}/api/generate-skills`, {
        params: {
          arithmeticTopic,
          marketTopic,
          difficultyLevel,
        },
      });

      const { generatedData } = response.data;
      console.log("Response from server:", response.data);
      setGeneratedData(generatedData);
      setGeneratedAnswer("");
      setAnswerRevealed(false);
    } catch (error) {
      console.error("Error while fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const revealAnswer = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`${API_URL}/api/generate-answer-skills`, {
        params: {
          arithmeticTopic,
          marketTopic,
          difficultyLevel,
          generatedQuestion: generatedData,
        },
      });

      const { generatedAnswer } = response.data;
      console.log("Response from server:", response.data);
      setGeneratedAnswer(generatedAnswer);
      setAnswerRevealed(true);
    } catch (error) {
      console.log("Error while fetching data:", error);
    } finally {
      setLoading(false);
      setSelectedDropdown(""); // Reset the dropdown state here
    }
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <title>Math and Arithmetics in Case Interviews - Prepare for Consulting Firms</title>
        <meta
          name="description"
          content="Master the math and arithmetic skills required for case interviews at top consulting firms like McKinsey, Deloitte, BCG, and more. Get ready to excel in quantitative problem-solving."
        />
      </Helmet>

      <div style={formStyle}>
        {/* Dropdown 1: Arithmetic Options */}
        <select
          style={inputStyle}
          id="arithmeticType"
          value={arithmeticTopic}
          onChange={(e) => {
            setArithmeticTopic(e.target.value);
            if (e.target.value) {
              setSelectedDropdown("arithmetic");
            } else {
              setSelectedDropdown("");
            }
          }}
          disabled={selectedDropdown === "market"}
        >
          <option value="">--Select an Arithmetic Option--</option>
          <option value="Addition">Addition</option>
          <option value="Subtraction">Subtraction</option>
          <option value="Multiplication">Multiplication</option>
          <option value="Division">Division</option>
          <option value="Percentages">Percentages</option>
          <option value="Fractions">Fractions</option>
          <option value="Decimals">Decimals</option>
          <option value="Ratios">Ratios</option>
        </select>

        {/* Dropdown 2: Market Sizing & Revenue Synergies */}
        {/* <select
          style={inputStyle}
          id="marketType"
          value={marketTopic}
          onChange={(e) => {
            setMarketTopic(e.target.value);
            if (e.target.value) {
              setSelectedDropdown("market");
            } else {
              setSelectedDropdown("");
            }
          }}
          disabled={selectedDropdown === "arithmetic"}
        >
          <option value="">--Select a Market Option--</option>
          <option value="Market Sizing">Market Sizing</option>
          <option value="Revenue Synergies">Revenue Synergies</option>
        </select> */}

        {/* Difficulty Level Dropdown */}
        <select style={inputStyle} id="difficultyLevel" value={difficultyLevel} onChange={(e) => setDifficultyLevel(e.target.value)}>
          <option value="">--Please choose a difficulty--</option>
          <option value="Easy">Easy</option>
          <option value="Medium">Medium</option>
          <option value="Hard">Hard</option>
        </select>

        <button
          style={buttonStyle}
          onClick={generateQuestion}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#00549d";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "#0070c0";
          }}
        >
          Generate Question
        </button>
      </div>

      {generatedData && !answerRevealed && (
        <button
          style={majesticButtonStyle}
          onClick={revealAnswer}
          onMouseEnter={(e) => {
            e.target.style.background = "linear-gradient(45deg, #005496, #003258)";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "linear-gradient(45deg, #0070c0, #003e6b)";
          }}
        >
          Reveal Answer
        </button>
      )}

      {loading ? (
        <div style={factBoxStyle}>
          <ThreeDots color="#0070c0" height={50} width={50} />
          <p style={factTextStyle}>Did you know? {getRandomFact().fact}</p>
        </div>
      ) : (
        <>
          <TextareaAutosize style={outputStyle} minRows={3} placeholder="Generated data will be displayed here" readOnly value={generatedData} />
          {answerRevealed && (
            <TextareaAutosize
              style={outputStyle}
              minRows={5}
              placeholder="Generated answer will be displayed here"
              readOnly
              value={generatedAnswer}
            />
          )}
        </>
      )}

      <button
        style={backButtonStyle}
        onClick={backToMainMenu}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0070c0";
          e.target.style.color = "#ffffff";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "#ffffff";
          e.target.style.color = "#0070c0";
        }}
      >
        Go Back to Main Menu
      </button>
    </div>
  );
}

export default PracticeSkills;
