import React from "react";
import ReactMarkdown from "react-markdown";

const SummaryMarkdownRenderer = ({ content }) => {
  // Function to process the content before rendering
  const processContent = (rawContent) => {
    // Replace escaped newlines with actual newlines
    let processed = rawContent.replace(/\\n/g, "\n");
    // Ensure there are blank lines before and after list items for proper rendering
    processed = processed.replace(/^-/gm, "\n-");
    processed = processed.replace(/(\n-.*$)/gm, "$1\n");
    return processed;
  };

  return (
    <div className="summary-markdown">
      <ReactMarkdown
        components={{
          p: ({ node, ...props }) => <p className="summary-paragraph" {...props} />,
          h1: ({ node, ...props }) => <h1 className="summary-heading" {...props} />,
          h2: ({ node, ...props }) => <h2 className="summary-heading" {...props} />,
          h3: ({ node, ...props }) => <h3 className="summary-heading" {...props} />,
          ul: ({ node, ...props }) => <ul className="summary-list" {...props} />,
          ol: ({ node, ...props }) => <ol className="summary-list" {...props} />,
          li: ({ node, ...props }) => <li className="summary-list-item" {...props} />,
        }}
      >
        {processContent(content)}
      </ReactMarkdown>
    </div>
  );
};

export default SummaryMarkdownRenderer;
