import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/auth.context";
import { API_URL } from "../config/config.index";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const { setToken, authenticateUser } = useContext(AuthContext);

  const { state } = location;
  const from = state?.from || "/"; // fallback to "/" if "from" is not available

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = formData;

    const payload = { email, password };

    try {
      const response = await axios.post(`${API_URL}/auth/login`, payload);
      setToken(response.data.authToken);
      await authenticateUser();
      navigate(from); // navigate to the intended destination or "/"
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else if (error.request) {
        setErrorMessage("The server did not respond. Please try again.");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Login</h3>
          <p style={{ color: "grey" }}> Welcome again! Enter the details below to sign in.</p>
          {errorMessage && <p className="error">{errorMessage}</p>}
          <div className="form-group">
            <label>Email:</label>
            <input className="form-control" type="email" name="email" value={formData.email} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input className="form-control" type="password" name="password" value={formData.password} onChange={handleInputChange} />
          </div>
          <button className="btn-primary" type="submit">
            Submit
          </button>
          <p className="signup-link">
            Don't have an account? <Link to="/auth/signup">Sign Up</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
