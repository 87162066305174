import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth.context";
import { API_URL } from "../config/config.index";
import "../css/ChoosePersonalCompany.css";
import { useRef } from "react";

// Import company logos
import Mck_logo from "../assets/Mck_logo.png";
import Bcg_logo from "../assets/boston-consulting-group-bcg-vector-logo.png";
import Bain_logo from "../assets/Bain_and_Company_Logo.jpg";
import RolandBerger_logo from "../assets/Roland_Berger_Logo_2015.png";
import Deloitte_logo from "../assets/Deloitte_Logo.png";
import OliverWyman_logo from "../assets/OW-_logo.jpg";
import Kearney_logo from "../assets/Kearney-Logo-2000.jpg";
import Accenture_logo from "../assets/Accenture_logo.png";
import Strategy_logo from "../assets/Strategy&_logo1.png";

const companyData = [
  {
    name: "McKinsey & Company",
    logo: Mck_logo,
    description:
      "Craft compelling narratives for McKinsey's Personal Experience Interview. Showcase leadership moments, problem-solving ingenuity, and measurable impact in your professional journey. Articulate experiences that demonstrate your ability to drive change and deliver exceptional results in high-pressure environments.",
    link: "/practice-personal-experience-mckinsey",
  },
  {
    name: "Boston Consulting Group",
    logo: Bcg_logo,
    description:
      "Prepare for BCG's immersive personal experience discussions. Share stories that highlight your analytical prowess, creative problem-solving, and adaptability in diverse situations. Emphasize instances where you've navigated complex challenges, leveraged data-driven insights, and collaborated effectively to achieve remarkable outcomes.",
    link: "/practice-personal-experience-bcg",
  },
  {
    name: "Bain & Company",
    logo: Bain_logo,
    description:
      "Excel in Bain's collaborative interview format by conveying impactful personal anecdotes. Highlight experiences that showcase your leadership potential, team synergy, and ability to thrive in dynamic environments. Demonstrate how you've fostered innovation, overcome obstacles, and consistently delivered results aligned with organizational goals.",
    link: "/practice-personal-experience-bain",
  },
  {
    name: "Roland Berger",
    logo: RolandBerger_logo,
    description:
      "Navigate Roland Berger's distinctive interview process with confidence. Present experiences that exemplify your strategic acumen, international perspective, and capacity to drive sustainable change. Illustrate your ability to balance analytical rigor with creative thinking, and showcase your adaptability in diverse cultural contexts.",
    link: "/practice-personal-experience-roland-berger",
  },
  {
    name: "Deloitte",
    logo: Deloitte_logo,
    description:
      "Prepare for Deloitte's comprehensive personal experience interview. Share stories that demonstrate your expertise in digital innovation, risk management, and industry-specific challenges. Highlight instances where you've leveraged emerging technologies, fostered inclusive teamwork, and delivered transformative solutions that created lasting value for clients.",
    link: "/practice-personal-experience-deloitte",
  },
  {
    name: "Oliver Wyman",
    logo: OliverWyman_logo,
    description:
      "Master Oliver Wyman's rigorous interview approach by recounting impactful personal experiences. Emphasize moments where you've applied analytical excellence, entrepreneurial spirit, and industry insights to solve complex problems. Showcase your ability to navigate uncertainty, drive strategic initiatives, and create innovative solutions in fast-paced environments.",
    link: "/practice-personal-experience-oliver-wyman",
  },
  {
    name: "Kearney",
    logo: Kearney_logo,
    description:
      "Embrace Kearney's unique interview process with compelling personal narratives. Highlight experiences that demonstrate your expertise in operational excellence, supply chain optimization, and strategic transformation. Share instances where you've driven tangible results, fostered cross-functional collaboration, and implemented innovative solutions to address critical business challenges.",
    link: "/practice-personal-experience-kearney",
  },
  {
    name: "Accenture",
    logo: Accenture_logo,
    description:
      "Shine in Accenture's forward-thinking interview by sharing diverse personal experiences. Showcase your proficiency in digital transformation, cloud migration, and emerging technologies. Emphasize moments where you've led change initiatives, fostered innovation ecosystems, and delivered client-centric solutions that drove significant business value across various industries.",
    link: "/practice-personal-experience-accenture",
  },
  {
    name: "Strategy&",
    logo: Strategy_logo,
    description:
      "Excel in Strategy&'s sophisticated interview process with impactful personal anecdotes. Highlight experiences that demonstrate your expertise in corporate strategy, mergers and acquisitions, and organizational transformation. Share instances where you've navigated complex global markets, developed innovative business models, and implemented strategies that drove sustainable growth.",
    link: "/practice-personal-experience-strategy&",
  },
];

const CompanyCard = ({ name, logo, description, link }) => (
  <div className="company-item">
    <div className="company-logo-container">
      <img src={logo} alt={`${name} logo`} className="company-logo" />
    </div>
    <div className="company-content">
      <h2 className="company-name">{name}</h2>
      <p className="company-description">{description}</p>
      <Link to={link} className="start-practice-btn">
        <span className="btn-text">Start Practice</span>
        <span className="btn-icon">
          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      </Link>
    </div>
  </div>
);

function ChoosePersonalCompany() {
  const { isLoggedIn, user, checkPremiumMembership } = useContext(AuthContext);
  const [userPlan, setUserPlan] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState(companyData);

  const companyListRef = useRef(null);

  // useEffect(() => {
  //   checkPremiumMembership();

  //   async function fetchData() {
  //     if (!isLoggedIn || !user || !user.email) {
  //       setLoading(false);
  //       return;
  //     }

  //     try {
  //       const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
  //       const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

  //       if (!stripeCustomerId) {
  //         console.warn("No Stripe customer ID found for user:", user.email);
  //         setLoading(false);
  //         return;
  //       }

  //       const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
  //       const planName = productResponse.data.productName;
  //       setUserPlan(planName);
  //     } catch (error) {
  //       console.error("Error fetching user plan:", error);
  //       setError("Unable to retrieve your plan details. Please try again or contact support.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchData();
  // }, [isLoggedIn, checkPremiumMembership, user]);

  useEffect(() => {
    const filtered = companyData.filter((company) => company.name.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredCompanies(filtered);
  }, [searchTerm]);

  // const userHasPlan = (planName) => {
  //   const planNamesMap = {
  //     Basic: "Basic Plan",
  //     Premium: "Premium Plan",
  //     Pro: "Pro Plan",
  //   };

  //   if (planName === "Basic") {
  //     return userPlan === planNamesMap["Basic"] || userPlan === planNamesMap["Premium"] || userPlan === planNamesMap["Pro"];
  //   } else if (planName === "Premium") {
  //     return userPlan === planNamesMap["Premium"] || userPlan === planNamesMap["Pro"];
  //   } else if (planName === "Pro") {
  //     return userPlan === planNamesMap["Pro"];
  //   } else {
  //     return false;
  //   }
  // };

  // const scrollToCompanies = () => {
  //   companyListRef.current.scrollIntoView({ behavior: "smooth" });
  // };

  // if (isLoading) {
  //   return <div className="loading-message">Preparing your personalized consulting journey...</div>;
  // }

  // if (error) {
  //   return <div className="error-message">{error}</div>;
  // }

  return (
    <div className="container">
      <Helmet>
        <title>Elite Consulting Career Launchpad | Personalized Interview Mastery</title>
        <meta
          name="description"
          content="Unlock your potential with tailored interview preparation for top consulting firms. Master the art of storytelling, problem-solving, and leadership with our AI-powered platform."
        />
        <link rel="canonical" href="https://www.casestudygenerator.com/practice-personal-interviews" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Elite Consulting Career Launchpad",
              "description": "Elevate your consulting career with personalized interview preparation for top firms. Master storytelling, leadership, and strategic problem-solving for McKinsey, BCG, Bain, and beyond.",
              "url": "https://www.casestudygenerator.com/practice-personal-interviews",
              "publisher": {
                "@type": "Organization",
                "name": "Case Study Generator",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://res.cloudinary.com/djzert4i9/image/upload/v1715166670/Overview_Personal_Experience_c72fal.png"
                }
              }
            }
          `}
        </script>
      </Helmet>
      <header className="header-pei">
        <h1 className="title-pei">Elevate Your Consulting Career</h1>
        <p className="subtitle-pei">
          Master the art of storytelling, problem-solving, and leadership with tailored interview preparation for top consulting firms.
        </p>
      </header>

      <div className="company-grid">
        {companyData.map((company, index) => (
          <CompanyCard key={index} {...company} />
        ))}
      </div>
    </div>
  );
}

export default ChoosePersonalCompany;
