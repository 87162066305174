import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: #007bff;
  border-color: #007bff;
`;

const BuyPlanModal = ({show, setShow}) => {
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  
  const redirectToPricing = () => {
    navigate('/plans')
    handleClose();
  };

  return (
    <div style={{zIndex:1, position:'relative'}}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Buy Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you wish to proceed with purchasing this plan?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <StyledButton onClick={redirectToPricing}>
            Go to Pricing
          </StyledButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BuyPlanModal;
