import React from "react";
import { Link } from "react-router-dom";
import "../css/AboutUs.css";
import { FaRocket, FaBullseye, FaGlobe } from "react-icons/fa"; // Use react-icons for better SEO and accessibility

const AboutUs = () => {
  return (
    <section className="about-us-section" aria-labelledby="about-title">
      <h1 id="about-title" className="about-us-title">
        About Case Study Generator
      </h1>
      <article className="about-us-content">
        <header className="about-us-header">
          <h2 className="about-us-subtitle">Your Partner in Consulting Success</h2>
        </header>
        <p className="about-us-description">
          What sets us apart is our unique approach to hands-on learning. We recognize the value of traditional problem-solving techniques — the
          clarity that comes from sketching frameworks and performing calculations on paper. Our platform invites you to bring these pen-and-paper
          strategies into the digital age. Simply upload images of your work, and let our AI provide personalized, insightful feedback. This
          integration of traditional methods with cutting-edge technology not only enhances your learning experience but also offers a comprehensive
          understanding of consulting frameworks and problem-solving tactics. Our platform is designed to prepare you for the challenges of consulting
          in a way that's both innovative and deeply practical.
        </p>
        <section className="about-us-solution">
          <h2 className="about-us-subtitle">What We Offer</h2>
          <p className="about-us-description">
            Gain an edge with our extensive resource library. Our offerings include customizable case studies that simulate real-world consulting
            scenarios, live feedback, and interactive mock interviews with feedback sessions. Prepare confidently and stand out in your interviews
            with Case Study Generator.
          </p>
        </section>
      </article>
      <section className="about-us-mission-vision">
        <div className="about-us-item">
          <FaRocket className="about-us-icon" aria-hidden="true" />
          <h3 className="about-us-subtitle">Our Solution</h3>
          <p className="about-us-description">Comprehensive resources tailored to your needs, ensuring you ace your consulting interviews.</p>
        </div>
        <div className="about-us-item">
          <FaBullseye className="about-us-icon" aria-hidden="true" />
          <h3 className="about-us-subtitle">Our Mission</h3>
          <p className="about-us-description">
            To empower aspiring consultants with a complete, accessible toolkit for career advancement in the consulting industry.
          </p>
        </div>
        <div className="about-us-item">
          <FaGlobe className="about-us-icon" aria-hidden="true" />
          <h3 className="about-us-subtitle">Our Vision</h3>
          <p className="about-us-description">
            To be the global go-to hub for case interview preparation, fostering a community of successful consultants.
          </p>
        </div>
      </section>
      <Link to="/choose" className="about-us-cta-link">
        <button className="about-us-button" aria-label="Start your journey with Case Study Generator">
          Start Your Journey
        </button>
      </Link>
    </section>
  );
};

export default AboutUs;
