import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth.context";
import { API_URL } from "../config/config.index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CaseForm from "./CaseForm";
import CaseIntroduction from "./CaseIntroduction";
import CreateAnswer from "./CreateAnswer";
import Loader from "./Loader";
import "bootstrap/dist/css/bootstrap.min.css";
import { Stepper, Step, StepLabel, Button, Typography, CircularProgress } from "@mui/material";
import { InfoOutlined, CheckCircleOutlineOutlined, ErrorOutlineOutlined } from "@mui/icons-material";
import "../css/Cases.css";

const BASE_URL = process.env.REACT_APP_API_BASE_URL || window.location.protocol + "//" + window.location.hostname + ":5005";

function CreateCaseInterview() {
  const navigate = useNavigate();
  const [generatedData, setGeneratedData] = useState("");
  const [caseType, setCaseType] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, user } = useContext(AuthContext);
  const [fetchedAnswer, setFetchedAnswer] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [userPlan, setUserPlan] = useState("");
  const [introductionData, setIntroductionData] = useState({});
  const [showGenerateLoader, setShowGenerateLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!isLoggedIn || !user || !user.email) {
        toast.error("You must be logged in to access this page");
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/api/checkUserStatus`, {
          params: {
            email: user.email,
          },
        });

        const { hasCreatedFreeCase, stripeCustomerId, productName } = response.data;

        if (hasCreatedFreeCase) {
          if (stripeCustomerId) {
            setUserPlan(productName);
          } else {
            toast.error("You need a Basic or Premium Plan to access this page");
            navigate("/plans");
            return;
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [isLoggedIn, user, navigate]);

  const backToMainMenu = () => {
    navigate("/");
  };

  const handleShowAnswerClick = async () => {
    if (user.hasCreatedFreeCase) {
      toast.info("You have already utilized your complimentary mock case. Subscribe for more!");
    } else {
      try {
        setShowGenerateLoader(true); // Show loading indicator during the API call
        await axios.post(`${API_URL}/api/user/updateFreeCaseStatus`, {
          email: user.email,
          hasCreatedFreeCase: true,
        });
        toast.success("Your complimentary mock case answer is revealed!");
        setCurrentStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error("Error updating user's free case status:", error);
        toast.error("Something went wrong!");
      } finally {
        setShowGenerateLoader(false); // Hide loading indicator
      }
    }
  };

  const handleGenerateClick = async () => {
    if (user.hasCreatedFreeCase) {
      toast.info("You have already utilized your complimentary mock case. Subscribe for more!");
    } else {
      try {
        setShowGenerateLoader(true); // Show loading indicator during the API call
        await axios.post(`${API_URL}/api/user/updateFreeCaseStatus`, {
          email: user.email,
          hasCreatedFreeCase: true,
        });
        toast.success("Your complimentary mock case has been generated!");
        generateIntroduction();
      } catch (error) {
        console.error("Error updating user's free case status:", error);
        toast.error("Something went wrong!");
      } finally {
        setShowGenerateLoader(false); // Hide loading indicator
      }
    }
  };

  const fetchAnswer = async (caseType, industryType, difficultyLevel, generatedData) => {
    try {
      const response = await axios.get(`${API_URL}/api/generate-answer`, {
        params: {
          caseType,
          industryType,
          difficultyLevel,
          generatedIntroduction: generatedData,
        },
      });

      const answer = response.data.generatedAnswer;

      setFetchedAnswer(answer);
    } catch (error) {
      console.error("Error while fetching answer:", error);
      throw error;
    }
  };

  const handleCaseGenerated = (data) => {
    setFormData({
      caseType: data.caseType,
      industryType: data.industryType,
      difficultyLevel: data.difficultyLevel,
      generatedData: data.generatedData,
    });
    setCurrentStep((prevStep) => prevStep + 1);
    fetchAnswer(data.caseType, data.industryType, data.difficultyLevel, data.generatedData);
  };

  const handleIntroductionComplete = (data) => {
    setIntroductionData(data);
    setCurrentStep(2);
  };

  const generateIntroduction = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/generate`, {
        params: {
          caseType,
          industryType,
          difficultyLevel,
        },
      });
      setGeneratedData(response.data.generatedData);
    } catch (error) {
      console.error("Failed to fetch introduction:", error);
    }
  };

  const steps = ["Choices", "Introduction", "Answer"];

  const handleNext = () => {
    if (currentStep === 0) {
      handleGenerateClick();
    } else if (currentStep === steps.length - 2) {
      handleShowAnswerClick();
    } else {
      setCurrentStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <CaseForm onCaseGenerated={handleCaseGenerated} />;
      case 1:
        return (
          <CaseIntroduction
            caseType={formData.caseType}
            industryType={formData.industryType}
            difficultyLevel={formData.difficultyLevel}
            generatedData={formData.generatedData}
            introduction={generatedData}
          />
        );
      case 2:
        return (
          <CreateAnswer
            caseType={formData.caseType}
            industryType={formData.industryType}
            difficultyLevel={formData.difficultyLevel}
            generatedData={formData.generatedData}
            fetchedAnswer={fetchedAnswer}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div className="container">
      <Helmet>
        {/* Dynamic, descriptive titles and meta descriptions for each page */}
        <title>Create a Custom Case Interview - Your Path to Consultancy</title>
        <meta
          name="description"
          content="Generate custom case interviews to prepare for your consulting roles. Choose from various industries, case types, and difficulty levels."
        />
        <meta name="keywords" content="Case Interview, Consulting, Job Preparation, Custom Cases, Industry-specific, Difficulty Level" />
      </Helmet>

      {loading && <Loader />}

      <Stepper activeStep={currentStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        {currentStep === steps.length ? (
          <div>
            <Button onClick={backToMainMenu}>Go Back to Main Menu</Button>
          </div>
        ) : (
          <div>
            {getStepContent(currentStep)}
            <div className="center-buttons">
              {currentStep > 0 && (
                <Button disabled={showGenerateLoader} onClick={() => setCurrentStep((prevActiveStep) => prevActiveStep - 1)}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                color={currentStep === steps.length - 2 ? "secondary" : "primary"}
                onClick={handleNext}
                disabled={showGenerateLoader}
              >
                {currentStep === steps.length - 2 ? "Show Answer" : "Next"}
                {showGenerateLoader && <CircularProgress size={20} style={{ marginLeft: "10px" }} />}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateCaseInterview;
