import React from 'react';
import '../css/CancelSubscriptionModal.css';

const CancelSubscriptionModal = ({ isOpen, onClose, handleCancelPlan }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Cancel Subscription</h3>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to cancel your subscription?</p>
        </div>
        <div className="modal-footer">
          <button className="modal-button cancel" onClick={onClose}>
            Cancel
          </button>
          <button className="modal-button confirm" onClick={() => handleCancelPlan()}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;