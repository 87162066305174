import React, { useEffect } from 'react';
import CaseExperienceDropdown from "./CaseExperienceDropdown";
import DateNavigator from "./DateNavigator";
import LanguageDropdown from "./LanguageDropdown";
import Slider from './InputRange/Slider';

export default function Filter({ user, setCurrentDate, setStartTime, setEndTime, setCurrentLanguage, setCurrentExperience, currentDate }) {

  const handleDateChange = (date) => {
    setCurrentDate(date);
  };

  const handleSliderChange = (value) => {
    setStartTime(value.start)
    setEndTime(value.end)
  };

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language)
  };

  const handleCaseExperienceChange = (experience) => {
    setCurrentExperience(experience)
  };

  useEffect(() => {
    setCurrentExperience(user[0]?.user_experience_level)
  },[user])

  return (
    <form className="Meeting_perpose get_list   ">

         <div className="row">
             <div className="col-md-3 mb-3">
               <DateNavigator currentDate={currentDate} onDateChange={handleDateChange} />
             </div>
             <div className="col-md-3 mb-3">
                <Slider 
                  min={0}
                  max={1000}
                  onChange={({ min, max }) =>
                    console.log(`min = ${min}, max = ${max}`)
                  }
                  onSliderChange={handleSliderChange}
                />
             </div>
             <LanguageDropdown onLanguageChange={handleLanguageChange} />
             <CaseExperienceDropdown onExperienceChange={handleCaseExperienceChange} user={user}/>
         </div>
    </form>
  );
}