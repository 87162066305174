import { Button, Form } from "react-bootstrap";



const CustomButton = ({group, id, handleButton, ButtonText, isChecked}) => {

    const buttonStyle = {
        backgroundColor: isChecked  ? '#E7EDFF' : 'transparent',
        color: isChecked ? '#0066FF' : 'black',
        width: 'auto',
        border: isChecked ? '1px solid #0066FF': '1px solid #DCDCDC',
        fontWeight: '500',
        textTransform: 'capitalize',
        padding: '4px 20px'
    };

    return (
        <Button style={buttonStyle}>
            <Form.Check
                inline
                name={group}
                type='checkbox'
                id={`inline-Checkbox-${id}`}
                onChange={handleButton}
                checked={isChecked}
            />
            {ButtonText}
        </Button>
    );
}

export default CustomButton