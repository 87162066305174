import React, { useState, useCallback } from "react";
import ReactFlow, { Controls, Background, useNodesState, useEdgesState, addEdge } from "reactflow";
import "reactflow/dist/style.css";
import { Button, Input, Modal, message, Tooltip, List, Typography, Form } from "antd";
import { PlusOutlined, QuestionCircleOutlined, CheckOutlined, CommentOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const nodeWidth = 180;
const nodeHeight = 80;
const nodeSpacingX = 250;
const nodeSpacingY = 100;
const blockSpacingY = 200;

const CustomNode = ({ data }) => {
  const [showAnswerInput, setShowAnswerInput] = useState(false);
  const [showExampleAnswers, setShowExampleAnswers] = useState(false);
  const [showAddAnswerModal, setShowAddAnswerModal] = useState(false);
  const [userAnswer, setUserAnswer] = useState("");

  const handleAddAnswer = () => {
    if (userAnswer.trim()) {
      data.addUserAnswer(data.id, userAnswer.trim());
      setUserAnswer("");
      setShowAddAnswerModal(false);
    }
  };

  const isLastChildNode = ["Price", "Volume", "Fixed Costs", "Variable Costs"].includes(data.label);

  return (
    <div style={{ position: "relative", width: nodeWidth, minHeight: nodeHeight }}>
      <div
        style={{
          width: nodeWidth,
          height: nodeHeight,
          border: "1px solid #ddd",
          borderRadius: "5px",
          padding: "10px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "absolute",
          zIndex: 1,
        }}
      >
        <div>{data.label}</div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
          {data.canAddChildren && <Button icon={<PlusOutlined />} size="small" onClick={() => data.onAdd(data.id)} />}
          {!isLastChildNode && data.isEditable && <Button icon={<EditOutlined />} size="small" onClick={() => data.onEdit(data.id)} />}
          <Tooltip title="Click for hint">
            <QuestionCircleOutlined style={{ cursor: "pointer" }} onClick={() => data.onHint(data.label)} />
          </Tooltip>
          {isLastChildNode && (
            <>
              <Tooltip title="Add your answer">
                <CommentOutlined style={{ cursor: "pointer" }} onClick={() => setShowAddAnswerModal(true)} />
              </Tooltip>
              <Tooltip title="View example answers">
                <EyeOutlined style={{ cursor: "pointer" }} onClick={() => setShowExampleAnswers(true)} />
              </Tooltip>
            </>
          )}
        </div>
        {showExampleAnswers && (
          <Modal
            title="Example Answers"
            open={showExampleAnswers}
            onOk={() => setShowExampleAnswers(false)}
            onCancel={() => setShowExampleAnswers(false)}
            footer={[
              <Button key="back" onClick={() => setShowExampleAnswers(false)}>
                Close
              </Button>,
            ]}
          >
            <List
              size="small"
              dataSource={data.exampleAnswers}
              renderItem={(item) => (
                <List.Item style={{ padding: "10px 5px", fontSize: "14px" }}>
                  <Paragraph>{item}</Paragraph>
                </List.Item>
              )}
            />
          </Modal>
        )}
      </div>
      {data.userAnswers && data.userAnswers.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "100%",
            transform: "translateY(-50%)",
            width: "100%",
            padding: "5px",

            borderRadius: "5px",
            zIndex: 0,
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ul
            style={{
              listStyleType: "disc", // Simple disc bullets
              padding: "0", // No padding around the list
              margin: "0", // No margin around the list
              fontSize: "12px", // Smaller font size for compactness
              backgroundColor: "none", // Light gray background
              fontFamily: "Arial, sans-serif", // Clean, simple font
            }}
          >
            {data.userAnswers.map((item, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "5px", // Minimal space between items
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
      <Modal
        title="Add Your Answer"
        open={showAddAnswerModal}
        onOk={handleAddAnswer}
        onCancel={() => setShowAddAnswerModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowAddAnswerModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAddAnswer}>
            Submit
          </Button>,
        ]}
      >
        <Form>
          <Form.Item>
            <Input.TextArea value={userAnswer} onChange={(e) => setUserAnswer(e.target.value)} placeholder="Enter your answer" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const IssueTreeBuilder = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([
    {
      id: "0",
      type: "custom",
      data: {
        label: "Restaurant Profits",
        canAddChildren: true,
        isEditable: false,
        id: "0",
        onAdd: (id) => addNode(id),
        onEdit: (id) => editNode(id),
        onHint: (label) => showHint(label),
        addUserAnswer: (id, answer) => addUserAnswer(id, answer),
        userAnswers: [],
        exampleAnswers: [],
      },
      position: { x: 0, y: 0 },
    },
  ]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const [newNodeName, setNewNodeName] = useState("");
  const [showExamples, setShowExamples] = useState(false);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const treeStructure = {
    "Restaurant Profits": ["Revenue", "Costs"],
    Revenue: ["Price", "Volume"],
    Costs: ["Fixed Costs", "Variable Costs"],
    Price: [],
    Volume: [],
    "Fixed Costs": [],
    "Variable Costs": [],
  };

  const hints = {
    "Restaurant Profits": "The two main components affecting profits are Revenue and Costs.",
    Revenue: "Revenue can be broken down into Price and Volume.",
    Costs: "Costs are typically categorized as Fixed Costs or Variable Costs.",
    Price: "What factors might affect the pricing of menu items?",
    Volume: "What could influence the number of customers or orders?",
    "Fixed Costs": "What costs remain constant regardless of sales volume?",
    "Variable Costs": "What costs change in proportion to sales or production?",
  };

  const examples = {
    Price: ["Menu pricing strategy", "Competitive pricing", "Seasonal pricing"],
    Volume: ["Customer traffic", "Table turnover rate", "Average party size"],
    "Fixed Costs": ["Rent", "Insurance", "Salaries for permanent staff"],
    "Variable Costs": ["Food ingredients", "Hourly wages", "Utilities"],
  };

  const showHint = (label) => {
    message.info(hints[label] || "Think about factors that might affect this aspect of the business.");
  };

  const calculateBlockPosition = (parentNode, blockType) => {
    const level = 1;
    const newX = level * nodeSpacingX;
    const parentY = parentNode.position.y;
    const newY = blockType === "Revenue" ? parentY - blockSpacingY / 2 : parentY + blockSpacingY / 2;
    return { x: newX, y: newY };
  };

  const calculateNodePosition = (parentNode, index, totalChildren) => {
    const level = parentNode.id === "0" ? 1 : 2;
    const newX = level * nodeSpacingX;
    const parentY = parentNode.position.y;
    const totalHeight = (totalChildren - 1) * nodeSpacingY;
    const startY = parentY - totalHeight / 2;
    return { x: newX, y: startY + index * nodeSpacingY };
  };

  const addUserAnswer = useCallback(
    (id, answer) => {
      setNodes((nds) =>
        nds.map((node) => (node.id === id ? { ...node, data: { ...node.data, userAnswers: [...(node.data.userAnswers || []), answer] } } : node))
      );
    },
    [setNodes]
  );

  const addNode = useCallback(
    (parentId) => {
      setNodes((nds) => {
        const parentNode = nds.find((n) => n.id === parentId);
        if (!parentNode) return nds;

        const parentLabel = parentNode.data.label;
        const childrenOptions = treeStructure[parentLabel] || [];

        if (childrenOptions.length === 0) {
          setCurrentNode({ id: parentId, label: parentLabel });
          setNewNodeName("");
          setModalVisible(true);
          return nds;
        } else {
          const newNodes = [];
          const newEdges = [];

          childrenOptions.forEach((childLabel, index) => {
            const newNodeId = `${parentId}-${childLabel}`;
            let position;

            if (parentId === "0") {
              position = calculateBlockPosition(parentNode, childLabel);
            } else {
              position = calculateNodePosition(parentNode, index, childrenOptions.length);
            }

            const newNode = {
              id: newNodeId,
              type: "custom",
              data: {
                label: childLabel,
                canAddChildren: (treeStructure[childLabel] || []).length > 0,
                isEditable: (treeStructure[childLabel] || []).length === 0,
                id: newNodeId,
                onAdd: (id) => addNode(id),
                onHint: (label) => showHint(label),
                addUserAnswer: (id, answer) => addUserAnswer(id, answer),
                userAnswers: [],
                exampleAnswers: examples[childLabel] || [],
              },
              position: position,
              draggable: false,
            };

            newNodes.push(newNode);
            newEdges.push({ id: `e${parentId}-${newNodeId}`, source: parentId, target: newNodeId });
          });

          const updatedNodes = [...nds, ...newNodes];
          setEdges((eds) => [...eds, ...newEdges]);

          return updatedNodes.map((node) => (node.id === parentId ? { ...node, data: { ...node.data, canAddChildren: false } } : node));
        }
      });
    },
    [setNodes, setEdges, treeStructure, showHint, addUserAnswer, examples]
  );

  const editNode = (nodeId) => {
    const node = nodes.find((n) => n.id === nodeId);
    if (node) {
      setCurrentNode(node);
      setNewNodeName(node.data.label);
      setModalVisible(true);
    }
  };

  const handleAddOrEditNode = () => {
    if (newNodeName.trim() !== "") {
      setNodes((nds) => nds.map((node) => (node.id === currentNode.id ? { ...node, data: { ...node.data, label: newNodeName } } : node)));
      setModalVisible(false);
      message.success("Great job! You've added a valuable factor to consider.");
    }
  };

  const displayExamples = () => {
    setShowExamples(true);
    message.success("Excellent work on your analysis! Here are some examples to compare with your ideas.");
  };

  return (
    <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column" }}>
      <div style={{ flex: 1 }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
          attributionPosition="bottom-left"
        >
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>
      <div style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
        <Button onClick={displayExamples} icon={<CheckOutlined />}>
          I'm done! Show me examples
        </Button>
      </div>
      <Modal title="Add Factor" open={modalVisible} onOk={handleAddOrEditNode} onCancel={() => setModalVisible(false)}>
        <Input value={newNodeName} onChange={(e) => setNewNodeName(e.target.value)} placeholder="Enter factor name" />
      </Modal>
      <Modal title="Example Factors" open={showExamples} onOk={() => setShowExamples(false)} onCancel={() => setShowExamples(false)}>
        {Object.entries(examples).map(([category, items]) => (
          <div key={category}>
            <Title level={3}>{category}</Title>
            <List
              dataSource={items}
              renderItem={(item) => (
                <List.Item style={{ padding: "10px 5px", fontSize: "14px" }}>
                  <Paragraph>{item}</Paragraph>
                </List.Item>
              )}
            />
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default IssueTreeBuilder;
