import React from "react";
import "../../css/LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className="spinner-overlay">
      <div className="spinner"></div>
      <p>Saving and analyzing your case...</p>
      <p>You will receive a message when we're done. In the meantime, you can continue using all features!</p>
    </div>
  );
};

export default LoadingSpinner;
