export function convertTimestampToTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedTime = `${formattedHours}:${formattedMinutes}`;
  return formattedTime;
}

export function combineDateAndTimeToUTC(dateString, timeString) {
  // Concatenate date and time strings
  const combinedDateTimeString = dateString + "T" + timeString + "Z";

  // Create a new Date object using the combined date and time string
  const combinedDateTime = new Date(combinedDateTimeString);

  // Get the UTC time in milliseconds
  const utcMilliseconds = combinedDateTime.getTime();

  return utcMilliseconds;
}


export function getExperienceRange(userType) {
  switch (userType) {
      case "Novice":
          return "< 10";
      case "Advanced":
          return "11 - 30";
      case "Pro":
          return "> 30";
      default:
          return "< 10";
  }
}


export const handleCopy = (url, setShowCheckMark, toast) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(url)
      .then(() => {
        setShowCheckMark(true);
        setTimeout(() => {
          setShowCheckMark(false);
        }, 3000);
        toast.success("Clipboard Copy")
      })
      .catch((error) => toast.error(`Unable to copy to clipboard: ${error}`));
  } else {
    alert('Copying to clipboard is not supported in this browser');
  }
};


export const formatDate = (dateString) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const date = new Date(dateString);
  const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];

  return `${dayOfWeek} ${dayOfMonth} ${month}`;
};