import { useState } from "react";
import engflag from "../../../assets/en_big.webp";

const LanguageDropdown = ({onLanguageChange}) => {
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    onLanguageChange(event.target.value)
  };
  

  // const fetchData = async () => {
  //   try {
  //     const response = await getMeetingList({
  //       language: selectedLanguage
  //     });
  //     setMeetings(response.data.data);
  //   } catch (error) {
  //     console.error("Error fetching meeting list:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // },[selectedLanguage])


  return (
    <div className="col-md-3 mb-3 mt-3">
      <div>
        <select value={selectedLanguage} onChange={handleLanguageChange} className="form-select">
          <option disabled>Language</option>
          <option>
            <div>
              <span>
                <img src={engflag} alt="image" width="20" height="20" />
              </span>
              English
            </div>
          </option>

          <option>
            <div>
              <span>
                <img src={engflag} alt="image" width="20" height="20" />
              </span>
              German
            </div>
          </option>

          <option>
            <div>
              <span>
                <img src={engflag} alt="image" width="20" height="20" />
              </span>
              French
            </div>
          </option>

          <option>
            <div>
              <span>
                <img src={engflag} alt="image" width="20" height="20" />
              </span>
              Spanish
            </div>
          </option>
        </select>
      </div>
    </div>
  );
};

export default LanguageDropdown;
