// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import "../css/Choose.css";
// import NewImg from "../assets/van-tay-media-TFFn3BYLc5s-unsplash.jpg";
// import arithmetic from "../assets/arithmetic.jpg";
// import LockIcon from "../assets/lock-icon.png";
// import UnlockedLockIcon from "../assets/unlock.png";
// import CarlCase from "../assets/Carl_Case.png";
// import SimoneSkills from "../assets/Simone_Skills.png";
// import PersonalExperience from "../assets/Personal_Experience.png";
// import { Link } from "react-router-dom";
// import { API_URL } from "../config/config.index";
// import { AuthContext } from "../context/auth.context";
// import { Upgrade } from "@mui/icons-material";

// function ChoosePractice() {
//   const { isLoggedIn, user } = useContext(AuthContext);
//   const [userPlan, setUserPlan] = useState("");
//   const [hasFreeCarlCase, setHasFreeCarlCase] = useState(false);
//   const [hasFreeSimoneCase, setHasFreeSimoneCase] = useState(false);
//   const [hasFreePersonalExperience, setHasFreePersonalExperience] = useState(false);

//   useEffect(() => {
//     async function fetchData() {
//       if (!isLoggedIn || !user || !user.email) {
//         return;
//       }

//       try {
//         const freeCaseStatusResponse = await axios.get(`${API_URL}/api/checkFreeCaseStatus`, {
//           params: { email: user.email },
//         });
//         console.log("Free case status response:", freeCaseStatusResponse.data);
//         setHasFreeCarlCase(!freeCaseStatusResponse.data.hasCreatedFreeCarlCase);
//         setHasFreeSimoneCase(!freeCaseStatusResponse.data.hasCreatedFreeSimoneCase);
//         setHasFreePersonalExperience(!freeCaseStatusResponse.data.hasCreatedFreePersonalExperience);

//         const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
//         const stripeCustomerId = customerIdResponse.data.stripeCustomerId;
//         if (stripeCustomerId) {
//           const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
//           setUserPlan(productResponse.data.productName);
//         }
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     }

//     fetchData();
//   }, [isLoggedIn, user]);

//   const userHasPlan = (planName) => {
//     return userPlan === planName || (planName === "Basic Plan" && userPlan === "Premium Plan");
//   };

//   const hasAccessCarl = userHasPlan("Basic Plan") || hasFreeCarlCase;
//   const hasAccessSimone = userHasPlan("Premium Plan") || hasFreeSimoneCase;
//   const hasAccessPersonalExperience = userHasPlan("Premium Plan") || hasFreePersonalExperience;

//   const Card = ({ title, image, description, link, buttonText, badge, requiredPlan, hasCardAccess }) => {
//     console.log(`User Plan: ${userPlan}`);
//     console.log(`hasFreeCarlCase: ${hasFreeCarlCase}`);
//     console.log(`hasFreeSimoneCase: ${hasFreeSimoneCase}`);
//     console.log(`hasFreePersonalExperience: ${hasFreePersonalExperience}`);

//     // Lock icon source based on access
//     const lockIconSrc = hasCardAccess ? UnlockedLockIcon : LockIcon;

//     return (
//       <div className="choice-card">
//         {badge && <span className={`badge ${badge.toLowerCase()}`}>{badge}</span>}
//         <img src={lockIconSrc} alt={hasCardAccess ? "Unlocked" : "Locked"} className="lock-icon" />
//         {(requiredPlan === "Basic Plan" && hasFreeCarlCase) ||
//         (requiredPlan === "Premium Plan" && (hasFreeSimoneCase || (title === "Personal Experience Partner" && hasFreePersonalExperience))) ? (
//           <div className="free-session">Free Session Available!</div>
//         ) : null}
//         <h2 className="choice-title">{title}</h2>
//         <div className="choice-image-container">
//           <img className="choice-image" src={image} alt={title} />
//         </div>
//         <p className="choice-description">{description}</p>
//         <Link to={link} className={`choice-button ${!hasCardAccess ? "premium-button" : ""}`}>
//           {buttonText}
//         </Link>
//       </div>
//     );
//   };

//   return (
//     <div className="choice-container">
//       <Card
//         title="Crack the Case with Carl"
//         image={CarlCase}
//         description="Sharpen your interview acumen with authentic, real-world case scenarios. Ideal for comprehensive practice and mastery of case interviews in their entirety."
//         link={userHasPlan("Basic Plan") || hasFreeCarlCase ? "/practice-case-interview" : "/plans"}
//         buttonText={userHasPlan("Basic Plan") || hasFreeCarlCase ? "Practice" : "Upgrade Now"}
//         badge="Basic"
//         requiredPlan="Basic Plan"
//         hasCardAccess={hasAccessCarl}
//       />

//       <Card
//         title="Simone the Skill Builder"
//         image={SimoneSkills}
//         description="Enhance your skills with Simone the Skill Builder. Dive into focused practice sessions covering key areas like case math, market sizing, and break-even analysis, tailored to elevate your strategic thinking."
//         link={userHasPlan("Premium Plan") || hasFreeSimoneCase ? "/practice-skills-related-to-case-interview" : "/plans"}
//         buttonText={userHasPlan("Premium Plan") || hasFreeSimoneCase ? "Practice" : "Upgrade Now"}
//         badge="Premium"
//         requiredPlan="Premium Plan"
//         hasCardAccess={hasAccessSimone}
//       />

//       <Card
//         title="Personal Experience Partner"
//         image={PersonalExperience}
//         description="Master your personal experience interview with our Personal Experience Partner. Practice and perfect your storytelling for interviews, with tailored scenarios specific to your target company. Get crucial feedback to ensure your personal stories stand out in any interview."
//         link={userHasPlan("Premium Plan") || hasFreePersonalExperience ? "/choose-personal-experience" : "/plans"}
//         buttonText={userHasPlan("Premium Plan") || hasFreePersonalExperience ? "Practice" : "Upgrade Now"}
//         badge="Beta"
//         requiredPlan="Premium Plan"
//         hasCardAccess={hasAccessPersonalExperience}
//       />

//       {/* ... Other Cards ... */}
//     </div>
//   );
// }

// export default ChoosePractice;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "../css/Choose.css";
import NewImg from "../assets/van-tay-media-TFFn3BYLc5s-unsplash.jpg";
import arithmetic from "../assets/arithmetic.jpg";
import LockIcon from "../assets/lock-icon.png";
import UnlockedLockIcon from "../assets/unlock.png";
import CarlCase from "../assets/Carl_Case.png";
import SimoneSkills from "../assets/Simone_Skills.png";
import PersonalExperience from "../assets/Personal_Experience.png";
import { Link } from "react-router-dom";
import { API_URL } from "../config/config.index";
import { AuthContext } from "../context/auth.context";

function ChoosePractice() {
  const { isLoggedIn, user } = useContext(AuthContext);
  const [userPlan, setUserPlan] = useState("");
  // Assuming state related to free sessions remains the same
  const [hasFreeCarlCase, setHasFreeCarlCase] = useState(false);
  const [hasFreeSimoneCase, setHasFreeSimoneCase] = useState(false);
  const [hasFreePersonalExperience, setHasFreePersonalExperience] = useState(false);

  // Simplified card access check
  const hasAccess = () => true; // Simplifying for demonstration. This should always return true for now.

  const Card = ({ title, image, description, link, badge }) => {
    const hasCardAccess = hasAccess(); // This is always true for now

    return (
      <div className="choice-card">
        {badge && <span className={`badge ${badge.toLowerCase()}`}>{badge}</span>}
        {/* Removed the line that renders the lock icon */}
        <h2 className="choice-title">{title}</h2>
        <div className="choice-image-container">
          <img className="choice-image" src={image} alt={title} />
        </div>
        <p className="choice-description">{description}</p>
        <Link to={hasCardAccess ? link : "/plans"} className="choice-button">
          {hasCardAccess ? "Start Practicing" : "Explore"}
        </Link>
      </div>
    );
  };

  return (
    <div className="choice-container">
      <Card
        title="Crack the Case with Carl"
        image={CarlCase}
        description="Dive into real-world scenarios and refine your case-solving tactics with Carl. Each challenge is an opportunity to simulate the consulting interview experience."
        link="/practice-case-interview"
        badge="Basic"
        // requiredPlan="Basic Plan"
      />

      <Card
        title="Simone the Skill Builder"
        image={SimoneSkills}
        description="Boost your skillset with focused drills from Simone. Cover essential areas like case math and market analysis, and elevate your strategic approach."
        link="/practice-skills-related-to-case-interview"
        badge="Premium"
        // requiredPlan="Premium Plan"
      />

      <Card
        title="Personal Experience Partner"
        image={PersonalExperience}
        description="Narrate compelling stories with the Personal Experience Partner. Engage in tailored sessions to craft and refine the narrative of your consulting journey."
        link="/choose-personal-experience"
        badge="Beta"
        // requiredPlan="Premium Plan"
      />

      {/* ... Other Cards ... */}
    </div>
  );
}

export default ChoosePractice;
