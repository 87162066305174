import React, { useState, useEffect } from "react";
import "../css/ProgressBar.css"; // Assume this CSS file styles the component to be centered and styled

const ProgressBar = ({ targetProgress }) => {
  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${targetProgress}%` }} />
      </div>
      <div className="progress-text">{targetProgress}%</div>
    </div>
  );
};

export default ProgressBar;
