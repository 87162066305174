import React, { createContext, useState, useContext } from "react";

const AcademyContext = createContext();

export const AcademyProvider = ({ children }) => {
  const [dayData, setDayData] = useState([]);

  return <AcademyContext.Provider value={{ dayData, setDayData }}>{children}</AcademyContext.Provider>;
};

export const useAcademy = () => useContext(AcademyContext);
