import { useEffect, useRef, useState } from "react";
import CustomTooltip from "../../Tooltip/CustomTooltip";
import Filter from "./Filter";
import Faqs from "./Faqs";
import CreateMeeting from "./CreateMeeting";
import { API_URL } from "../../../config/config.index";
import axios from "axios";
import Meeting from "./Meeting";
import CalenderSvg from "../../svgIcons/CalenderSvg";
import PurposeMeeting from "../../svgIcons/PurposeMeeting";
import Acceptance from "../../svgIcons/Acceptance";
import ConductMeeting from "../../svgIcons/ConductMeeting";
import RightIcon from "../../svgIcons/RightIcon";
import { Helmet } from "react-helmet";

const MeetingBoardContent = ({
  fetchData,
  meetings,
  setMeetings,
  setCurrentDate,
  setStartTime,
  setEndTime,
  setCurrentLanguage,
  setCurrentExperience,
  currentDate,
}) => {
  const [languageTooltip, setLanguageTooltip] = useState(false);
  const [proposeTooltip, setProposeTooltip] = useState(false);
  const [acceptenceTooltip, setAcceptenceTooltip] = useState(false);
  const [meetingTooltip, setMeetingTooltip] = useState(false);

  const [user, setUser] = useState([]);

  const gotToken = localStorage.getItem("authToken");

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${gotToken !== null && gotToken}`,
    },
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API_URL}/meeting/users`, headers);
      setUser(response.data.users);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [gotToken]);

  const languageRef = useRef(null);
  const proposeRef = useRef(null);
  const acceptenceRef = useRef(null);
  const meetingRef = useRef(null);

  return (
    <div className="meeting_board_content">
      <Helmet>
        <title>Case Interview Practice Platform for Aspiring Consultants</title>
        <meta
          name="description"
          content="Join our community to find partners for practicing case interviews. Ideal for aspiring consultants preparing for interviews with top consulting firms. Engage in live case practice, get feedback, and sharpen your problem-solving skills."
        />
        <meta
          name="keywords"
          content="case interview practice, consulting case preparation, practice case interviews online, peer case interview practice, consulting interview preparation, case interview partner finder, management consulting case practice"
        />
      </Helmet>
      <div className="container">
        <div className="dissmisable-notice-box">
          <p>Please follow the steps below to schedule a meeting:</p>
          <h2>
            Scheduling the meeting -<span className="heading_color"> How To Guide</span>
          </h2>
          <div className="interviewBasic_Steps">
            <div ref={languageRef} onClick={() => setLanguageTooltip((prev) => !prev)} className="process-item">
              <div className="process-item-circle">
                <span>
                  {" "}
                  <CalenderSvg />
                </span>
              </div>
              <div className="process-item-text">Pick a Date and Language</div>
            </div>
            <CustomTooltip target={languageRef.current} show={languageTooltip}>
              Select a suitable date and your preferred language for an effective exchange.
            </CustomTooltip>
            <div className="process-arrow">
              <span>
                <RightIcon />
              </span>
            </div>
            {/* <CreateMeeting fetchData={fetchData}/>
            <div className="conduct_meeting">
                <div className="container">
                    <Filter user={user}
                        setCurrentDate={setCurrentDate}
                        setStartTime ={setStartTime}
                        setEndTime={setEndTime}
                        setCurrentLanguage={setCurrentLanguage}
                        setCurrentExperience={setCurrentExperience}
                        currentDate={currentDate} 
                    />
                    <Meeting meetings={meetings} setMeetings={setMeetings} fetchData={fetchData}/>
                </div>
            </div> */}

            <div ref={proposeRef} onClick={() => setProposeTooltip((prev) => !prev)} className="process-item">
              <div className="process-item-circle">
                <span>
                  <PurposeMeeting />
                </span>
              </div>
              <div className="process-item-text">Propose meeting</div>
            </div>

            <CustomTooltip target={proposeRef.current} show={proposeTooltip}>
              Send out your proposed meeting details for consideration.
            </CustomTooltip>
            <div className="process-arrow">
              <span>
                {" "}
                <RightIcon />
              </span>
            </div>
            <div ref={acceptenceRef} onClick={() => setAcceptenceTooltip((prev) => !prev)} className="process-item">
              <div className="process-item-circle">
                <span>
                  <Acceptance />{" "}
                </span>
              </div>
              <div className="process-item-text">Wait for acceptance</div>
            </div>
            <CustomTooltip target={acceptenceRef.current} show={acceptenceTooltip}>
              Await the approval from your peers to confirm the session's schedule.
            </CustomTooltip>
            <div className="process-arrow">
              <span>
                {" "}
                <RightIcon />
              </span>
            </div>
            <div ref={meetingRef} onClick={() => setMeetingTooltip((prev) => !prev)} className="process-item">
              <div className="process-item-circle">
                <span>
                  <ConductMeeting />{" "}
                </span>
              </div>
              <div className="process-item-text">Conduct meeting</div>
            </div>
            <CustomTooltip target={meetingRef.current} show={meetingTooltip}>
              Lead the session with confidence once confirmation is received.
            </CustomTooltip>
          </div>
        </div>
      </div>
      {/* <CreateMeeting />
      <div className="conduct_meeting">
        <div className="container">
          <Filter setMeetings={setMeetings} user={user} />
          <Meeting meetings={meetings} setMeetings={setMeetings} />
        </div>
      </div> */}
      <CreateMeeting fetchData={fetchData} />
      <div className="conduct_meeting">
        <div className="container">
          <Filter
            user={user}
            setCurrentDate={setCurrentDate}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            setCurrentLanguage={setCurrentLanguage}
            setCurrentExperience={setCurrentExperience}
            currentDate={currentDate}
          />
          <Meeting meetings={meetings} setMeetings={setMeetings} fetchData={fetchData} />
        </div>
      </div>
      <Faqs />
    </div>
  );
};

export default MeetingBoardContent;
