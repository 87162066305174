import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/auth.context";
import { API_URL, API_URL_NEW_BACKEND } from "../../config/config.index";
import { marked } from "marked";
import useSubscriptionCheck from "../useSubscriptionCheck";
import simoneImage from "../../assets/Simone_Skills.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faStop, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import CustomSkillCreatorMobile from "./CustomSkillCreatorMobile.jsx";
import "../../css/SimoneSkillsMobile.css";

const SimoneSkillsMobile = () => {
  const [inputValue, setInputValue] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [currentSkill, setCurrentSkill] = useState("General Skill Building");
  const [currentDifficulty, setCurrentDifficulty] = useState("Medium");
  const [showCustomSkillCreator, setShowCustomSkillCreator] = useState(false);
  const [showChatInterface, setShowChatInterface] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const { user, isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const eventSourceRef = useRef(null);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const isAllowed = useSubscriptionCheck();

  useEffect(() => {
    scrollToBottom();
  }, [conversationHistory]);

  useEffect(() => {
    console.log("Conversation history updated:", conversationHistory);
  }, [conversationHistory]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const addToConversation = useCallback((type, text) => {
    console.log("Adding to conversation:", type, text);
    setConversationHistory((prev) => {
      if (type === "ai" && prev.length > 0 && prev[prev.length - 1].type === "ai") {
        const updatedHistory = [...prev];
        updatedHistory[prev.length - 1] = { ...updatedHistory[prev.length - 1], text };
        console.log("Updated history:", updatedHistory);
        return updatedHistory;
      } else {
        const newHistory = [...prev, { type, text: text || "" }];
        console.log("New history:", newHistory);
        return newHistory;
      }
    });

    setTimeout(scrollToBottom, 0);
  }, []);

  const handleSend = async () => {
    const trimmedInput = inputValue.trim();
    if (trimmedInput === "" || loading) return;

    setLoading(true);
    addToConversation("user", trimmedInput);
    setInputValue("");

    try {
      await fetchOpenAIResponseStreaming(trimmedInput, threadId);
    } catch (error) {
      console.error("Error fetching OpenAI response:", error);
      addToConversation("ai", "Sorry, I encountered an error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchOpenAIResponseStreaming = async (promptText, currentThreadId) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    try {
      const response = await fetch(`${API_URL_NEW_BACKEND}/api/skill-building`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userMessage: promptText,
          threadId: currentThreadId,
          skill: currentSkill,
          difficulty: currentDifficulty,
        }),
      });

      const data = await response.json();
      const newThreadId = data.threadId;

      if (!currentThreadId) {
        setThreadId(newThreadId);
      }

      if (newThreadId) {
        const newEventSource = new EventSource(`${API_URL_NEW_BACKEND}/api/stream-responses?threadId=${data.threadId}`);
        let rawContent = "";

        newEventSource.addEventListener("messageStart", () => {
          console.log("Message start event received");
          rawContent = "";
          addToConversation("ai", "");
        });

        newEventSource.addEventListener("textDelta", (event) => {
          console.log("Text delta event received:", event.data);
          const eventData = JSON.parse(event.data);
          rawContent += eventData.value;
          const html = DOMPurify.sanitize(marked(rawContent));
          console.log("Sanitized HTML:", html);
          addToConversation("ai", html);
        });

        newEventSource.onopen = () => console.log("SSE connection opened successfully.");
        newEventSource.onerror = (event) => {
          console.error("SSE connection encountered an error:", event);
          setLoading(false);
          newEventSource.close();
        };

        eventSourceRef.current = newEventSource;
      } else {
        console.error("No threadId received from the server");
        addToConversation("ai", "Sorry, I encountered an error. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      addToConversation("ai", "Sorry, I encountered an error. Please try again.");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleNewSkillSession = () => {
    setConversationHistory([]);
    setInputValue("");
    setThreadId(null);
    setCurrentSkill("General Skill Building");
    setCurrentDifficulty("Medium");
    setShowCustomSkillCreator(true);
    setShowChatInterface(false);
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }
  };

  const handleHelpMeCreateSkillSession = () => {
    setShowChatInterface(true);
    fetchOpenAIResponseStreaming("Help me create a skill-building session. Please guide me through the process step by step.");
  };

  const handleCreateOwnSkillSession = () => {
    setShowCustomSkillCreator(true);
  };

  const handleCustomSkillSubmit = async (formData) => {
    setShowCustomSkillCreator(false);
    setShowChatInterface(true);
    setCurrentSkill(formData.skill);
    setCurrentDifficulty(formData.difficulty);
    const promptText = `Let's start a skill-building session focusing on ${formData.skill} at a ${formData.difficulty} level. Please provide a relevant exercise or question.`;
    await fetchOpenAIResponseStreaming(promptText);
  };

  const startRecording = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.warn("getUserMedia not supported on your browser!");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        recorder.start();
        setIsRecording(true);

        recorder.ondataavailable = async (e) => {
          const audioData = new Blob([e.data], { type: "audio/wav" });
          const formData = new FormData();
          formData.append("file", audioData, "audio.wav");

          try {
            const response = await fetch(`${API_URL_NEW_BACKEND}/api/transcribe`, {
              method: "POST",
              body: formData,
            });
            const data = await response.json();
            setInputValue(data.transcription.text);
          } catch (error) {
            console.error("Error while sending audio data:", error);
          }
        };

        recorder.onerror = (err) => {
          console.error("Recorder error:", err);
          setIsRecording(false);
        };

        recorder.onstop = () => {
          stream.getTracks().forEach((track) => track.stop());
          setIsRecording(false);
        };
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };

  if (!showChatInterface && !showCustomSkillCreator) {
    return (
      <div className="simone-skills-mobile simone-initial-options">
        <h1>Create a Skill-Building Session</h1>
        <p>Choose how you'd like to start:</p>
        <button className="simone-option-button" onClick={handleHelpMeCreateSkillSession}>
          Help me create a session
        </button>
        <button className="simone-option-button" onClick={handleCreateOwnSkillSession}>
          Create my own session
        </button>
      </div>
    );
  }
  return (
    <div className="simone-skills-mobile">
      {!showChatInterface && !showCustomSkillCreator && (
        <div className="simone-initial-options">
          <h1>Create a Skill-Building Session</h1>
          <p>Choose how you'd like to start:</p>
          <button className="simone-option-button" onClick={handleHelpMeCreateSkillSession}>
            Help me create a session
          </button>
          <button className="simone-option-button" onClick={handleCreateOwnSkillSession}>
            Create my own session
          </button>
        </div>
      )}
      {showChatInterface && (
        <div className="simone-chat-interface">
          <div className="simone-chat-container" ref={chatContainerRef}>
            {conversationHistory.map((message, index) => (
              <div key={index} className={`simone-message simone-message--${message.type}`}>
                {message.type === "ai" && <img src={simoneImage} alt="AI" className="simone-message-icon" />}
                <div className="simone-message-content" dangerouslySetInnerHTML={{ __html: message.text }} />
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="simone-chat-footer-wrapper">
            <footer className="simone-chat-footer">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSend();
                }}
                className="simone-input-form"
              >
                <div className="simone-input-wrapper">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message..."
                    disabled={loading}
                    className="simone-message-input"
                  />
                  <button
                    type="button"
                    className={`simone-mic-button ${isRecording ? "simone-recording" : ""}`}
                    onClick={isRecording ? stopRecording : startRecording}
                  >
                    <FontAwesomeIcon icon={isRecording ? faStop : faMicrophone} />
                  </button>
                </div>
                <button type="submit" disabled={loading || !inputValue.trim()} className="simone-send-button">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 2L11 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </form>
            </footer>
            <div className="simone-actions">
              <button className="simone-button simone-button--new-session" onClick={handleNewSkillSession} title="Start New Session">
                <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>
          </div>
        </div>
      )}
      {showCustomSkillCreator && (
        <CustomSkillCreatorMobile
          onSubmit={handleCustomSkillSubmit}
          onClose={() => {
            setShowCustomSkillCreator(false);
            setShowChatInterface(true);
          }}
        />
      )}
    </div>
  );
};

export default SimoneSkillsMobile;
