import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQsSection = () => {
  return (
    <section className="FAQsSection">
      <h1 className="FAQs-title">FAQs</h1>
      <p className="FAQs-subtitle">Frequently Asked Questions</p>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>When should I choose Basic and when Premium?</Accordion.Header>
          <Accordion.Body>
            The Basic Plan is useful for most people right now. It provides access to our core features and is sufficient for regular practice. The
            Premium Plan allows you to do more practices per day and save more than one case per day. However, the real value for Premium will be
            unlocked when we add the academy (which is currently a work in progress). If you're looking for intensive practice or want to be ready for
            our upcoming advanced features, Premium might be the right choice for you.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>How should I use CaseStudyGenerator?</Accordion.Header>
          <Accordion.Body>
            We suggest you start by doing one AI-Case Practice to see where you get stuck. Based on that, you'll receive feedback and insights into
            your performance. This initial practice will help you identify areas for improvement and guide your further preparation. You can then
            focus on specific skills or types of cases that need more attention.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>How does the saving and analysis work?</Accordion.Header>
          <Accordion.Body>
            We have AI agents set up who are specialized in analyzing the outcome of your case. Go to the Progress section to track your performance
            over time, review previous case performances, and see which key skills need extra practice. We also provide tips on how to practice those
            skills. You can do everything on CaseStudyGenerator, making it a one-stop platform for your case interview preparation.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>What types of cases does CaseStudyGenerator offer?</Accordion.Header>
          <Accordion.Body>
            CaseStudyGenerator offers a wide range of case types typically encountered in consulting interviews, including market sizing,
            profitability, market entry, growth strategy, and more. Our AI ensures that each case is unique and tailored to your skill level and areas
            for improvement.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>How does CaseStudyGenerator compare to other case prep methods?</Accordion.Header>
          <Accordion.Body>
            CaseStudyGenerator stands out by offering AI-powered, personalized case practice with real-time feedback and progress tracking. Unlike
            static resources or limited human coaching, our platform adapts to your needs, provides unlimited practice opportunities, and gives you
            detailed insights into your performance over time.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Can I practice with a partner on CaseStudyGenerator?</Accordion.Header>
          <Accordion.Body>
            Absolutely! We highly recommend practicing with other people, and we're thrilled to facilitate that on CaseStudyGenerator! We believe AI
            practice and peer practice are complementary and essential for comprehensive interview preparation. Use our AI case practice and
            skill-building features to perfect your skills, then showcase them in real interviews with peers. Our platform offers a dedicated space
            for you to connect with other aspiring consultants, schedule practice sessions, and give each other valuable feedback. This combination of
            AI-powered insights and human interaction will give you the best of both worlds, ensuring you're fully prepared for your consulting
            interviews. Remember, the more you practice with both AI and peers, the more confident and skilled you'll become!
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Are there any discounts available?</Accordion.Header>
          <Accordion.Body>
            <p>
              {" "}
              We're glad you asked! We understand that preparing for consulting interviews can be a significant investment, especially for students
              and early-career professionals. That's why we've established partnerships with several student consultancies to make our platform more
              accessible.{" "}
            </p>
            <p>
              If you're part of a student organization, or if you have any other questions about potential discounts or payment options, please don't
              hesitate to reach out to us at <a href="mailto:question@casestudygenerator.com">question@casestudygenerator.com</a>. Our team is
              committed to helping you find the best solution to support your consulting interview preparation journey. Remember, investing in your
              skills is investing in your future, and we're here to support you every step of the way!{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </section>
  );
};

export default FAQsSection;
