import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useEffect, useState } from "react";
import { API_URL } from "../config/config.index";
import { AuthContext } from "../context/auth.context";
import "../css/StripeForm.css";
import { NavLink, Navigate } from "react-router-dom";
import Loader from "./Loader";
import cardsimg from "../../src/assets/cards_logoimg.png";
import cvclogo from "../../src/assets/cvclogo.png";
import carimg from "../../src/assets/product-car.png";
import stockimg from "../../src/assets/andrew-neel-ute2XAFQU2I-unsplash.jpg";

function PaymentForm() {
  const [isLoading, setisLoading] = useState(false);
  const { user, isPremiumMember, setIsPremiumMember, setisStripeSubscriptionEnabled } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const [data, setdata] = useState(undefined);
  const [emaiil, setemail] = useState("");
  const [name, setname] = useState("");
  const [Dropdown, setDropdown] = useState(false);
  const [status, setStatus] = useState(false);
  const [error, seterror] = useState("");
  const [productId, setproductId] = useState(new URLSearchParams(window.location.search).get("productId"));

  const getProducts = async () => {
    const response = await fetch(API_URL + "/stripe/products", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) return alert("Cannot get product");
    const result = await response.json();
    setdata(result);
  };

  useEffect(() => {
    getProducts();
    function clear(e) {
      if (!e.target.closest(".pay_btn")) {
        seterror("");
      }
    }
    document.addEventListener("click", clear);
    return () => {
      document.removeEventListener("click", clear);
    };
  }, []);
  if (!productId) {
    // return <Navigate to="/plans" replace={true}/>
  }
  if (!user) {
    // return <Navigate to="/login" replace={true}/>
  }

  const createSubscription = async (e) => {
    let isfilled = true;
    try {
      e.preventDefault();
      setisLoading(true);
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement._complete) {
        isfilled = false;
      }

      // Validate expiry date
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      if (!cardExpiryElement._complete) {
        isfilled = false;
      }

      // Validate CVC
      const cardCvcElement = elements.getElement(CardCvcElement);
      if (!cardCvcElement._complete) {
        isfilled = false;
      }
      if (!isfilled) {
        setisLoading(false);
        return seterror("Please fill all the fields");
      }
      // console.log(elements.getElement("card"))
      const paymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement(CardNumberElement),
        type: "card",
      });

      const response = await fetch(API_URL + "/stripe/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: user._id,
          name: name,
          email: emaiil,
          product_id: productId,
          paymentMethod: paymentMethod.paymentMethod.id,
        }),
      });

      if (!response.ok) {
        seterror("Payment unsuccessful!");
        setStatus("fail");
        return;
      }
      const data = await response.json();

      const confirm = await stripe.confirmCardPayment(data.clientSecret, { receipt_email: "question@casestudygenerator.com" });
      if (confirm.error) {
        setStatus("fail");
        return seterror("Payment unsuccessful!");
      }

      // seterror("Payment Successful! Subscription active.");
      setStatus("success");
      setIsPremiumMember(true);
      setisStripeSubscriptionEnabled(true);
      console.log(confirm);
      localStorage.removeItem("authToken");
    } catch (err) {
      console.error(err);
      seterror("Payment failed! ");
      setStatus("fail");
    }
    setisLoading(false);
  };
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  return (
    <>
      {!status ? (
        <div className="stripe-container">
          <div className="productdropdown" onClick={() => setDropdown(!Dropdown)}>
            {data ? (
              <>
                {data.map((price) => {
                  if (price.id === productId) {
                    return (
                      <div key={price.id} className="product_content">
                        <h5 className="light-font">Subscribe to {price.product.name}</h5>
                        <h4>
                          {price.unit_amount / 100} {price.currency?.toUpperCase()} <span>/ {price.recurring.interval}</span>
                        </h4>

                        <p className="light-font">{price.product.description}</p>
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
            <div className="product_img">
              <img src={stockimg} alt=""></img>
            </div>
          </div>
          <div className="payment_right">
            <div className="light-font">Email</div>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                value={emaiil}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
            </div>
            <br />
            <div className="light-font"> Card Information</div>

            <div className="card_wrapper  ">
              <div className="cards_number">
                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                <div className="cards-imgs">
                  <img src={cardsimg} alt=""></img>
                </div>
              </div>
              <div className="cvv_wrapper">
                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                <div className="cvclogo">
                  <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                  <div className="cvc_card_logo">
                    <img src={cvclogo} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="light-font">Name on card</div>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter a name"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
            </div>

            <br />
            <br />
            {error ? <div className="text-danger py-2 text-center">Please fill all the fields</div> : ""}
            {isLoading ? (
              <button className="pay_btn">Loading</button>
            ) : (
              <>
                {name === "" || emaiil === "" ? (
                  <button className="pay_btn opacity-50">Subscribe </button>
                ) : (
                  <button className="pay_btn " onClick={createSubscription}>
                    Subscribe
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="box">
            {status === "success" ? (
              <div className="success alert">
                <div className="alert-body">Success !</div>
                Payment Successful! Subscription active.
                <p>
                  Go to{" "}
                  <NavLink className="" to="/profile">
                    Profile
                  </NavLink>
                </p>
              </div>
            ) : (
              <div className="error alert">
                <div className="alert-body">Error !</div>
                {error}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default PaymentForm;
