import React, { useState, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../css/Cases.css";
import { AuthContext } from "../context/auth.context";
import { API_URL } from "../config/config.index";
import Markdown from "markdown-to-jsx";

const CreateAnswer = ({ caseType, industryType, difficultyLevel, generatedData, onLearningSave, fetchedAnswer }) => {
  const { isLoggedIn, user, isPremiumMember, checkPremiumMembership, setIsPremiumMember } = useContext(AuthContext);
  const [learning, setLearning] = useState("");
  const [openSection, setOpenSection] = useState(null);

  const sections = fetchedAnswer.split(/(## .*\n)/).filter(Boolean);

  const saveLearning = async () => {
    if (!learning.trim()) {
      toast.error("Please write down your learnings before saving.");
      return;
    }

    try {
      const learningData = {
        user: user._id,
        caseType,
        industryType,
        difficultyLevel,
        learnings: learning,
      };

      await axios.post(`${API_URL}/learning/learnings`, learningData);

      setLearning("");
      toast.success("Learning saved successfully!"); // Show the success toast here
    } catch (error) {
      console.error("Error while saving learning:", error);
      toast.error("Failed to save learning. Please try again later.");
    }
  };

  const handleToggle = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  return (
    <div className="create-answer-container">
      <div className="fact-container">
        <h3 className="case-type">Case Form:</h3>
        <div className="case-type">{caseType}</div>
      </div>

      {sections.map((section, index) => (
        <div key={index} className={`section ${openSection === index ? "open" : ""}`} onClick={() => handleToggle(index)}>
          <Markdown>{section}</Markdown>
        </div>
      ))}

      {/* <div className="learning-container">
        <h2 className="learning-header">What did you learn?</h2>
        <textarea
          className="learning-textarea"
          placeholder="Write your learnings here..."
          value={learning}
          onChange={(e) => setLearning(e.target.value)}
        />
        <button className="save-button" onClick={saveLearning}>
          Save Learnings
        </button>
      </div> */}
      <ToastContainer />
    </div>
  );
};

export default CreateAnswer;
