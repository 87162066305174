import React from "react";
import SimmoneSkills from "./SimoneSkills";
import SimoneSkillsMobile from "./SimoneSkillsMobile";

const ResponsiveComponentSimone = () => {
  const isMobile = window.innerWidth <= 576; // Change this value based on your definition of a mobile device
  console.log("Is mobile: ", isMobile);
  return <>{isMobile ? <SimoneSkillsMobile /> : <SimmoneSkills />}</>;
};

export default ResponsiveComponentSimone;
