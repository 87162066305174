import { Overlay, Tooltip } from "react-bootstrap";

const CustomTooltip = ({ target, show, children }) => {
    return (
        <Overlay target={target} show={show} placement="top">
            {(props) => (
                <Tooltip id="overlay-example" {...props}>
                    {children}
                </Tooltip>
            )}
        </Overlay>
    );
};

export default CustomTooltip