import React, { useEffect, useState } from "react";
import questions from "../../src/components/pages/data/questions.json";
import { useContext } from "react";
import { AuthContext } from "../context/auth.context";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// Styles
const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "80%",
  maxWidth: "600px",
  padding: "20px",
  margin: "15px auto",
  border: "1px solid #ddd",
  borderRadius: "4px",
  textAlign: "center",
  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  fontFamily: "Arial, sans-serif",
};

const questionStyle = {
  marginBottom: "20px",
  fontSize: "1.2em",
  fontWeight: "bold",
};

const answerStyle = {
  display: "block",
  background: "#f4f4f4",
  color: "black",
  border: "none",
  padding: "10px 20px",
  margin: "10px 0",
  cursor: "pointer",
  width: "100%",
  textAlign: "left",
  borderRadius: "4px",
};

const scoreboardStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: "20px",
};

const scoreLabelStyle = {
  fontSize: "0.8em",
  fontWeight: "bold",
};

const scoreValueStyle = {
  fontSize: "1.2em",
};

const options = ["A", "B", "C", "D"];

const nextButtonStyle = {
  padding: "10px 20px",
  margin: "10px 0",
  background: "#007bff",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

function PracticeKnowledge() {
  const [question, setQuestion] = useState(null);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set());

  useEffect(() => {
    getRandomQuestion();
  }, []);

  const getRandomQuestion = () => {
    if (answeredQuestions.size >= questions.questions.length) {
      // Reset or handle 'all questions answered', for example, to reset:
      setAnsweredQuestions(new Set());
      return;
    }

    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * questions.questions.length);
    } while (answeredQuestions.has(randomIndex));

    setCurrentQuestionIndex(randomIndex);
    setAnsweredQuestions(new Set([...answeredQuestions, randomIndex]));
  };

  useEffect(() => {
    if (currentQuestionIndex !== null) {
      const currentQuestion = questions.questions[currentQuestionIndex];
      const answersArray = Object.values(currentQuestion.answers);
      const correctIndex = answersArray.indexOf(currentQuestion.answers["1"]);

      const shuffled = [...answersArray];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }

      setShuffledAnswers(shuffled);
      setCorrectAnswerIndex(shuffled.indexOf(currentQuestion.answers["1"]));
      setQuestion(currentQuestion);
      setSelectedAnswer(null);
      setShowExplanation(false);
    }
  }, [currentQuestionIndex]);

  const handleAnswer = (answer, index) => {
    if (selectedAnswer === null) {
      setSelectedAnswer(index);
      setShowExplanation(true);
      if (index === correctAnswerIndex) {
        setCorrectAnswers(correctAnswers + 1);
      } else {
        setIncorrectAnswers(incorrectAnswers + 1);
      }
    }
  };

  const handleNextQuestion = () => {
    getRandomQuestion();
  };

  if (!question) {
    return <p>Loading...</p>;
  }

  return (
    <div style={cardStyle}>
      <Helmet>
        <title>Practice Knowledge - CaseStudyGenerator</title>
        <meta name="description" content="Test your knowledge with our practice questions. Improve your skills today!" />
      </Helmet>
      <p style={questionStyle}>{question.question}</p>
      {shuffledAnswers.map((answer, index) => (
        <button
          style={{
            ...answerStyle,
            backgroundColor: showExplanation
              ? index === correctAnswerIndex
                ? "#48BB78"
                : index === selectedAnswer
                ? "#F56565"
                : "#f4f4f4"
              : "#f4f4f4",
          }}
          key={index}
          onClick={() => handleAnswer(answer, index)}
        >
          {options[index]}) {answer}
        </button>
      ))}
      {showExplanation && (
        <div>
          <p>
            <strong>{selectedAnswer === correctAnswerIndex ? "Correct." : "Incorrect."}</strong>
          </p>
          <p>{question.explanation}</p>
          <button style={nextButtonStyle} onClick={handleNextQuestion}>
            Next Question
          </button>
        </div>
      )}
      <div style={scoreboardStyle}>
        <div>
          <p style={scoreLabelStyle}>Correct Answers</p>
          <p style={scoreValueStyle}>{correctAnswers}</p>
        </div>
        <div>
          <p style={scoreLabelStyle}>Incorrect Answers</p>
          <p style={scoreValueStyle}>{incorrectAnswers}</p>
        </div>
      </div>
    </div>
  );
}

export default PracticeKnowledge;
