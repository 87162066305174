import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../config/config.index";

const AdminPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});

  const generateCase = async (caseType, industry) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL}/casegeneration/generateCase/${caseType}`, { industry });
      setAnalytics((prevState) => ({ ...prevState, [caseType]: (prevState[caseType] || 0) + 1 }));
      alert(`New case generated: ${response.data.caseId}`);
    } catch (error) {
      alert("Error generating case.");
    } finally {
      setIsLoading(false);
    }
  };

  const bulkGenerate = () => {
    const caseTypes = ["M&A", "Market Entry", "Cost Reduction", "Growth Strategy", "Operations"];
    const industries = ["Healthcare", "Tech", "Energy", "Retail", "Finance", "Manufacturing", "Telecom", "Tech"];
    // const caseTypes = ["M&A", "Market Entry", "Cost Reduction", "Growth Strategy", "Operations"];
    // const industries = ["Healthcare"];

    // const caseTypes = ["M&A", "Market Entry", "Cost Reduction", "Growth Strategy", "Operations"];
    // const industries = ["Tech"];

    // const caseTypes = ["M&A", "Market Entry", "Cost Reduction", "Growth Strategy", "Operations"];
    // const industries = ["Energy"];

    // const caseTypes = ["M&A", "Market Entry", "Cost Reduction", "Growth Strategy", "Operations"];
    // const industries = ["Retail"];

    caseTypes.forEach((caseType) => {
      const randomIndustry = industries[Math.floor(Math.random() * industries.length)];
      generateCase(caseType, randomIndustry);
    });
  };

  return (
    <div>
      {isLoading ? <div>Loading...</div> : null}
      <button onClick={bulkGenerate}>Bulk Generate Cases</button>
      <button onClick={() => generateCase("Profitability", "Healthcare")}>Generate Profitability Case</button>
      {/* ... other case buttons */}
      <div>
        <h2>Analytics</h2>
        {Object.entries(analytics).map(([caseType, count]) => (
          <div key={caseType}>
            {caseType}: {count}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPage;
