import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth.context";
import "../css/ProfilePage.css";
import { API_URL } from "../config/config.index";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { cancelCurrentSubscription, getUserSubscription } from "../services/SubscriptionServices";

function ProfilePage() {
  const { isLoggedIn, user } = useContext(AuthContext);
  const [userPlan, setUserPlan] = useState(null);
  const [subscribedPlan, setSubscribedPlan] = useState(null)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [subscriptionFeatures, setSubscriptionFeatures] = useState(null)
  const [isSubcriptionEnding, setIsSubscriptionEnding] = useState(false)
  const [subscriptionEndsOn, setSubscriptionEndOn] = useState("")

  const fetchUserSubscription = async () => {
    try {
      const response = await getUserSubscription();
      if (response.status === 200) {
        const subscription = response.data.subscription;
        if (response?.data?.willEnd) {
          setIsSubscriptionEnding(response?.data?.willEnd)
          setSubscriptionEndOn(response?.data?.willEndOn)
        }

        setSubscriptionFeatures(subscription.subscriptionFeatures)
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchUserSubscription();
  }, []);

  const handleSubscriptionModal = () => {
    setShowCancelModal(true);

  };

  const handleCloseModal = () => {
    setShowCancelModal(false);
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await cancelCurrentSubscription(subscribedPlan.id)

      if (response.status === 200) {
        toast.success("Plan Canceled Succesfully")
        setSubscriptionFeatures(null)
      } else {
        console.error("Failed to retrieve checkout URL:", response);
      }

      setShowCancelModal(false);
      fetchUserPlan()
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const fetchUserPlan = async () => {
    if (!isLoggedIn || !user || !user.email) {
      toast.error("You must be logged in to view your plan");
      return;
    }

    try {
      setLoading(true);

      // Fetch Stripe Customer ID
      const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
      const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

      if (!stripeCustomerId) {
        console.warn("No Stripe customer ID found for user:", user.email);
        setUserPlan("No active subscription");
        return;
      }

      // Fetch Subscription Details
      const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
      const planName = productResponse.data.productName;
      setUserPlan(planName);
      setSubscribedPlan(productResponse.data.subscribedPlan)
    } catch (error) {
      console.error("Error fetching user plan:", error);
      setUserPlan("Error fetching subscription details");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchUserPlan();
  }, [isLoggedIn, user]);

  const makeProfileSearchable = () => {
    // Logic to make the profile searchable
    console.log("Making profile searchable for top consultancies");
    // navigate("/upgrade-to-premium-to-get-access-to-top-consulting-firms");
    navigate("/join-exclusive-network-with-top-consulting-firms");
  };

  return (
    <>
      {/* <div className="searchable-container">
        <button className="searchable-button" onClick={makeProfileSearchable}>
          Become Searchable for Top Consultancies
        </button>
        <p className="searchable-explanation">
          Exclusive Gateway to Top Consultancies: This is where your consulting career leapfrogs to the forefront. Our platform offers an unparalleled
          opportunity, not found anywhere else, to be directly spotted by the giants of the industry — McKinsey, Bain, BCG, Deloitte, Roland Berger
          and more. By making your profile searchable here, you're not just visible; you're distinctly highlighted in an elite space where these top
          firms actively search for extraordinary talent. It's the shortest, most distinct line from aspiring consultant to being on the radar of the
          best in the business.
        </p>
        <br />
        <br />
        <p className="searchable-explanation">Work in progress! Keep an eye on this space for exciting updates.</p>
      </div> */}
      <div className="profile-container">
        {isLoggedIn ? (
          <div className="profile-content">
            <h1>Profile Information</h1>
            <div className="divider"></div>
            <h3 className="section-title">Account</h3>
            <p className="details-text">User: {user.name}</p>
            <p className="details-text">Email: {user.email}</p>
            {loading ? (
              <p>Loading your subscription details...</p>
            ) : (
              <>
                <div className="divider"></div>
                <h3 className="section-title">Current Plan</h3>
                <p className="details-text">{userPlan || "You do not have an active subscription plan."}</p>

                {subscriptionFeatures !== null && (
                  <div className="subscription-features-table">
                    <div className="table-header">
                      <div className="header-cell">Feature</div>
                      <div className="header-quantity-cell">Quantity Used</div>
                    </div>
                    <div className="table-body">
                      {subscriptionFeatures.map((feature, index) => (
                        <div key={index} className="table-row">
                          <div className="cell">{feature.name}</div>
                          {feature.quantity > 1 &&
                            <div className="quantity-cell">{feature.usedQuantity}/ {feature.quantity}</div>
                          }
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="manage-subscription">
              {userPlan ?
                <>
                  <div className="upgrade-btn" onClick={() => {
                    navigate("/plans")
                  }} >
                    Upgrade Subscription
                  </div>

                  {!isSubcriptionEnding &&
                    <button className="cancel-btn"
                      onClick={() => {
                        handleSubscriptionModal()

                      }}
                      disabled={isSubcriptionEnding}
                    >
                      Cancel Subscription
                    </button>
                  }

                </> :

                <div className="buy-btn" onClick={() => {
                  navigate("/plans")
                }}>
                  Buy Subscription
                </div>







              }
            </div>
            {isSubcriptionEnding &&
              <div className="warningtext"> Subscription ends On {subscriptionEndsOn}*</div>}


          </div>
        ) : (
          <p>Please log in to view your profile.</p>
        )}
      </div>

      <CancelSubscriptionModal
        isOpen={showCancelModal}
        onClose={handleCloseModal}
        handleCancelPlan={handleCancelSubscription}
      />
    </>
  );
}

export default ProfilePage;
