import React from "react";
import answers from "../../assets/flash.svg";
import rocket from "../../assets/rocket.svg";
import document from "../../assets/google-docs.svg";

const PossiblitiesSection = () => {
  return (
    <section className="PossiblitiesSection">
      <h1 className="Possiblities-title">
        Track Your Progress To <br></br> Ace Your Interviews
      </h1>
      <p className="Possiblities-subtitle">
        Visualize your growth across 6 core consulting skills with unparalleled precision.<br></br>
        Identify trends, capitalize on your strengths, and systematically eliminate<br></br>
        weaknesses – turning your preparation into a clear path to success.
      </p>
      <div className="Possiblities-frame">
        <div className="Possiblities-image">
          <img
            src="https://res.cloudinary.com/djzert4i9/image/upload/v1720030062/Screenshot_Progress_kkwcjf.png"
            alt="Progress tracking dashboard"
          ></img>
        </div>
      </div>
      <div className="features-bar">
        <div className="icon-box mb-5 mb-md-0">
          <img src={answers} alt="AI Assistant" />
          <h3>AI-Powered Interview Coach</h3>
          <p>Engage in voice chats with our AI assistant for on-demand, personalized interview practice and feedback.</p>
        </div>
        <div className="icon-box mb-5 mb-md-0">
          <img src={rocket} alt="Progress Tracking" />
          <h3>Detailed Skill Analytics</h3>
          <p>Visualize your growth across key consulting competencies with our advanced progress tracking system.</p>
        </div>
        <div className="icon-box">
          <img src={document} alt="Collaborative Practice" />
          <h3>Peer Practice Network</h3>
          <p>Connect with fellow aspiring consultants for mock interviews and collaborative case solving sessions.</p>
        </div>
      </div>
    </section>
  );
};

export default PossiblitiesSection;
