import React from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  faArrowLeft,
  faChartLine,
  faBullseye,
  faLightbulb,
  faExclamationTriangle,
  faRedo,
  faTrophy,
  faArrowUp,
  faArrowDown,
  faFileAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Radar } from "react-chartjs-2";
import "../../css/PersonalGrowth/PracticeDetailsPage.css";

const PracticeDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const { practiceDetails, allPractices } = location.state || {};
  const [showFullCaseDescription, setShowFullCaseDescription] = useState(false);

  if (!practiceDetails || !allPractices) {
    return <div className="loading">Practice details not found. Please go back and try again.</div>;
  }

  const formatMetricName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getMetricScores = () => {
    return allPractices.map((practice) => practice.score);
  };

  const metricScores = getMetricScores();
  const latestScore = metricScores[metricScores.length - 1] || 0;
  const averageScore = metricScores.reduce((sum, score) => sum + score, 0) / metricScores.length || 0;
  const scoreChange =
    metricScores.length > 1 ? (((latestScore - metricScores[metricScores.length - 2]) / metricScores[metricScores.length - 2]) * 100).toFixed(2) : 0;

  const metricsData =
    practiceDetails.detailedEvaluation?.MetricAnalysis ||
    Object.entries(practiceDetails.detailedEvaluation || {}).map(([key, value]) => ({
      Metric: formatMetricName(key),
      Performance: value.score,
      Strengths: [{ Description: value.areas_of_strength }],
      AreasForImprovement: [{ Description: value.areas_for_improvement }],
    }));

  const bestMetric = metricsData.reduce((best, current) => (parseFloat(current.Performance) > parseFloat(best.Performance) ? current : best));

  const lowestMetric = metricsData.reduce((lowest, current) => (parseFloat(current.Performance) < parseFloat(lowest.Performance) ? current : lowest));

  const chartData = {
    labels: metricsData.map((metric) => metric.Metric),
    datasets: [
      {
        label: "Metric Scores",
        data: metricsData.map((metric) => parseFloat(metric.Performance)),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(54, 162, 235, 1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(54, 162, 235, 1)",
      },
    ],
  };

  const chartOptions = {
    scales: {
      r: {
        beginAtZero: true,
        max: 10,
        min: 0,
        ticks: {
          stepSize: 2,
        },
      },
    },
    plugins: {
      legend: { display: false },
    },
    maintainAspectRatio: false,
  };

  const caseDescription = practiceDetails.caseSummary || "Case description not available.";
  const executiveSummary = practiceDetails.executiveSummary || "Executive summary not available.";
  const truncatedDescription = caseDescription.length > 200 ? `${caseDescription.slice(0, 200)}...` : caseDescription;

  console.log("practiceDetails:", practiceDetails);
  console.log("allPractices:", allPractices);

  return (
    <div className="practice-details-page">
      <div className="page-header">
        <Link to="/progress" className="back-button">
          <FontAwesomeIcon icon={faArrowLeft} /> Back to Progress
        </Link>
        <Link to="/practice-case-interview">
          <button className="practice-again-button">
            <FontAwesomeIcon icon={faRedo} /> Practice Again
          </button>
        </Link>
      </div>

      <header className="practice-header">
        <h1>Practice Session Details</h1>
        <p>Date: {new Date(practiceDetails.date).toLocaleDateString()}</p>
      </header>

      <section className="case-overview">
        <div className="case-summary">
          <h2>
            <FontAwesomeIcon icon={faFileAlt} /> Executive Summary
          </h2>
          <p>{executiveSummary}</p>
        </div>
        <div className="case-description">
          <h2>
            <FontAwesomeIcon icon={faInfoCircle} /> Case Description
          </h2>
          <p>{showFullCaseDescription ? caseDescription : truncatedDescription}</p>
          <button className="read-more-button" onClick={() => setShowFullCaseDescription(!showFullCaseDescription)}>
            {showFullCaseDescription ? "Read Less" : "Read More"}
          </button>
        </div>
      </section>

      <section className="overall-performance">
        <h2>Overall Performance</h2>
        <div className="performance-grid">
          <div className="performance-card main-score">
            <div className="score-circle">
              <svg viewBox="0 0 36 36" className="circular-chart">
                <path
                  className="circle-bg"
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                  className="circle"
                  strokeDasharray={`${latestScore * 10}, 100`}
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" className="percentage">
                  {latestScore.toFixed(1)}
                </text>
              </svg>
            </div>
            <div className="score-details">
              <h3>Overall Score</h3>
              <p className="score-change">{scoreChange}% from last practice</p>
            </div>
          </div>
          <div className="performance-card">
            <FontAwesomeIcon icon={faArrowUp} className="card-icon" />
            <div className="card-content">
              <h3>Best Metric</h3>
              <p className="sub-score">{bestMetric.Metric}</p>
              <p className="sub-score-value">{parseFloat(bestMetric.Performance).toFixed(1)}</p>
            </div>
          </div>
          <div className="performance-card">
            <FontAwesomeIcon icon={faArrowDown} className="card-icon" />
            <div className="card-content">
              <h3>Lowest Metric</h3>
              <p className="sub-score">{lowestMetric.Metric}</p>
              <p className="sub-score-value">{parseFloat(lowestMetric.Performance).toFixed(1)}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="metrics-performance">
        <h2>Metrics Performance</h2>
        <div className="metrics-chart">
          <Radar data={chartData} options={chartOptions} />
        </div>
      </section>

      <section className="metrics-details">
        <h2>Individual Metrics</h2>
        <div className="metrics-grid">
          {metricsData.map((metric, index) => (
            <div key={index} className="metric-card">
              <h3>{metric.Metric}</h3>
              <p>
                <strong>Score:</strong> {parseFloat(metric.Performance).toFixed(2)}
              </p>
              <p>
                <strong>Strength:</strong> {metric.Strengths[0].Description}
              </p>
              <p>
                <strong>Area for Improvement:</strong> {metric.AreasForImprovement[0].Description}
              </p>
            </div>
          ))}
        </div>
      </section>

      <section className="practice-insights">
        <h2>Practice Insights</h2>
        <div className="insights-grid">
          <div className="insight-card">
            <FontAwesomeIcon icon={faLightbulb} className="card-icon" />
            <h3>Key Strengths</h3>
            <p>{practiceDetails.strengths}</p>
          </div>
          <div className="insight-card">
            <FontAwesomeIcon icon={faExclamationTriangle} className="card-icon" />
            <h3>Areas for Improvement</h3>
            <p>{practiceDetails.weaknesses}</p>
          </div>
          <div className="insight-card">
            <FontAwesomeIcon icon={faBullseye} className="card-icon" />
            <h3>Action Items</h3>
            <p>{practiceDetails.actionItems}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PracticeDetailsPage;
