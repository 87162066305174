import React from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline"; // Corrected icon import for v2

const Review = ({ basicInfo, professionalSummary, education, workExperience, onEdit }) => {
  return (
    <div className="flex flex-col justify-center bg-gray-100 py-16">
      <div className="mx-auto w-full max-w-2xl">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-6">Review Your Information</h2>
        <div className="bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
          <div className="space-y-6">
            {/* Basic Information */}
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <h3 className="font-bold text-gray-700">Basic Information:</h3>
                <PencilSquareIcon className="h-5 w-5 text-blue-500 cursor-pointer" onClick={() => onEdit("basicInfo")} />
              </div>
              <p>First Name: {basicInfo.firstName}</p>
              <p>Last Name: {basicInfo.lastName}</p>
              <p>Email: {basicInfo.email}</p>
              <p>Region: {basicInfo.location?.region}</p>
              {basicInfo.location?.region === "North America" ? (
                <p>State: {basicInfo.location?.state}</p>
              ) : (
                <p>Country: {basicInfo.location?.country}</p>
              )}
            </div>
            {/* Professional Summary */}
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <h3 className="font-bold text-gray-700">Professional Summary:</h3>
                <PencilSquareIcon className="h-5 w-5 text-blue-500 cursor-pointer" onClick={() => onEdit("professionalSummary")} />
              </div>
              <p>Headline: {professionalSummary.headline}</p>
              <p>Bio: {professionalSummary.bio}</p>
            </div>
            {/* Educational Background */}
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <h3 className="font-bold text-gray-700">Educational Background:</h3>
                <PencilSquareIcon className="h-5 w-5 text-blue-500 cursor-pointer" onClick={() => onEdit("education")} />
              </div>
              {education.map((edu, index) => (
                <div key={index} className="mb-2">
                  <p>University: {edu.university}</p>
                  <p>Degree: {edu.degree}</p>
                  <p>Major: {edu.major}</p>
                  <p>GPA: {edu.gpa}</p>
                  <p>Graduation Year: {edu.graduationYear}</p>
                  <p>Honors/Awards: {edu.honorsAwards}</p>
                </div>
              ))}
            </div>
            {/* Work Experience */}
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <h3 className="font-bold text-gray-700">Work Experience:</h3>
                <PencilSquareIcon className="h-5 w-5 text-blue-500 cursor-pointer" onClick={() => onEdit("workExperience")} />
              </div>
              {workExperience.map((exp, index) => (
                <div key={index} className="mb-2">
                  <p>Position: {exp.position}</p>
                  <p>Company: {exp.company}</p>
                  <p>Duration: {exp.duration}</p>
                  <p>Key Achievements: {exp.keyAchievements}</p>
                </div>
              ))}
            </div>
            {/* Submit Button */}
            <div className="mt-8">
              <button
                type="button"
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  // Implement submission logic here
                }}
              >
                Make Your Profile Discoverable to Top Consultancies Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
