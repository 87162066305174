import React, { useState, useEffect } from "react";
import { X, Lightbulb, ChevronLeft, ChevronRight } from "lucide-react";
import "./Tips.css";

const tips = [
  "If you're stuck, say: 'I'm stuck, please offer some guidance.'",
  "Structure your approach: Start with a framework, then dive into details.",
  "Clarify assumptions: Don't hesitate to ask for more information.",
  "Think aloud: Share your thought process as you work through the case.",
  "Summarize periodically: Recap key points to show your understanding.",
];

const Tips = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentTip, setCurrentTip] = useState(0);

  const nextTip = () => setCurrentTip((prev) => (prev + 1) % tips.length);
  const prevTip = () => setCurrentTip((prev) => (prev - 1 + tips.length) % tips.length);

  useEffect(() => {
    const interval = setInterval(nextTip, 10000); // Auto-rotate tips every 10 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`tips-container ${isOpen ? "open" : ""}`}>
      <button className="tips-toggle" onClick={() => setIsOpen(!isOpen)} aria-label="Toggle Tips">
        <Lightbulb size={20} />
      </button>
      {isOpen && (
        <div className="tips-content">
          <button className="close-button" onClick={() => setIsOpen(false)} aria-label="Close Tips">
            <X size={20} />
          </button>
          <div className="tip-carousel">
            <button className="carousel-button prev" onClick={prevTip} aria-label="Previous Tip">
              <ChevronLeft size={20} />
            </button>
            <div className="tip-display">
              <span className="tip-number">{currentTip + 1}</span>
              <p className="tip-text">{tips[currentTip]}</p>
            </div>
            <button className="carousel-button next" onClick={nextTip} aria-label="Next Tip">
              <ChevronRight size={20} />
            </button>
          </div>
          <div className="tip-indicators">
            {tips.map((_, index) => (
              <span key={index} className={`indicator ${index === currentTip ? "active" : ""}`} onClick={() => setCurrentTip(index)} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tips;
