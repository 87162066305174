import React, { useState } from "react";
import Chatbot from "./ChatBot";

const CaseIntroduction = ({ generatedData }) => {
  const [showChat, setShowChat] = useState(null);
  const [messages, setMessages] = useState([]);

  const startChat = () => {
    setShowChat(true);
  };

  const endChat = () => {
    setShowChat(false);
  };

  return (
    <div>
      <div className="introduction-container">
        <h3 className="case-type">Introduction:</h3>
        <div className="introduction-text">
          <p>{generatedData}</p>
        </div>
        {showChat === null && (
          <button className="start-chat-button" onClick={startChat}>
            Start Conversation
          </button>
        )}
      </div>
      {showChat && <Chatbot caseIntroduction={generatedData} messages={messages} setMessages={setMessages} endChat={endChat} />}
      {showChat === false && (
        <div className="chat-history">
          {messages.map((message, index) => (
            <p key={index} className={`chat-message ${message.user}`}>
              {message.text}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default CaseIntroduction;
