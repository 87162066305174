import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faExclamationTriangle, faTrophy, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getUserSubscription } from "../../services/SubscriptionServices.js";
import { toast } from "react-toastify";

import LoadingSpinner from "./LoadingSpinner";
import "../../css/SaveModal.css";

const SaveModal = ({ isOpen, onClose, onSave, isSaving, progress }) => {
  const [isFeatureAllowed, setIsFeatureAllowed] = useState(false);
  const [warningText, setWarningText] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetchUserSubscription();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const showWarning = progress < 60;
  const showCelebration = progress > 90;

  const fetchUserSubscription = async () => {
    try {
      const response = await getUserSubscription();
      if (response.status === 200) {
        const subscription = response.data.subscription;
        const subscriptionFeatures = subscription.subscriptionFeatures;

        const featureExists = subscriptionFeatures.find((feature) => feature.name === "AI - Case Practice");

        if (featureExists) {
          if (featureExists.usedQuantity < featureExists.quantity) {
            setIsFeatureAllowed(true);
            setWarningText("");
          } else {
            setWarningText("Upgrade to save and track your progress");
          }
        }
      }
    } catch (error) {
      console.error("Error fetching subscription:", error);
      setWarningText(error.response?.data?.error || "An error occurred while checking your subscription");
    }
  };

  const handleSave = () => {
    // if (!isFeatureAllowed) {
    //   toast.info("Saving and tracking progress is only available for Basic and Premium users.");
    //   return;
    // }
    onSave();
  };

  return (
    <div className="save-modal-overlay" onClick={isSaving ? null : onClose}>
      <div className="save-modal-content" onClick={(e) => e.stopPropagation()}>
        {isSaving ? (
          <div className="save-modal-saving-content">
            <LoadingSpinner />
            <p>Saving your case and initiating analysis...</p>
          </div>
        ) : (
          <>
            <button className="save-modal-close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2>
              <FontAwesomeIcon icon={faSave} className="save-modal-icon" />
              Save Case Progress
            </h2>
            {warningText && (
              <div className="save-modal-warning-text">
                <FontAwesomeIcon icon={faExclamationTriangle} className="save-modal-icon" />
                <p>{warningText}</p>
              </div>
            )}
            {showWarning && (
              <>
                <div className="save-modal-warning-text">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="save-modal-icon" />
                  <p>Your case progress is currently below 60%. Saving now may result in less accurate feedback.</p>
                </div>
                <div className="save-modal-progress-indicator">
                  <div className="save-modal-progress-bar" style={{ width: `${progress}%` }}></div>
                  <span className="save-modal-progress-text">{progress}% Complete</span>
                </div>
              </>
            )}
            {showCelebration && (
              <div className="save-modal-celebration-text">
                <FontAwesomeIcon icon={faTrophy} className="save-modal-icon" />
                <p>Wow! You're crushing it! 🎉 {progress}% progress is what we aim for. Ready to see how awesome you did?</p>
              </div>
            )}
            <p>
              {showCelebration
                ? "Let's wrap this up and see your amazing results!"
                : "Are you sure you want to save your progress? This will end the current case session and initiate the analysis process."}
            </p>
            <div className="save-modal-buttons">
              <button
                onClick={handleSave}
                className={`save-modal-confirm-button ${showCelebration ? "celebrating" : ""}`}
                // disabled={!isFeatureAllowed}
              >
                {showWarning ? "Save Anyway" : showCelebration ? "Get My Performance Insights 💪" : "Save and End Case"}
              </button>
              <button onClick={onClose} className="save-modal-cancel-button">
                {showCelebration ? "One More Round 🔄" : "Continue Case"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SaveModal;
