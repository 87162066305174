import React, { useState, useCallback } from "react";
import ReactFlow, { Controls, Background, useNodesState, useEdgesState, addEdge } from "reactflow";
import "reactflow/dist/style.css";
import { Button, Input, Modal, message, Tooltip, List, Typography, Form } from "antd";
import { PlusOutlined, QuestionCircleOutlined, CheckOutlined, CommentOutlined, EyeOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const nodeWidth = 180;
const nodeHeight = 80;
const nodeSpacingY = 100; // Reduced spacing between nodes

const CustomNode = ({ data }) => {
  const [showAddAnswerModal, setShowAddAnswerModal] = useState(false);
  const [showExampleAnswers, setShowExampleAnswers] = useState(false);
  const [userAnswer, setUserAnswer] = useState("");

  const handleAddAnswer = () => {
    if (userAnswer.trim()) {
      data.addUserAnswer(data.id, userAnswer.trim());
      setUserAnswer("");
      setShowAddAnswerModal(false);
    }
  };

  return (
    <>
      <div
        style={{
          width: nodeWidth,
          height: nodeHeight,
          border: "1px solid #ddd",
          borderRadius: "5px",
          padding: "10px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>{data.label}</div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
          {data.canAddChildren && <Button icon={<PlusOutlined />} size="small" onClick={() => data.onAdd(data.id)} />}
          <Tooltip title="Click for hint">
            <QuestionCircleOutlined style={{ cursor: "pointer" }} onClick={() => data.onHint(data.label)} />
          </Tooltip>
          <Tooltip title="Add your analysis">
            <CommentOutlined style={{ cursor: "pointer" }} onClick={() => setShowAddAnswerModal(true)} />
          </Tooltip>
          <Tooltip title="View example analysis">
            <EyeOutlined style={{ cursor: "pointer" }} onClick={() => setShowExampleAnswers(true)} />
          </Tooltip>
        </div>
      </div>
      {data.userAnswers && data.userAnswers.length > 0 && (
        <div
          style={{
            position: "absolute",
            left: `${nodeWidth + 20}px`,
            top: "0px",
            width: "200px",
          }}
        >
          <ul style={{ listStyleType: "disc", padding: "0 0 0 20px", margin: "0", fontSize: "12px" }}>
            {data.userAnswers.map((item, index) => (
              <li key={index} style={{ marginBottom: "5px" }}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
      <Modal
        title="Add Your Analysis"
        open={showAddAnswerModal}
        onOk={handleAddAnswer}
        onCancel={() => setShowAddAnswerModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowAddAnswerModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAddAnswer}>
            Submit
          </Button>,
        ]}
      >
        <Form>
          <Form.Item>
            <Input.TextArea value={userAnswer} onChange={(e) => setUserAnswer(e.target.value)} placeholder="Enter your analysis" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Example Analysis"
        open={showExampleAnswers}
        onOk={() => setShowExampleAnswers(false)}
        onCancel={() => setShowExampleAnswers(false)}
      >
        <List
          size="small"
          dataSource={data.exampleAnswers}
          renderItem={(item) => (
            <List.Item style={{ padding: "10px 5px", fontSize: "14px" }}>
              <Paragraph>{item}</Paragraph>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const ThreeCsFramework = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([
    {
      id: "1",
      type: "custom",
      data: {
        label: "Company",
        canAddChildren: false,
        id: "1",
        onAdd: (id) => addNode(id),
        onHint: (label) => showHint(label),
        addUserAnswer: (id, answer) => addUserAnswer(id, answer),
        userAnswers: [],
        exampleAnswers: [
          "Unique eco-friendly delivery model using electric bicycles",
          "Partnerships with sustainable restaurants for exclusive offerings",
          "Potential challenge in scaling operations while maintaining sustainability",
          "Limited initial capital compared to established competitors",
          "Strong mission alignment with growing environmental concerns",
        ],
      },
      position: { x: 50, y: 0 },
      draggable: false,
    },
    {
      id: "2",
      type: "custom",
      data: {
        label: "Customers",
        canAddChildren: false,
        id: "2",
        onAdd: (id) => addNode(id),
        onHint: (label) => showHint(label),
        addUserAnswer: (id, answer) => addUserAnswer(id, answer),
        userAnswers: [],
        exampleAnswers: [
          "Environmentally conscious urban professionals",
          "Health-focused individuals seeking sustainable food options",
          "Customers willing to pay a premium for eco-friendly services",
          "Desire for transparency in food sourcing and delivery practices",
          "Tech-savvy users accustomed to food delivery apps",
        ],
      },
      position: { x: 50, y: nodeSpacingY },
      draggable: false,
    },
    {
      id: "3",
      type: "custom",
      data: {
        label: "Competitors",
        canAddChildren: false,
        id: "3",
        onAdd: (id) => addNode(id),
        onHint: (label) => showHint(label),
        addUserAnswer: (id, answer) => addUserAnswer(id, answer),
        userAnswers: [],
        exampleAnswers: [
          "Established players like UberEats, DoorDash, and Grubhub",
          "Local food delivery services with loyal customer bases",
          "Potential for large competitors to introduce eco-friendly options",
          "High customer acquisition costs in a saturated market",
          "Difficulty in differentiating services beyond eco-friendliness",
        ],
      },
      position: { x: 50, y: 2 * nodeSpacingY },
      draggable: false,
    },
  ]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [showExamples, setShowExamples] = useState(false);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const hints = {
    Company: "Consider both internal capabilities and external market factors.",
    Customers: "Think about both current and potential future needs of the target market.",
    Competitors: "Analyze direct competitors as well as potential substitutes or new entrants.",
  };

  const showHint = (label) => {
    message.info(hints[label] || "Consider key factors that might influence this aspect of the 3Cs analysis.");
  };

  const addUserAnswer = useCallback(
    (id, answer) => {
      setNodes((nds) =>
        nds.map((node) => (node.id === id ? { ...node, data: { ...node.data, userAnswers: [...(node.data.userAnswers || []), answer] } } : node))
      );
    },
    [setNodes]
  );

  const addNode = useCallback(
    (parentId) => {
      // Logic for adding an optional fourth node if needed
      // This can be implemented later if required
    },
    [setNodes, setEdges]
  );

  const displayExamples = () => {
    setShowExamples(true);
    message.success("Excellent work on your analysis! Here are some examples to compare with your ideas.");
  };

  return (
    <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column" }}>
      <div style={{ flex: 1 }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
          attributionPosition="bottom-left"
        >
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>
      <div style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
        <Button onClick={displayExamples} icon={<CheckOutlined />}>
          I'm done! Show me examples
        </Button>
      </div>
      <Modal title="Example 3Cs Analysis" open={showExamples} onOk={() => setShowExamples(false)} onCancel={() => setShowExamples(false)} width={800}>
        {["Company", "Customers", "Competitors"].map((category) => (
          <div key={category}>
            <Title level={3}>{category}</Title>
            <List
              dataSource={nodes.find((node) => node.data.label === category).data.exampleAnswers}
              renderItem={(item) => (
                <List.Item style={{ padding: "10px 5px", fontSize: "14px" }}>
                  <Paragraph>{item}</Paragraph>
                </List.Item>
              )}
            />
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default ThreeCsFramework;
