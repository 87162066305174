import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../css/CustomCaseCreatorMobile.css";

const CustomCaseCreatorMobile = ({ onSubmit, onClose }) => {
  const [caseType, setCaseType] = useState("");
  const [industry, setIndustry] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const caseTypes = [
    "Market Entry",
    "Profitability",
    "Growth Strategy",
    "Merger & Acquisition",
    "Product Launch",
    "Cost Reduction",
    "Pricing Strategy",
    "New Market Analysis",
    "Business Model Innovation",
    "Digital Transformation",
    "Supply Chain Optimization",
    "Customer Segmentation",
  ];

  const industries = [
    "Technology",
    "Healthcare",
    "Financial Services",
    "Retail",
    "Manufacturing",
    "Energy",
    "Telecommunications",
    "Automotive",
    "Pharmaceuticals",
    "Consumer Goods",
    "Media & Entertainment",
    "Aerospace & Defense",
  ];

  const skillOptions = [
    { name: "Market Sizing", icon: "📊" },
    { name: "Framework Building", icon: "🏗️" },
    { name: "Data Analysis", icon: "📈" },
    { name: "Hypothesis Testing", icon: "🧪" },
    { name: "Quantitative Reasoning", icon: "🔢" },
    { name: "Problem Structuring", icon: "🧩" },
  ];

  const handleSubmit = () => {
    if (currentStep === 3 && caseType && industry) {
      onSubmit({
        caseType,
        industry,
        skill: selectedSkill,
      });
    }
  };

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="step-content">
            <h3>Step 1: Select Case Type</h3>
            <div className="option-grid">
              {caseTypes.map((type) => (
                <button key={type} type="button" className={`option-button ${caseType === type ? "selected" : ""}`} onClick={() => setCaseType(type)}>
                  {type}
                  {caseType === type && <FontAwesomeIcon icon={faCheck} className="check-icon" />}
                </button>
              ))}
            </div>
          </div>
        );
      case 2:
        return (
          <div className="step-content">
            <h3>Step 2: Choose Industry</h3>
            <div className="option-grid">
              {industries.map((ind) => (
                <button key={ind} type="button" className={`option-button ${industry === ind ? "selected" : ""}`} onClick={() => setIndustry(ind)}>
                  {ind}
                  {industry === ind && <FontAwesomeIcon icon={faCheck} className="check-icon" />}
                </button>
              ))}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="step-content">
            <h3>Step 3: Select Primary Skill (Optional)</h3>
            <div className="skill-grid">
              {skillOptions.map((skill) => (
                <button
                  key={skill.name}
                  type="button"
                  className={`skill-button ${selectedSkill === skill.name ? "selected" : ""}`}
                  onClick={() => setSelectedSkill(selectedSkill === skill.name ? "" : skill.name)}
                >
                  <span className="skill-icon">{skill.icon}</span>
                  <span className="skill-name">{skill.name}</span>
                  {selectedSkill === skill.name && <FontAwesomeIcon icon={faCheck} className="check-icon" />}
                </button>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="custom-case-creator-mobile">
      <button className="close-button" onClick={onClose} aria-label="Close">
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <h2>Create Custom Case</h2>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${(currentStep / 3) * 100}%` }}></div>
      </div>
      <div>
        {renderStep()}
        <div className="navigation-buttons">
          {currentStep > 1 && (
            <button type="button" onClick={handleBack} className="back-button">
              Back
            </button>
          )}
          {currentStep < 3 ? (
            <button type="button" onClick={handleNext} className="next-button" disabled={currentStep === 1 ? !caseType : !industry}>
              Next
            </button>
          ) : (
            <button type="button" onClick={handleSubmit} className="create-case-button" disabled={!caseType || !industry}>
              Create Case
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomCaseCreatorMobile;
