import React, { useState, useEffect } from "react";

const WorkExperience = ({ onNext, onBack }) => {
  const [workExperiences, setWorkExperiences] = useState([
    {
      position: "",
      company: "",
      duration: "",
      keyAchievements: "",
    },
  ]);

  // Add Tailwind CDN on component mount
  useEffect(() => {
    const tailwindLink = document.createElement("link");
    tailwindLink.href = "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
    tailwindLink.rel = "stylesheet";
    document.head.appendChild(tailwindLink);

    return () => {
      document.head.removeChild(tailwindLink);
    };
  }, []);

  const handleExperienceChange = (index, e) => {
    const updatedExperiences = [...workExperiences];
    updatedExperiences[index][e.target.name] = e.target.value;
    setWorkExperiences(updatedExperiences);
  };

  const addWorkExperience = () => {
    setWorkExperiences((prevWorkExperiences) => [
      ...prevWorkExperiences,
      {
        // initial state of your new work experience
        company: "",
        position: "",
        startDate: "",
        endDate: "",
        // add more fields as necessary
      },
    ]);
  };

  const handleNext = () => {
    onNext(workExperiences); // Replace with your actual next step logic
  };

  const removeWorkExperience = (index) => {
    const newWorkExperience = [...workExperiences];
    newWorkExperience.splice(index, 1);
    setWorkExperiences(newWorkExperience);
  };

  return (
    <div className="flex flex-col justify-center bg-gray-100 py-16">
      <div className="mx-auto w-full max-w-2xl">
        {/* <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-6">Work Experience</h2> */}
        <div className="bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
          <form className="space-y-6">
            {workExperiences.map((experience, index) => (
              <div key={index} className="space-y-6">
                <div>
                  <label htmlFor={`position-${index}`} className="text-sm font-medium text-gray-700">
                    Position
                  </label>
                  <input
                    type="text"
                    name="position"
                    id={`position-${index}`}
                    value={experience.position}
                    onChange={(e) => handleExperienceChange(index, e)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor={`company-${index}`} className="text-sm font-medium text-gray-700">
                    Company
                  </label>
                  <input
                    type="text"
                    name="company"
                    id={`company-${index}`}
                    value={experience.company}
                    onChange={(e) => handleExperienceChange(index, e)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor={`duration-${index}`} className="text-sm font-medium text-gray-700">
                    Duration
                  </label>
                  <input
                    type="text"
                    name="duration"
                    id={`duration-${index}`}
                    value={experience.duration}
                    onChange={(e) => handleExperienceChange(index, e)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor={`keyAchievements-${index}`} className="text-sm font-medium text-gray-700">
                    Key Achievements
                  </label>
                  <textarea
                    name="keyAchievements"
                    id={`keyAchievements-${index}`}
                    value={experience.keyAchievements}
                    onChange={(e) => handleExperienceChange(index, e)}
                    required
                    rows="3"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  ></textarea>
                </div>

                <div className="flex justify-between">
                  {workExperiences.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeWorkExperience(index)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Remove
                    </button>
                  )}
                  {index === workExperiences.length - 1 && (
                    <button
                      type="button"
                      onClick={addWorkExperience}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Add More
                    </button>
                  )}
                </div>
              </div>
            ))}

            <div className="flex justify-between">
              <button
                type="button"
                onClick={onBack}
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
