import React from "react";
import CarlCase from "./CarlCase";
import CarlCaseMobile from "./CarlCaseMobile";

const ResponsiveComponent = () => {
  const isMobile = window.innerWidth <= 576; // Change this value based on your definition of a mobile device
  console.log("Is mobile: ", isMobile);
  return <>{isMobile ? <CarlCaseMobile /> : <CarlCase />}</>;
};

export default ResponsiveComponent;
