import React, { useState, useEffect } from "react";
import BasicInfo from "./BasicInfo";
import ProfessionalSummary from "./ProfessionalSummary";
import EducationalBackground from "./EducationalBackground";
import FormNavigation from "./FormNavigation";
import WorkExperience from "./WorkExperience";
import Review from "./Review";

// Import other form pages as needed

const UserForm = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [yourBasicInfoState, setYourBasicInfoState] = useState({});
  const [yourProfessionalSummaryState, setYourProfessionalSummaryState] = useState({});
  const [yourEducationState, setYourEducationState] = useState([]);
  const [yourWorkExperienceState, setYourWorkExperienceState] = useState([]);

  const totalPages = 4; // Update this as per the number of form pages

  // add tailwind cdn
  useEffect(() => {
    const tailwindLink = document.createElement("link");
    tailwindLink.href = "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
    tailwindLink.rel = "stylesheet";
    document.head.appendChild(tailwindLink);
  }, []);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
    // Add logic to handle form submission on last page
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const renderFormPage = () => {
    switch (currentPage) {
      case 0:
        return (
          <BasicInfo
            formData={yourBasicInfoState}
            setFormData={setYourBasicInfoState} // Corrected prop name
            onNext={goToNextPage}
          />
        );
      case 1:
        return (
          <ProfessionalSummary
            formData={yourProfessionalSummaryState}
            setFormData={setYourProfessionalSummaryState}
            onNext={goToNextPage}
            onBack={goToPreviousPage}
          />
        );
      case 2:
        return (
          <EducationalBackground formData={yourEducationState} setFormData={setYourBasicInfoState} onNext={goToNextPage} onBack={goToPreviousPage} />
        );
      case 3:
        return (
          <WorkExperience
            formData={yourWorkExperienceState}
            setFormData={setYourWorkExperienceState}
            onNext={goToNextPage}
            onBack={goToPreviousPage}
          />
        );
      case 4:
        return (
          <Review
            basicInfo={yourBasicInfoState}
            professionalSummary={yourProfessionalSummaryState}
            education={yourEducationState}
            workExperience={yourWorkExperienceState}
          />
        );
      default:
        return <div>Form Page Not Found</div>;
    }
  };

  return (
    <div className="user-form-container">
      <FormNavigation currentPage={currentPage} goToPage={goToPage} />
      {renderFormPage()}
    </div>
  );
};
export default UserForm;
