import React from "react";
import { Helmet } from "react-helmet";
import PersonalizedImg from "../assets/andrew-neel-ute2XAFQU2I-unsplash.jpg";
import CollaborativeImg from "../assets/Learn_together.jpg";
import "../css/PrepEnhancer.css";
import { Link } from "react-router-dom";

function PrepEnhancer() {
  return (
    <div className="prep-enhancer">
      <Helmet>
        <title>Personalized and Collaborative Case Interview Prep</title>
      </Helmet>

      <div className="prep-module">
        <h2>Personalized Learning Path</h2>
        <img src={PersonalizedImg} alt="Personalized Learning" />
        <p>
          Master case interviews at your pace. Our genAI technology offers a personalized learning experience, adapting to your unique strengths and
          growth areas. Focus on what matters most to you with customized learning modules, self-paced progress tracking, and interactive
          skill-building exercises.
        </p>
        <ul>
          <li>Customized learning modules</li>
          <li>Self-paced progress tracking</li>
          <li>Interactive skill-building exercises</li>
        </ul>
        <Link to="/choose">
          <button>Discover More</button>
        </Link>
      </div>

      <div className="prep-module">
        <h2>Collaborative Community Practice</h2>
        <img src={CollaborativeImg} alt="Collaborative Learning" />
        <p>
          Elevate your preparation with the power of community. Our platform connects you with peers for collaborative learning experiences. Share
          insights, engage in real-world case collaborations, and learn from diverse perspectives in our community learning sessions. Together, we're
          stronger.
        </p>
        <ul>
          <li>Community learning sessions</li>
          <li>Peer-to-peer interaction</li>
          <li>Real-world case collaborations</li>
        </ul>
        <Link to="/practice-cases-with-peers">
          <button>Join the Community</button>
        </Link>
      </div>
    </div>
  );
}

export default PrepEnhancer;
