import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL_NEW_BACKEND } from "../../config/config.index.js";
import { Link } from "react-router-dom";
import "../../css/CasesOverview.css";
import { AiOutlineReload } from "react-icons/ai"; // Import reload icon from Ant Design

const CasesOverview = () => {
  const [cases, setCases] = useState([]);
  const [caseTypeFilter, setCaseTypeFilter] = useState("");
  const [industryFilter, setIndustryFilter] = useState("");

  // Inside your component
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL_NEW_BACKEND}/retrieve/test`);
        console.log(response.data); // Log the response data
      } catch (error) {
        console.error("Error:", error); // Log the error
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL_NEW_BACKEND}/retrieve/case-interview`);
        setCases(response.data);
        console.log("Case Interview Data", response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount

  const handleCaseTypeFilterChange = (event) => {
    setCaseTypeFilter(event.target.value);
  };

  const handleIndustryFilterChange = (event) => {
    setIndustryFilter(event.target.value);
  };

  const filteredCases = cases.filter((caseItem) => {
    const matchesCaseType = caseTypeFilter === "" || caseItem.caseType.toLowerCase().replace(/ /g, "-") === caseTypeFilter;
    const matchesIndustry = industryFilter === "" || caseItem.industry === industryFilter;
    return matchesCaseType && matchesIndustry;
  });

  useEffect(() => {
    if (filteredCases.length > 0) {
      console.log("Sample case item:", filteredCases[0]);
    }
  }, [filteredCases]);

  const optionsCases = [
    "All Case Types",
    "Investment",
    "Market Entry",
    "Mergers and Acquisitions",
    "Operations",
    "Pricing",
    "Private Equity",
    "Profitability",
    "Public Sector",
  ].sort();

  return (
    <div>
      <div className="select-container">
        <select className="select-element" value={caseTypeFilter} onChange={handleCaseTypeFilterChange}>
          {optionsCases.map((option) => (
            <option key={option} value={option.toLowerCase().replace(/ /g, "-")}>
              {option}
            </option>
          ))}
        </select>
        <select className="select-element" value={industryFilter} onChange={handleIndustryFilterChange}>
          <option value="">All industries</option>
          <option value="Automotive">Automotive</option>
          <option value="Banking">Banking</option>
          <option value="Chemicals">Chemicals</option>
          <option value="Consumer Goods">Consumer Goods</option>
          <option value="Energy">Energy</option>
          <option value="Financial-services">Financial Services</option>
          <option value="Healthcare">Healthcare</option>
          <option value="Industrial-goods">Industrial Goods</option>
          <option value="Insurance">Insurance</option>
          <option value="Media">Media</option>
          <option value="Pharmaceuticals">Pharmaceuticals</option>
          <option value="Private-equity">Private Equity</option>
          <option value="Public Sector">Public Sector</option>
          <option value="Retail">Retail</option>
          <option value="Technology">Technology</option>
          <option value="Telecommunications">Telecommunications</option>
        </select>
        <button
          className="reset-button"
          onClick={() => {
            setCaseTypeFilter("");
            setIndustryFilter("");
          }}
        >
          <AiOutlineReload />
        </button>
      </div>
      <div className="cases-container">
        {filteredCases.map((caseItem) => (
          <Link key={caseItem._id} to={`/free-practice-case-interviews/${caseItem._id}`} className="case-link">
            <div className="case-item">
              <img src={caseItem.imageUrl} alt={caseItem.title} className="case-thumbnail" />
              <div className="case-content">
                <h2>{caseItem.title}</h2>
                <span className="case-type">{caseItem.caseType}</span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CasesOverview;
