import React, { useState } from 'react';

const DateNavigator = ({onDateChange }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const formatDateString = (date) => {
    const today = new Date();
    if (isToday(date)) return 'Today';

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    if (date.toDateString() === tomorrow.toDateString()) return 'Tomorrow';

    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const isToday = (date) => {
    const today = new Date();
    return date.toDateString() === today.toDateString();
  };

  const handleNextDay = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    setCurrentDate(nextDate);
    onDateChange(nextDate)
  };

  const handlePrevDay = () => {
    if (!isToday(currentDate)) {
      const prevDate = new Date(currentDate);
      prevDate.setDate(currentDate.getDate() - 1);
      setCurrentDate(prevDate);
      onDateChange(prevDate)
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await getMeetingList({ date: formattedDate(currentDate) });
  //     setMeetings(response.data.data);
  //   } catch (error) {
  //     console.error("Error fetching meeting list:", error);
  //     // Handle error: Show a message to the user or retry fetching data
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [currentDate]);

  return (
    <div className="date_navigator">
      <span onClick={handlePrevDay} className={isToday(currentDate) ? 'disabled' : ''}>
        <i className="fas fa-angle-left"></i>
      </span>
      {formatDateString(currentDate)}
      <span onClick={handleNextDay}><i className="fas fa-angle-right"></i></span>
    </div>
  );
};

export default DateNavigator;
