// import React, { useState } from "react";
// import axios from "axios";
// import { API_URL } from "../config/config.index.js";
// import "../css/Chatbot.css";

// function Chatbot({ caseIntroduction, messages, setMessages }) {
//   const [loading, setLoading] = useState(false);
//   const [userInput, setUserInput] = useState("");
//   const [messageCount, setMessageCount] = useState(0);

//   const handleSend = async () => {
//     const trimmedInput = userInput.trim();
//     if (trimmedInput === "" || messageCount >= 5) {
//       return; // Do nothing if input is empty or message count limit reached
//     }

//     setUserInput("");
//     setMessages((prevMessages) => [...prevMessages, { user: "user", text: trimmedInput }]);
//     setMessageCount((prevCount) => prevCount + 1);

//     try {
//       setLoading(true);
//       const response = await axios.post(`${API_URL}/api/chatbot`, {
//         userMessage: trimmedInput,
//         caseIntroduction,
//       });

//       const botResponse = response.data;
//       setMessages((prevMessages) => [...prevMessages, { user: "bot", text: botResponse }]);
//     } catch (error) {
//       console.error("Error while fetching data:", error);
//       setMessages((prevMessages) => [...prevMessages, { user: "bot", text: "Error getting response." }]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       handleSend();
//     }
//   };

//   return (
//     <div className="chatbot-container">
//       <div className="messages-container">
//         {messages.map((message, index) => (
//           <p key={index} className={`chat-message ${message.user}`}>
//             {message.text}
//           </p>
//         ))}
//       </div>
//       <div className="user-input-container">
//         <input
//           className="user-input"
//           value={userInput}
//           onChange={(e) => setUserInput(e.target.value)}
//           onKeyPress={handleKeyPress}
//           disabled={loading || messageCount >= 5}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSend} disabled={loading || userInput.trim() === "" || messageCount >= 5}>
//           Send
//         </button>
//       </div>
//       {messageCount >= 5 && (
//         <p className={`end-chat-message ${messageCount >= 5 ? "visible" : ""}`}>
//           It looks like we've reached the end of our chat session. Good luck!
//         </p>
//       )}
//     </div>
//   );
// }

// export default Chatbot;

import React, { useState, useRef } from "react";
import axios from "axios";
import { API_URL, API_URL_NEW_BACKEND } from "../config/config.index.js";
import "../css/Chatbot.css";
import { useEffect } from "react";

function Chatbot() {
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [ttsData, setTtsData] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Automatically scroll to the latest message
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    // Automatically play TTS when data updates
    if (ttsData) {
      const audio = new Audio(`data:audio/mp3;base64,${ttsData}`);
      audio.play().catch((error) => console.error("Error playing audio:", error));
    }

    // Test the backend connection
    axios
      .get(`${API_URL_NEW_BACKEND}/api/test`)
      .then((response) => {
        console.log("Backend connection test:", response.data);
      })
      .catch((error) => {
        console.error("Error connecting to backend:", error);
      });
  }, [messages, ttsData]);

  useEffect(() => {
    // Fetch initial assistant message
    const fetchInitialMessage = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL_NEW_BACKEND}/api/initial-message`);
        setMessages([{ user: "assistant", text: response.data.message }]);
      } catch (error) {
        console.error("Error fetching initial message:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialMessage();
  }, []);

  const handleSend = async () => {
    const trimmedInput = userInput.trim();
    if (trimmedInput === "") {
      console.error("User input is empty");
      return;
    }

    setMessages((prevMessages) => [...prevMessages, { user: "user", text: trimmedInput }]);
    setUserInput("");

    try {
      setLoading(true);
      const response = await axios.post(`${API_URL_NEW_BACKEND}/api/assistant-chat`, { userMessage: trimmedInput });
      console.log("Response from backend:", response.data);

      // Commenting out the part where assistant's messages are added to the UI
      // setMessages((prevMessages) => [...prevMessages, { user: "assistant", text: response.data.response }]);
      setTtsData(response.data.ttsData); // Set TTS data
    } catch (error) {
      console.error("Error while fetching data from backend:", error);
      setMessages((prevMessages) => [...prevMessages, { user: "bot", text: "Error: Unable to fetch response." }]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading) {
      handleSend();
    }
  };

  return (
    <div className="chatbot-container">
      <div className="messages-container">
        {messages.map(
          (message, index) =>
            // Render only user messages
            message.user === "user" && (
              <p key={index} className="chat-message user">
                {message.text}
              </p>
            )
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="user-input-container">
        <input
          className="user-input"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          disabled={loading}
          placeholder="Type your message..."
        />
        <button onClick={handleSend} disabled={loading || userInput.trim() === ""}>
          Send
        </button>
      </div>
    </div>
  );
}

export default Chatbot;
