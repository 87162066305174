import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h5>CaseStudyGenerator</h5>
          {/* <div className="form">
                        <input type="email" placeholder="name@email.com" />
                        <button>Subscribe</button>
                    </div>
                    <p className="footer-note">No Spam. Just Product updates.</p> */}
          <p className="footer-rights">CaseStudyGenerator. All right reserved. © 2024</p>
        </div>
        <div className="footer-quicklinks">
          <div className="footer-section">
            <h6>Product</h6>
            <ul>
              <li>
                <Link to="/choose">Features</Link>
              </li>
              <li>
                <Link to="/plans">Pricing</Link> {/* Use Link here */}
              </li>
              <li>
                <Link to="/blogs">Blog</Link> {/* And here */}
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h6>Support</h6>
            <ul>
              <li>
                <a href="mailto:question@casestudygenerator.com">Help Desk</a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h6>Company</h6>
            <ul>
              {/* <li>Privacy Policy</li> */}
              <li>
                <Link to="/about">About Us</Link>
              </li>
              {/* <li>Terms of Use</li> */}
            </ul>
            <div className="footer-social">
              <a href="https://www.linkedin.com/company/102663462/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn className="social-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
