import React from "react";
import { FaUser, FaGlobe, FaDatabase } from "react-icons/fa";
import "../css/SocialProof.css";

function SocialProof() {
  return (
    <section className="social-proof-section">
      <h2 className="social-proof-heading">Our Track Record Speaks for Itself</h2>
      <div className="social-proof-container">
        <div className="social-proof-item">
          <FaUser className="user-icon social-proof-icon" />
          <div className="social-proof-text">
            <p className="social-proof-number">1,000+</p>
            <p className="social-proof-description">Happy Users</p>
          </div>
        </div>
        <div className="social-proof-item">
          <FaGlobe className="globe-icon social-proof-icon" />
          <div className="social-proof-text">
            <p className="social-proof-number">50+</p>
            <p className="social-proof-description">Countries Served</p>
          </div>
        </div>
        <div className="social-proof-item">
          <FaDatabase className="social-proof-icon" />
          <div className="social-proof-text">
            <p className="social-proof-number">10K+</p>
            <p className="social-proof-description">Queries/Month</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SocialProof;
