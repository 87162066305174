import React from "react";
import styled from "styled-components";
import { InfinitySpin } from "react-loader-spinner";
import randomFacts from "../components/pages/data/facts.json";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const FactContainer = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 1rem;
  margin-top: 2rem;
  background-color: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  font-size: 1.2rem;
  color: #0070c0; /* Apple's vibrant blue color */
  text-align: center;
  position: relative;
`;

const RandomFact = styled.div`
  font-size: 1.2rem;
  color: #0070c0; /* Apple's vibrant blue color */
  margin-bottom: 1rem;
`;

const LoaderText = styled.div`
  font-size: 1.2rem;
  color: #0070c0; /* Apple's vibrant blue color */
  text-align: center;
  z-index: 2;
`;

const getRandomFact = () => {
  const fact = randomFacts[Math.floor(Math.random() * randomFacts.length)];
  return `${fact.category}: ${fact.fact}`;
};

const Loader = () => {
  return (
    <LoaderContainer>
      <FactContainer>
        <RandomFact>{getRandomFact()}</RandomFact>
        <InfinitySpin
          type="TailSpin"
          color="#0070c0" /* Apple's vibrant blue color */
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
        <LoaderText>We are thinking...in the meantime learn from the facts above!</LoaderText>
      </FactContainer>
    </LoaderContainer>
  );
};

export default Loader;
