import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import NavbarDropdown from "./NavBarDropdown";
import "../css/Navbar.css";

function Navbar({ isAnnouncementVisible }) {
  const navigate = useNavigate();
  const { isLoggedIn, logOutUser } = useContext(AuthContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeAllMenus = () => {
    setMobileMenuOpen(false);
  };

  const renderLoginLogout = () => {
    if (isLoggedIn) {
      return (
        <>
          <Link className="navLinkStyle" to="/profile" onClick={closeAllMenus}>
            Profile
          </Link>
          <button
            type="button"
            className="logoutButtonStyle"
            onClick={() => {
              logOutUser(navigate);
              closeAllMenus();
            }}
          >
            Logout
          </button>
        </>
      );
    } else {
      return (
        <Link className="navLinkStyle" to="/login" onClick={closeAllMenus}>
          Login
        </Link>
      );
    }
  };

  return (
    <>
      <nav
        className={`navBarStyle ${isHomePage && isAnnouncementVisible ? "navBarWithAnnouncement" : ""}`}
        style={{
          justifyContent: isMobile ? "center" : "space-between",
        }}
      >
        <Link className="logoStyle" to="/" onClick={closeAllMenus} style={{ marginRight: isMobile ? "auto" : "0" }}>
          CaseStudyGenerator
        </Link>
        {!isMobile && (
          <>
            <div className="linksContainerStyle">
              <NavbarDropdown closeMenu={closeAllMenus} isMobile={isMobile} />
                {/*TODO: Uncomment after fixing stripe*/}
              {/*<Link className="navLinkStyle" to="/plans" onClick={closeAllMenus}>*/}
              {/*  Pricing*/}
              {/*</Link>*/}
              <Link className="navLinkStyle" to="/progress" onClick={closeAllMenus}>
                Progress
              </Link>
            </div>
            <div className="loginContainer">{renderLoginLogout()}</div>
          </>
        )}
        {isMobile && (
          <button className="hamburgerMenu" onClick={toggleMenu} style={{ marginLeft: "auto" }}>
            <FontAwesomeIcon icon={mobileMenuOpen ? faTimes : faBars} className="iconStyle" />
          </button>
        )}
      </nav>
      {mobileMenuOpen && isMobile && (
        <div className="mobileMenu">
          <NavbarDropdown closeMenu={closeAllMenus} isMobile={isMobile} />
          <Link className="navLinkStyle" to="/plans" onClick={closeAllMenus}>
            Pricing
          </Link>
          <Link className="navLinkStyle" to="/progress" onClick={closeAllMenus}>
            Progress
          </Link>
          {renderLoginLogout()}
        </div>
      )}
      {mobileMenuOpen && isMobile && <div className="backdrop" onClick={toggleMenu}></div>}
    </>
  );
}

export default Navbar;
